import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

import { staticBaseURI } from "../../config"
import {
    fetchClassDetails,
    setSelectedYear,
} from "../../features/class-contribution/classContributionSlice"
import ClassDetails from "./ClassDetails"
import YearCarousel from "./YearCarousel"

const ClassShowcase = () => {
    const dispatch = useDispatch()

    const { selectedYear, years, classDetails, isLoading } = useSelector(
        (state) => state.classContributionPage
    )

    function handleSelectInput(ev) {
        dispatch(setSelectedYear(ev.value))
        dispatch(fetchClassDetails(ev.value))
    }

    const yearOptions = years.map((year) => {
        return { value: year, label: year }
    })

    return (
        <section className="bg-blue-dark px-4 py-16">
            <div className="container mx-auto px-6">
                <div className="relative">
                    <div className="year-select-input-wrapper flex justify-end relative -mt-20">
                        {/* <select
                            className="custom-select-input rounded-md focus:outline-none w-[80%] md:w-[40%] lg:w-[20%]"
                            onChange={(ev) => handleSelectInput(ev)}
                            value={selectedYear}
                        >
                            <option value="">Select Class Year</option>
                            {years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select> */}
                        <Select
                            options={yearOptions}
                            className="w-[80%] md:w-[40%] lg:w-[20%]"
                            onChange={(ev) => handleSelectInput(ev)}
                            defaultValue={selectedYear}
                        />
                    </div>
                </div>
                <div>
                    <h1 className="text-white text-2xl md:text-3xl lg:text-5xl font-bold uppercase mt-12">
                        Class Showcase
                    </h1>
                    <YearCarousel years={years} selectedYear={selectedYear} />
                    {isLoading ? (
                        <div className="flex justify-center mt-12">
                            <img
                                src={`${staticBaseURI}/dists/images/oval.svg`}
                                alt="loading"
                            />
                        </div>
                    ) : (
                        <ClassDetails details={classDetails} />
                    )}
                </div>
            </div>
        </section>
    )
}

export default ClassShowcase
