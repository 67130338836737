import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch scholarships
const fetchScholarships = async (limit, page) => {
    const url = `/scholarships-awards/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch scholarship details
const fetchScholarshipDetails = async (slug) => {
    const url = `/scholarship-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const scholarshipsService = {
    fetchScholarships,
    fetchPageContent,
    fetchScholarshipDetails,
}

export default scholarshipsService
