import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    fetchNoticeDetails,
    fetchPageContent,
} from "../../features/noticeboard/noticeboardSlice"
import * as DOMPurify from "dompurify"
import { useParams } from "react-router-dom"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI, staticBaseURI } from "../../config"
import { FaFileDownload } from "react-icons/fa"

const NoticeDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()

    const { pageContent, noticeDetails } = useSelector(
        (state) => state.noticeboardPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("notice-board"))
        dispatch(fetchNoticeDetails(params.slug))
    }, [dispatch, params.slug])

    let cleanContent = DOMPurify.sanitize(noticeDetails.description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const todayDate = new Date()

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <section className="bg-black px-4 py-16 relative">
                <div className="container mx-auto px-4">
                    <div className="bg-text top-0 left-0">notice</div>
                    <div className="flex justify-between">
                        <div className="text-white md:pr-8 self-center w-4/5">
                            <h2 className="font-semibold uppercase text-2xl md:text-4xl">
                                {noticeDetails.title}
                            </h2>
                            <h3 className="text-xl md:text-2xl mt-4">
                                Notice Date:{" "}
                                {`${noticeDetails.notice_date} - ${noticeDetails.notice_month} - ${noticeDetails.notice_year}`}
                            </h3>
                        </div>
                        <div className="flex justify-center">
                            <div>
                                <div className="flex justify-center">
                                    <img
                                        alt="logo"
                                        src={`${staticBaseURI}/dists/images/logo.png`}
                                        className=" grayscale md:w-auto w-[50%]"
                                    />
                                </div>
                                <div className="text-white text-center font-semibold text-2xl md:text-4xl">
                                    <h3>Today is</h3>
                                    <h3>{`${todayDate.getDate()} - ${
                                        todayDate.getMonth() + 1
                                    } - ${todayDate.getFullYear()}`}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="px-4 pt-0 pb-16 bg-blue-dark">
                <div className="container mx-auto relative bg-white -mt-8 p-8 rounded-2xl">
                    <div
                        className="text-base"
                        dangerouslySetInnerHTML={createMarkup(cleanContent)}
                    ></div>
                    <div className="mt-8">
                        <a
                            href={`${apiBaseURI}${noticeDetails.file_url}`}
                            className="bg-green-500 text-white px-4 py-2 flex w-fit"
                        >
                            <div className="self-center mr-2">
                                <FaFileDownload />
                            </div>
                            Download Notice File
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NoticeDetails
