import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch newsletters
const fetchNewsletters = async (limit, status, page) => {
    const url = `/newsletters/${status}/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch latest newsletter
const fetchLatestNewsletter = async () => {
    const url = `/latest-newsletter`
    const response = await api.get(url)
    return response.data
}

//fetch newsletter details
const fetchNewsletterDetails = async (slug) => {
    const url = `/newsletter-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const newsletterService = {
    fetchNewsletters,
    fetchPageContent,
    fetchLatestNewsletter,
    fetchNewsletterDetails,
}

export default newsletterService
