import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import specialpurposesService from "./specialpurposesService"

const initialState = {
    specialPurposes: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    purposeDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchPurposes = createAsyncThunk(
    "specialPurposes/fetchPurposes",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().specialPurposesPage
            return await specialpurposesService.fetchPurposes(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "specialPurposes/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await specialpurposesService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPurposeDetails = createAsyncThunk(
    "specialPurposes/fetchPurposeDetails",
    async (slug, thunkAPI) => {
        try {
            return await specialpurposesService.fetchPurposeDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const specialpurposesSlice = createSlice({
    name: "specialPurposes",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurposes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchPurposes.fulfilled, (state, action) => {
                state.isLoading = false
                state.specialPurposes = [
                    ...state.specialPurposes,
                    ...action.payload.data,
                ]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchPurposes.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.specialPurposes = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchPurposeDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchPurposeDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.purposeDetails = action.payload
            })
            .addCase(fetchPurposeDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export const { setCurrentPage } = specialpurposesSlice.actions
export default specialpurposesSlice.reducer
