import { Formik, Form } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux"
import parseISO from "date-fns/parseISO"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"

import Steps from "../Steps/Steps"
import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import "./profile-forms.css"
import { scrollTop, staticBaseURI } from "../../../../config"
import validateWorkDetails from "./validation/validateWorkDetails"
import { useEffect } from "react"
import {
    changeEditRow,
    changeIsEdit,
    editWorkDetails,
    fetchWorkDetails,
    submitWorkDetails,
} from "../../../../features/auth/authSlice"
import WorkItem from "./WorkItem"
import CheckBox from "../../../../components/Inputs/CheckBox"

const WorkDetails = () => {
    const dispatch = useDispatch()

    const { login, isLoading, workDetails, isEdit, editRow } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        scrollTop()
        dispatch(fetchWorkDetails())
    }, [dispatch])

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    const setIsEdit = () => {
        dispatch(changeIsEdit())
    }

    const setEditRow = (id) => {
        dispatch(changeEditRow(id))
    }

    const handleSubmit = (values, actions) => {
        if (isEdit) {
            dispatch(editWorkDetails({ values, actions }))
        } else {
            dispatch(submitWorkDetails({ values, actions }))
        }
    }

    const initialValues = {
        job_title: "",
        company: "",
        from: "",
        to: "",
        present: "",
    }

    const selectedRow = workDetails.find((row) => row.id === editRow)

    let editRowValues = {
        job_title: "",
        company: "",
        from: "",
        to: "",
        id: "",
        present: "",
    }

    if (isEdit && selectedRow) {
        editRowValues = {
            job_title: selectedRow.job_title,
            company: selectedRow.company,
            from: parseISO(`${selectedRow.start}-01-01`),
            to: selectedRow.end ? parseISO(`${selectedRow.end}-01-01`) : "",
            id: selectedRow.id,
            present: selectedRow.present ? true : false,
        }
    }

    return (
        <>
            <div className="grid grid-cols-5">
                <div className="col-span-5 md:col-span-2">
                    <Steps step={3} />
                </div>
                <motion.div
                    initial={{ opacity: 0, x: 300 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="col-span-5 md:col-span-3 py-16 px-8"
                >
                    <h1 className="font-bold text-4xl">Work Details</h1>
                    <h3 className="text-lg text-gray-700">
                        Please enter your work details
                    </h3>
                    <div>
                        <Formik
                            initialValues={
                                isEdit ? editRowValues : initialValues
                            }
                            validationSchema={validateWorkDetails}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                handleSubmit(values, actions)
                            }}
                        >
                            {(formikProps: FormikProps<any>) => (
                                <Form className="pt-8">
                                    <TextInput
                                        name="job_title"
                                        type="text"
                                        placeholder="JOB TITLE"
                                        color="dark"
                                    />
                                    <TextInput
                                        name="company"
                                        type="text"
                                        placeholder="COMPANY NAME"
                                        color="dark"
                                    />
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div className="w-full">
                                            <label>From: </label>
                                            <DatePicker
                                                selected={
                                                    formikProps.values.from
                                                }
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "from",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="text-input"
                                                placeholderText="Click to select year"
                                            />
                                            {formikProps.touched.from &&
                                            formikProps.errors.from ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.from}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="w-full">
                                            <label>To: </label>
                                            <DatePicker
                                                selected={formikProps.values.to}
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "to",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="text-input"
                                                disabled={
                                                    formikProps.values.present
                                                        ? true
                                                        : false
                                                }
                                                placeholderText="Click to select year"
                                            />
                                            {formikProps.touched.to &&
                                            formikProps.errors.to ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.to}
                                                </div>
                                            ) : null}
                                            <div className="mt-4">
                                                <CheckBox
                                                    name="present"
                                                    color="black"
                                                >
                                                    I currently work here
                                                </CheckBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-8">
                                        <button
                                            type="submit"
                                            className="text-white px-6 py-2 submit-btn"
                                            disabled={isLoading ? true : false}
                                        >
                                            {isLoading ? (
                                                <div className="inline-flex">
                                                    <Loader
                                                        size="6"
                                                        classes="mr-2 w-6 h-6 fill-blue-200"
                                                    />
                                                    <div className="self-center">
                                                        Please wait...
                                                    </div>
                                                </div>
                                            ) : (
                                                `Add Details`
                                            )}
                                        </button>
                                        {/* <a
                                            href={`${staticBaseURI}/profile/work-details`}
                                            className="text-white px-6 py-2 proceed-btn ml-2"
                                        >
                                            Save & Continue
                                        </a> */}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {workDetails && workDetails.length > 0 && (
                        <div className="mt-10">
                            {workDetails.map((work) => (
                                <WorkItem
                                    key={work.id}
                                    company={work.company}
                                    job_title={work.job_title}
                                    from={work.start}
                                    to={work.end}
                                    isPresent={work.present}
                                    id={work.id}
                                    isEdit={setIsEdit}
                                    editRow={setEditRow}
                                />
                            ))}
                            <div className="flex justify-between mt-8">
                                <Link
                                    to={`/profile/education-details`}
                                    className="text-white px-6 py-2 proceed-btn ml-2"
                                >
                                    Go Back
                                </Link>
                                <Link
                                    to={`/dashboard`}
                                    className="text-white px-6 py-2 proceed-btn ml-2"
                                >
                                    Save & Continue
                                </Link>
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>
        </>
    )
}

export default WorkDetails
