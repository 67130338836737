import { api } from "../../api"

//fetch how donation helps
const fetchHowDonationHelps = async () => {
    const url = `/how-donation-helps`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch what endowment allows
const fetchWhatEndowmentAllows = async () => {
    const url = `/what-endowments-allow`
    const response = await api.get(url)
    return response.data
}

const whyDonateUsService = {
    fetchWhatEndowmentAllows,
    fetchPageContent,
    fetchHowDonationHelps,
}

export default whyDonateUsService
