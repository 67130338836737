import React from "react"
import { apiBaseURI } from "../../config"

const StudentTeamItem = ({ name, image, designation }) => {
    return (
        <div className="mx-4">
            <div className="flex justify-center">
                <img
                    alt={name}
                    src={`${apiBaseURI}${image}`}
                    className="rounded-full"
                />
            </div>
            <div className="text-center mt-8">
                <h2 className="font-bold text-4xl mb-2 eft-student-name h-12">
                    {name}
                </h2>
                <h3 className="uppercase text-xl text-white">{designation}</h3>
            </div>
        </div>
    )
}

export default StudentTeamItem
