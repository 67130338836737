import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/newsletters/newsletterSlice"
import About from "./About"
import ArchivesList from "./ArchivesList"
import "./newsletters.css"

const NewsLetterArchives = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.newsletterPage)

    useEffect(() => {
        dispatch(fetchPageContent("newsletter-archive"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About about={pageContent.content} />
            <ArchivesList />
        </>
    )
}

export default NewsLetterArchives
