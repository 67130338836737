import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import alumniAffairsService from "./alumniAffairsService"

const initialState = {
    pageContent: {},
    news: [],
    team: [],
    errorNews: false,
    errorTeam: false,
    loadingNews: false,
    loadingTeam: false,
}

export const fetchPageContent = createAsyncThunk(
    "alumniAffairs/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await alumniAffairsService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchNews = createAsyncThunk(
    "alumniAffairs/fetchNews",
    async (limit, thunkAPI) => {
        try {
            return await alumniAffairsService.fetchNews(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAlumniTeam = createAsyncThunk(
    "alumniAffairs/fetchAlumniTeam",
    async (limit, thunkAPI) => {
        try {
            return await alumniAffairsService.fetchAlumniTeam(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const alumniAffairsSlice = createSlice({
    name: "alumniAffairs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchNews.pending, (state) => {
                state.loadingNews = true
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.loadingNews = false
                state.news = action.payload
            })
            .addCase(fetchNews.rejected, (state, action) => {
                state.loadingNews = false
                state.errorNews = true
                state.errorMessageNews = action.payload
                state.news = []
            })
            .addCase(fetchAlumniTeam.pending, (state) => {
                state.loadingTeam = true
            })
            .addCase(fetchAlumniTeam.fulfilled, (state, action) => {
                state.loadingTeam = false
                state.team = action.payload
            })
            .addCase(fetchAlumniTeam.rejected, (state, action) => {
                state.loadingTeam = false
                state.errorTeam = true
                state.team = []
                state.errorMessageTeam = action.payload
            })
    },
})

export default alumniAffairsSlice.reducer
