import { apiBaseURI, staticBaseURI } from "../../config"

const MemoryItem = ({ title, image, slug }) => {
    return (
        <div className="alumni-news-item m-2">
            <a href={`${staticBaseURI}/memory-details/${slug}`}>
                <div className="rounded-2xl overflow-hidden shadow-md">
                    <div
                        className="h-[200px] w-full album-item"
                        style={{
                            backgroundImage: `url(${apiBaseURI}${image})`,
                        }}
                    ></div>
                    <div className=" border-gray-300 border bg-white">
                        <div className="px-6 py-4">
                            <div className="font-bold xs:text-2xl sm:text-2xl mb-2 h-10 md:h-16">
                                {title}
                            </div>
                        </div>
                        <div className="px-6 pt-4 pb-8">
                            <button
                                type="button"
                                className="font-bold xs:text-base sm:text-lg"
                            >
                                View Photos &gt;
                            </button>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default MemoryItem
