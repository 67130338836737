import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch reunions
const fetchReunions = async (limit, page) => {
    const url = `/reunions/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch reunion details
const fetchReunionDetails = async (slug) => {
    const url = `/reunion-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const reunionService = {
    fetchReunions,
    fetchPageContent,
    fetchReunionDetails,
}

export default reunionService
