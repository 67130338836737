import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { fetchFacilities } from "../../features/facilities/facilitiesSlice"
import About from "./About"
import "./facilities.css"
import FacilitiesCarousel from "./FacilitiesCarousel"
import FacilityInfo from "./FacilityInfo"

const Facilities = () => {
    const dispatch = useDispatch()

    const { facilities } = useSelector((state) => state.facilitiesPage)

    useEffect(() => {
        dispatch(fetchFacilities())
    }, [dispatch])

    return (
        <>
            <PageHeader image="./dists/images/facilities-bg.jpg" text={false} />
            <About />
            <FacilitiesCarousel facilities={facilities} />
            <FacilityInfo facilities={facilities} />
        </>
    )
}

export default Facilities
