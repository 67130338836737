import { useEffect } from "react"
import { FaChevronDown } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"

import About from "./About"
import ReunionItem from "./ReunionItem"
import "./reunions.css"
import {
    fetchReunions,
    fetchPageContent,
    setCurrentPage,
} from "../../features/reunions/reunionSlice"

const Reunions = () => {
    const dispatch = useDispatch()

    const { pageContent, currentPage, lastPage, reunions } = useSelector(
        (state) => state.reunionsPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("reunions"))
        dispatch(fetchReunions(currentPage))
    }, [dispatch, currentPage])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <About about={pageContent.content} />
            <section className="anp-split-bg-blue-colors">
                <div className="px-0 md:px-4">
                    <div className="container mx-auto bg-white py-6 px-0 md:px-6 -mt-4 md:-mt-8 relative rounded-xl">
                        <div className="alumni-news-wrapper mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 relative -mb-14 gap-y-4">
                            {reunions.map((item) => (
                                <ReunionItem
                                    key={item.id}
                                    title={item.title}
                                    image={item.image_url}
                                    slug={item.slug}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="bg-blue-dark py-16 flex justify-center -mt-8">
                    {currentPage < lastPage && (
                        <button
                            className="mt-16"
                            type="button"
                            onClick={() =>
                                dispatch(setCurrentPage(currentPage + 1))
                            }
                        >
                            <div className="uppercase text-xl rounded-lg bg-white px-4 py-2">
                                Load More
                            </div>
                            <div className="text-white text-2xl flex mt-1 justify-center">
                                <FaChevronDown />
                            </div>
                        </button>
                    )}
                </div>
            </section>
        </>
    )
}

export default Reunions
