import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { FiChevronDown, FiChevronUp, FiArrowUp } from "react-icons/fi"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./footer.css"
import NewsLetter from "./NewsLetter/NewsLetter"
import Contact from "./Contact/Contact"
import { scrollFunction, scrollTop, staticBaseURI } from "../../config"
import { fetchQuickLinks } from "../../features/config/configSlice"

const Footer = () => {
    const dispatch = useDispatch()
    const { quickLinks } = useSelector((state) => state.config)

    useEffect(() => {
        let summary = document.querySelector("summary")
        summary.addEventListener("click", function () {
            checkOpenStatus()
        })
        autoToggleMenu()

        dispatch(fetchQuickLinks())
    }, [dispatch])

    //Toggle Menu Based On Device Size
    function autoToggleMenu() {
        const footer = document.querySelector("#footer-menu")
        if (Math.min(window.screen.width, window.screen.height) < 568) {
            footer.removeAttribute("open")
        } else {
            footer.setAttribute("open", "")
        }
    }

    window.onscroll = function () {
        scrollFunction()
    }

    const checkOpenStatus = () => {
        let expand = document.querySelector(".expand-footer")
        if (expand.hasAttribute("open")) {
            document.querySelector(".up-arrow").classList.add("hidden")
            document.querySelector(".down-arrow").classList.remove("hidden")
        } else {
            document.querySelector(".up-arrow").classList.remove("hidden")
            document.querySelector(".down-arrow").classList.add("hidden")
        }
    }

    return (
        <>
            <Contact />
            <NewsLetter />
            <footer className="bg-blue-footer pb-4">
                <div className="container mx-auto px-6">
                    <div className="relative md:-mt-8 lg:-mt-12">
                        <div className="flex justify-center">
                            <img
                                src={`${staticBaseURI}/dists/images/logo.png`}
                                className=" w-32 grayscale"
                                alt="RGU Logo"
                            />
                        </div>
                        <h3 className="text-[21px] md:text-3xl text-center text-white font-bold">
                            RGU Alumni
                        </h3>
                    </div>
                    <div className="flex justify-evenly footer-links mt-20 uppercase text-white md:text-lg lg:text-xl">
                        <a href="#0">About RGU</a>
                        <a href="#0">Meet Our Vice Chancellor</a>
                        <a href="#0">How To Reach RGU</a>
                        <a href="#0">Contact</a>
                    </div>
                    <div className="flex footer-social-icons justify-center text-gray-500 mt-12">
                        <a href="#0">
                            <FaFacebookF />
                        </a>
                        <a href="#0">
                            <FaTwitter />
                        </a>
                        <a href="#0">
                            <FaLinkedinIn />
                        </a>
                    </div>
                    <div className="flex justify-center mt-10 mb-6">
                        <div className="vertical-line border-r-2 border-r-white h-24"></div>
                    </div>

                    <details
                        className="duration-300 expand-footer mb-4 open:mb-0"
                        id="footer-menu"
                    >
                        <summary className="text-white uppercase bg-inherit text-base md:text-2xl border-b-2 pb-2 cursor-pointer flex justify-between md:mx-5 lg:mx-10 footer-expand-btn">
                            <div>Expand</div>
                            <div>
                                <span className="down-arrow">
                                    <FiChevronDown />
                                </span>
                                <span className="hidden up-arrow">
                                    <FiChevronUp />
                                </span>
                            </div>
                        </summary>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                            <div className="md:px-5 lg:px-10 border-r-2 footer-links-wrapper">
                                <div className="footer-header uppercase font-bold underline underline-offset-8 leading-8 md:text-xl lg:text-2xl text-white">
                                    Major Areas
                                </div>
                                <ul className="text-white footer-links md:text-lg lg:text-xl capitalize mt-8">
                                    <li>
                                        <a href="#0">About</a>
                                    </li>
                                    <li>
                                        <a href="#0">Alumni Affairs</a>
                                    </li>
                                    <li>
                                        <a href="#0">Alumna Connect</a>
                                    </li>
                                    <li>
                                        <a href="#0">Alumni Association</a>
                                    </li>
                                    <li>
                                        <a href="#0">Donation</a>
                                    </li>
                                    <li>
                                        <a href="#0">Forum</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="md:px-5 lg:px-10 border-r-2 footer-links-wrapper">
                                <div className="footer-header uppercase font-bold underline underline-offset-8 leading-8 md:text-xl lg:text-2xl text-white">
                                    Quick Links
                                </div>
                                <ul className="text-white footer-links md:text-lg lg:text-xl capitalize mt-8">
                                    {quickLinks.map((link) => (
                                        <li key={link.id}>
                                            <a
                                                href={`${staticBaseURI}/quick-link/${link.slug}`}
                                            >
                                                {link.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="md:px-5 lg:px-10 footer-links-wrapper">
                                <div className="footer-header uppercase font-bold underline underline-offset-8 leading-8 md:text-xl lg:text-2xl text-white">
                                    Endowment Fund
                                </div>
                                <ul className="text-white footer-links md:text-lg lg:text-xl capitalize mt-8">
                                    <li>
                                        <a href="#0">Online Transfer</a>
                                    </li>
                                    <li>
                                        <a href="#0">Fund Structure</a>
                                    </li>
                                    <li>
                                        <a href="#0">Ways To Fund</a>
                                    </li>
                                    <li>
                                        <a href="#0">Testimonials</a>
                                    </li>
                                    <li>
                                        <a href="#0">Alumni Donors</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </details>
                </div>
            </footer>
            <div className="bg-dark-blue-newsletter p-3 text-center text-white">
                &copy; Rajiv Gandhi University, {new Date().getFullYear()}
            </div>

            <div
                className="fixed bottom-6 right-4 hidden"
                id="scroll-to-top-btn"
            >
                <button
                    type="button"
                    title="Scroll to Top"
                    className="flex gap-1 items-center bg-blue-800 text-white z-50 px-3 py-3 rounded-full border-4 border-white "
                    onClick={() => scrollTop()}
                >
                    <FiArrowUp />
                </button>
            </div>
        </>
    )
}

export default Footer
