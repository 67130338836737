import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FaChevronRight } from "react-icons/fa"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import NewsDetailsBody from "./NewsDetailsBody"
import NewsDetailsMore from "./NewsDetailsMore"
import { fetchNewsDetails } from "../../features/news/newsSlice"
import { apiBaseURI, staticBaseURI } from "../../config"

const NewsDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { newsDetails, moreNews, nextNews } = useSelector(
        (state) => state.newspage
    )

    useEffect(() => {
        dispatch(fetchNewsDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{newsDetails.meta_title}</title>
                <meta
                    name="description"
                    content={newsDetails.meta_description}
                />
                <meta name="keywords" content={newsDetails.meta_keywords} />
            </Helmet>

            <section className="anp-header relative">
                <div
                    className="ph-img-wrapper"
                    style={{
                        backgroundImage: `url(${apiBaseURI}${newsDetails.image_url})`,
                    }}
                ></div>
                <div className="absolute bottom-0 left-0 anp-slider-caption px-4 pb-12 pt-8">
                    <div className="container mx-auto px-6">
                        <div className="flex justify-between text-white">
                            <div className="flex flex-col lg:flex-row news-highlights self-center">
                                <h3 className="xs:text-lg md:text-2xl xl:text-3xl mr-2 xl:mr-4 font-bold flex-1 underline uppercase">
                                    News Highlights:
                                </h3>
                                {nextNews && (
                                    <a
                                        href={`${staticBaseURI}/news-details/${nextNews.slug}`}
                                        className="flex self-center overflow-hidden"
                                    >
                                        <div className="xs:text-base md:text-lg xl:text-xl flex-1 lg:mt-0 mt-4">
                                            {nextNews.title}
                                        </div>
                                        <div className="self-center">
                                            <button className="right-arrow-btn px-3 py-2 rounded-lg mr-2">
                                                <FaChevronRight />
                                            </button>
                                        </div>
                                    </a>
                                )}
                            </div>
                            <div className="text-right text-white anp-page-name border-l-4 pl-6 md:mt-0 mt-4">
                                <h1 className="text-3xl md:text-4xl xl:text-5xl font-bold">
                                    Alumni
                                </h1>
                                <h3 className="text-xl md:text-2xl xl:text-3xl font-semibold">
                                    News
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewsDetailsBody
                content={newsDetails.content}
                title={newsDetails.title}
            />
            <NewsDetailsMore moreNews={moreNews} nextNews={nextNews} />
        </>
    )
}

export default NewsDetails
