import * as DOMPurify from "dompurify"

const About = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="relative bg-blue-dark px-4 py-8">
            <div className="container mx-auto p-6 px-4">
                <div className="flex justify-end mt-10">
                    <div className="bg-text top-2/4 left-8 -translate-y-1/2 about-bg-text">
                        alumni
                    </div>
                    <div className="text-white w-full md:w-2/3 text-xl">
                        <div className="flex justify-end mb-8">
                            <a
                                href="#0"
                                className="read-more-btn py-2 flex self-center w-fit"
                            >
                                <span className="light-blue-dot rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
                                Read more
                            </a>
                        </div>
                        <div
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
