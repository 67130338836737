import AwardWinnerItem from "./AwardWinnerItem"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchAlumnis } from "../../features/alumni-of-the-month/alumniOfTheMonthSlice"
import ImageCard from "../../components/Skeletons/ImageCard"

const AwardWinners = () => {
    const dispatch = useDispatch()

    const { alumns, currentPage } = useSelector(
        (state) => state.alumniOfTheMonthpage
    )

    useEffect(() => {
        dispatch(fetchAlumnis(currentPage))
    }, [dispatch, currentPage])

    return (
        <section className="young-alumni-winners pt-12 pb-20 px-4">
            <div className="container mx-auto px-4 pt-4">
                <div
                    className="grid md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-20"
                    id="winners-list"
                >
                    {alumns.length < 1
                        ? [...Array(4)].map((i) => <ImageCard key={i} />)
                        : alumns.map((winner) => (
                              <AwardWinnerItem
                                  key={winner.id}
                                  image={winner.image_url}
                                  name={winner.name}
                                  month={winner.award_month}
                                  slug={winner.slug}
                              />
                          ))}
                </div>
            </div>
        </section>
    )
}

export default AwardWinners
