import * as Yup from "yup"
import { staticBaseURI } from "../../../../../config"

const validateBasicDetails = Yup.object({
    name: Yup.string().min(2, "Too Short!").required("Please enter your name"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Please enter your email"),
    mobile: Yup.string()
        .min(10, "Invalid mobile number")
        .max(10, "Invalid mobile number")
        .required("Please enter mobile number"),
    gender: Yup.string().required("Please select your gender"),
    dob: Yup.string().required("Please select your date of birth"),
    department: Yup.string().required("Please select your department"),
    course: Yup.string().required("Please select your course"),
    batch: Yup.string().required("Please select your batch"),
    registration_id: Yup.string().required("Please enter your Registration ID"),
    adr_line_1: Yup.string().required("Please enter your address"),
    country: Yup.string().required("Please select your country"),
    state: Yup.string().required("Please select your state"),
    city: Yup.string().required("Please select your city"),
    pin: Yup.string().required("Please enter your PIN"),
    photo_url: Yup.string().nullable(),
    photo: Yup.string().when("photo_url", {
        is: (photo_url) =>
            photo_url === `${staticBaseURI}/dists/images/default-user.jpg`,
        then: Yup.string().required("Please select a photo"),
        otherwise: Yup.string().notRequired(),
    }),
})

export default validateBasicDetails
