import React from 'react'

import './page-header.css'

const index = ({ image, title, position, text=true }) => {

    let styles
    if(position === 'left'){
        styles = 'left-0 md:left-8 absolute'
    }
    else if(position === 'right'){
        styles = 'right-0 md:right-8 absolute '
    }
    else if(position === 'center'){
        styles = 'absolute left-0 md:left-[30%]'
    }

  return (
    <section className="relative single-page-header" style={{ backgroundImage: `url(${image})` }}>
        {
            text && (
                <div className={`bg-dark-blue-newsletter overflow-hidden z-10 text-white uppercase text-center py-4 px-2 md:py-8 md:px-4 -bottom-8 md:-bottom-14 text-xl sm:text-2xl md:text-4xl font-semibold w-11/12 md:w-1/2 lg:w-2/5 ${styles}`}>
                    {title}
                </div>
            )
        }
    </section>
  )
}

export default index