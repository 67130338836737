import { FaChevronDown } from "react-icons/fa"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import "./alumni-news-page.css"
import PageHeader from "./PageHeader"
import SectionTitle from "../../components/SectionTitle"
import AlumniNewsItem from "../Homepage/AlumniNews/AlumniNewsItem"
import { fetchAllNews } from "../../features/news/newsSlice"
import ImageCard from "../../components/Skeletons/ImageCard"

function AlumniNews() {
    const dispatch = useDispatch()

    //use isLoading, isError, errorMessage for error handling
    const { news, currentPage, lastPage } = useSelector(
        (state) => state.newspage
    )

    useEffect(() => {
        dispatch(fetchAllNews(currentPage))
    }, [dispatch, currentPage])

    return (
        <>
            <PageHeader />
            <section className="anp-split-bg-blue-colors">
                <div className="px-0 md:px-4">
                    <div className="container mx-auto bg-white py-6 px-0 md:px-6 -mt-4 md:-mt-8 relative rounded-xl">
                        <div className="px-4 sm:px-0">
                            <SectionTitle title="Alumni News" button={false} />
                        </div>
                        <div className="alumni-news-wrapper mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 relative -mb-14 gap-y-4">
                            {news.length < 1
                                ? [...Array(4)].map((i) => (
                                      <ImageCard key={i} />
                                  ))
                                : news.map((item) => (
                                      <AlumniNewsItem
                                          key={item.id}
                                          image={item.image_url}
                                          title={item.title}
                                          slug={item.slug}
                                      />
                                  ))}
                        </div>
                    </div>
                </div>

                <div className="bg-blue-dark py-16 flex justify-center -mt-8">
                    {currentPage < lastPage && (
                        <button
                            className="mt-16"
                            type="button"
                            onClick={() =>
                                dispatch(fetchAllNews(currentPage + 1))
                            }
                        >
                            <div className="uppercase text-xl rounded-lg bg-white px-4 py-2">
                                Load More
                            </div>
                            <div className="text-white text-2xl flex mt-1 justify-center">
                                <FaChevronDown />
                            </div>
                        </button>
                    )}
                </div>
            </section>
        </>
    )
}

export default AlumniNews
