import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchEventDetails } from "../../features/events/eventsSlice"
import About from "./About"
import Details from "./Details"
import "./event-details.css"
import OngoingEvents from "./OngoingEvents"

const EventDetails = () => {
    let params = useParams()
    let dispatch = useDispatch()

    const { eventDetails, ongoingEvents } = useSelector(
        (state) => state.eventsPage
    )

    useEffect(() => {
        dispatch(fetchEventDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${eventDetails.image_url}`}
            />
            <About
                day={eventDetails.event_day}
                date={eventDetails.formatted_event_date}
                title={eventDetails.title}
            />
            <Details
                description={eventDetails.description}
                guest_name={eventDetails.guest_name}
                guest_image={eventDetails.guest_image_url}
                guest_info={eventDetails.guest_info}
            />
            <OngoingEvents events={ongoingEvents} />
        </>
    )
}

export default EventDetails
