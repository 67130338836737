import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import newsletterService from "./newsletterService"

const initialState = {
    newsletters: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    newsletterDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchNewsletters = createAsyncThunk(
    "newsletter/fetchNewsletters",
    async (data, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().distinguishedAlumnsPage
            return await newsletterService.fetchNewsletters(
                state.perPage,
                data.status,
                data.currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "newsletter/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await newsletterService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchLatestNewsletter = createAsyncThunk(
    "newsletter/fetchLatestNewsletter",
    async (_, thunkAPI) => {
        try {
            return await newsletterService.fetchLatestNewsletter()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchNewsletterDetails = createAsyncThunk(
    "newsletter/fetchNewsletterDetails",
    async (slug, thunkAPI) => {
        try {
            return await newsletterService.fetchNewsletterDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const newsletterSlice = createSlice({
    name: "newsletter",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewsletters.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNewsletters.fulfilled, (state, action) => {
                state.isLoading = false
                state.newsletters = [
                    ...state.newsletters,
                    ...action.payload.data,
                ]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchNewsletters.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.newsletters = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchLatestNewsletter.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchLatestNewsletter.fulfilled, (state, action) => {
                state.isLoading = false
                state.newsletterDetails = action.payload
            })
            .addCase(fetchLatestNewsletter.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
                state.newsletterDetails = {}
            })
            .addCase(fetchNewsletterDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNewsletterDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.newsletterDetails = action.payload
            })
            .addCase(fetchNewsletterDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
                state.newsletterDetails = {}
            })
    },
})

export default newsletterSlice.reducer
