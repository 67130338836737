import { api } from "../../api"

//fetch
const fetchChapter = async () => {
    const url = `/chapter-policy`
    const response = await api.get(url)
    return response.data
}

const chapterpolicyService = {
    fetchChapter,
}

export default chapterpolicyService
