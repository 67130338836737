import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import chapterpolicyService from "./chapterpolicyService"

const initialState = {
    chapter: {},
    isLoading: false,
    isError: false,
}

export const fetchChapter = createAsyncThunk(
    "chapterpolicy/fetchChapter",
    async (_, thunkAPI) => {
        try {
            return await chapterpolicyService.fetchChapter()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const chapterpolicySlice = createSlice({
    name: "chapterpolicy",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChapter.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchChapter.fulfilled, (state, action) => {
                state.chapter = action.payload
                state.isLoading = false
            })
            .addCase(fetchChapter.rejected, (state, action) => {
                state.chapter = {}
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export default chapterpolicySlice.reducer
