import React, { useEffect } from "react"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux"

import { ECMemberItem } from "./ECMemberItem"
import { fetchVcMembers } from "../../features/alumni-association/alumniAssociationSlice"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"

function ECMembers() {
    const dispatch = useDispatch()

    const { vcMembers } = useSelector((state) => state.alumniAssociationPage)

    useEffect(() => {
        dispatch(fetchVcMembers(8))
    }, [dispatch])

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark py-16 px-4">
            <div className="container mx-auto px-6">
                <div className="aa-ec-members-header text-blue-light font-semibold uppercase text-2xl md:text-3xl lg:text-4xl underline underline-offset-8 mb-10">
                    Elected EC Members
                </div>
                <div className="-mx-[4rem] md:mx-0 ec-members-carousel">
                    <Slider {...settings} className="">
                        {vcMembers.map((item) => (
                            <ECMemberItem
                                key={item.id}
                                name={item.name}
                                designation={item.designation}
                                image={item.image_url}
                                year={item.batch}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default ECMembers
