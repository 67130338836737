import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ImageCard from "../../components/Skeletons/ImageCard"
//import { staticBaseURI } from "../../config"
import {
    fetchEvents,
    resetEvents,
    setType,
} from "../../features/events/eventsSlice"
import EventItem from "../Homepage/Events/EventItem"

const EventsList = () => {
    const dispatch = useDispatch()

    const { events, currentPage, type, isLoading } = useSelector(
        (state) => state.eventsPage
    )

    useEffect(() => {
        dispatch(fetchEvents(currentPage))
    }, [dispatch, currentPage, type])

    function handleSelectInput(ev) {
        dispatch(resetEvents())
        dispatch(setType(ev.target.value))
    }

    return (
        <section className="bg-blue-dark pt-12 px-4">
            <div className="container mx-auto px-4 pt-4">
                <div className="year-select-input-wrapper flex flex-col mb-8">
                    <label className="text-white text-lg mb-1">
                        Filter Events:
                    </label>
                    <select
                        className="custom-select-input rounded-md focus:outline-none w-[80%] md:w-[40%] lg:w-[20%] mb-4"
                        onChange={(ev) => handleSelectInput(ev)}
                        value={type}
                    >
                        <option value="all">All Events</option>
                        <option value="past">Past Events</option>
                        <option value="ongoing">Ongoing Events</option>
                        <option value="upcoming">Upcoming Events</option>
                    </select>
                    <hr />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-8">
                    {isLoading && events.length < 1
                        ? [...Array(4)].map((i) => <ImageCard key={i} />)
                        : events.length < 1
                        ? ""
                        : events.map((event) => (
                              <EventItem
                                  key={event.id}
                                  title={event.title}
                                  image={event.image_url}
                                  date={event.formatted_event_date}
                                  time={event.time}
                                  day={event.event_day}
                                  type={event.type}
                                  url={event.slug}
                                  category={event.event_category}
                              />
                          ))}
                </div>

                {/* <div className="flex justify-center mt-12">
                        <img
                        src={`${staticBaseURI}/dists/images/oval.svg`}
                        alt="loading"
                        />
                    </div> */}
            </div>
        </section>
    )
}

export default EventsList
