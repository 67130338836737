import * as DOMPurify from "dompurify"
import Slider from "react-slick"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"

import { apiBaseURI } from "../../config"

const FacilityInfoItem = ({ name, images, description, slug }) => {
    let cleanContent = DOMPurify.sanitize(description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }

    return (
        <div className="facility-info-item" id={slug}>
            <div className="w-full md:w-2/3 mx-auto">
                <Slider {...settings} className="facility-images-carousel">
                    {images.map((image) => (
                        <img
                            key={`facility-image-${image.id}`}
                            src={`${apiBaseURI}${image.image_url}`}
                            alt={name}
                        />
                    ))}
                </Slider>
            </div>
            <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl uppercase mt-8">
                {name}
            </h1>
            <div
                className="mt-4"
                dangerouslySetInnerHTML={createMarkup(cleanContent)}
            ></div>
        </div>
    )
}

export default FacilityInfoItem
