import { BiChevronRight, BiChevronLeft, BiX } from "react-icons/bi"
import { useSelector } from "react-redux"

import "./mobile-menu.css"
import MobileMenuLink from "./MobileMenuLink"

const MobileMenu = () => {
    const { menu } = useSelector((state) => state.config)

    return (
        <>
            <nav>
                <ul className="pushNav js-topPushNav">
                    <li className="closeLevel js-closeLevelTop hdg menu-link-w-icon">
                        <i className="left">
                            <BiX />
                        </i>
                        Close
                    </li>
                    <li>
                        <a href="#0">
                            <i className="fa fa-home"></i>
                            Home
                        </a>
                    </li>

                    <li>
                        {/* Begin section 1 */}
                        <div className="openLevel js-openLevel menu-link-w-icon">
                            Alumni Affairs
                            <i>
                                <BiChevronRight />
                            </i>
                        </div>
                        <ul className="pushNav pushNav_level js-pushNavLevel">
                            <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                <i className="left">
                                    <BiChevronLeft />
                                </i>
                                Go Back
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    About
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.alumni_affairs ? (
                                        <li>
                                            <MobileMenuLink
                                                url="alumni-affairs"
                                                title="Introduction"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.alumni_news ? (
                                        <li>
                                            <MobileMenuLink
                                                url="news"
                                                title="Alumni News"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.alumni_database ? (
                                        <li>
                                            <MobileMenuLink
                                                url="alumni-database"
                                                title="Alumni Database"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.newsletter ? (
                                        <li>
                                            <MobileMenuLink
                                                url="newsletter"
                                                title="Newsletter"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.newsletter_archive ? (
                                        <li>
                                            <MobileMenuLink
                                                url="newsletter-archive"
                                                title="Newsletter Archive"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.memories ? (
                                        <li>
                                            <MobileMenuLink
                                                url="memories"
                                                title="Memories"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Recognitions
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.distinguished_alumni ? (
                                        <li>
                                            <MobileMenuLink
                                                url="distinguished-alumni-award"
                                                title="Distinguished Alumni Awards"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.distinguished_alumni_service ? (
                                        <li>
                                            <MobileMenuLink
                                                url="young-alumni-achievements"
                                                title="Young Alumni Achievements"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.young_alumni ? (
                                        <li>
                                            <MobileMenuLink
                                                url="distinguished-alumni-service-award"
                                                title="Distinguished Alumni Service
                                        Award"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.alumni_of_the_month ? (
                                        <li>
                                            <MobileMenuLink
                                                url="alumni-of-the-month"
                                                title="Alumni of The Month"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Contributions
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.scholarships_awards ? (
                                        <li>
                                            <MobileMenuLink
                                                url="scholarships-awards"
                                                title="Scholarships and Awards"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.chairs ? (
                                        <li>
                                            <MobileMenuLink
                                                url="chairs"
                                                title="Chairs"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.special_purposes ? (
                                        <li>
                                            <MobileMenuLink
                                                url="special-purposes"
                                                title="Special Purposes"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.class_contribution ? (
                                        <li>
                                            <MobileMenuLink
                                                url="class-contribution"
                                                title="Class Contributions"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Edowment Fund
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.endowment_fund ? (
                                        <li>
                                            <MobileMenuLink
                                                url="endowment-fund"
                                                title="About Us"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.why_donate ? (
                                        <li>
                                            <MobileMenuLink
                                                url="why-donate-us"
                                                title="Why Donate"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.how_to_donate ? (
                                        <li>
                                            <MobileMenuLink
                                                url="how-to-donate"
                                                title="How To Donate"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.team ? (
                                        <li>
                                            <MobileMenuLink
                                                url="team"
                                                title="Team"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.recognition ? (
                                        <li>
                                            <MobileMenuLink
                                                url="recognition"
                                                title="Recognition"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.faq ? (
                                        <li>
                                            <MobileMenuLink
                                                url="faq"
                                                title="FAQ's"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Occassions
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {/* {menu.alumni_lecture ? (
                                        <li>
                                            <MobileMenuLink title="Alumni Lecture Series" />
                                        </li>
                                    ) : null} */}

                                    {menu.tech_talks ? (
                                        <li>
                                            <MobileMenuLink
                                                url="tech-talks"
                                                title="Tech Talks"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Services
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.degree_certificates ? (
                                        <li>
                                            <MobileMenuLink
                                                url="degree-certificates"
                                                title="Degrees &amp; Certificates"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.facilities ? (
                                        <li>
                                            <MobileMenuLink
                                                url="facilities"
                                                title="Institute Facilities"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div className="openLevel js-openLevel menu-link-w-icon">
                            Alumni Association
                            <i>
                                <BiChevronRight />
                            </i>
                        </div>
                        <ul className="pushNav pushNav_level js-pushNavLevel">
                            <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                <i className="left">
                                    <BiChevronLeft />
                                </i>
                                Go Back
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    About
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.alumni_association ? (
                                        <li>
                                            <MobileMenuLink
                                                url="alumni-association"
                                                title="About"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.office_bearers ? (
                                        <li>
                                            <MobileMenuLink
                                                url="office-bearers"
                                                title="Office Bearers"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.bye_laws ? (
                                        <li>
                                            <MobileMenuLink
                                                url="by-laws"
                                                title="Bye Laws"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.chapter_policy ? (
                                        <li>
                                            <MobileMenuLink
                                                url="chapter-policy"
                                                title="Chapter Policy"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Events
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.latest_events ? (
                                        <li>
                                            <MobileMenuLink
                                                url="events"
                                                title="Latest Events"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.reunions ? (
                                        <li>
                                            <MobileMenuLink
                                                url="reuninons"
                                                title="Reunions"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.chapters ? (
                                        <li>
                                            <MobileMenuLink
                                                url="chapters"
                                                title="Chapters"
                                            />
                                        </li>
                                    ) : null}

                                    {menu.alumni_day ? (
                                        <li>
                                            <MobileMenuLink
                                                url="alumni-day"
                                                title="Alumni Day"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                            <li>
                                <div className="openLevel js-openLevel menu-link-w-icon">
                                    Updates
                                    <i>
                                        <BiChevronRight />
                                    </i>
                                </div>
                                <ul className="pushNav pushNav_level js-pushNavLevel ">
                                    <li className="closeLevel js-closeLevel hdg menu-link-w-icon">
                                        <i className="left">
                                            <BiChevronLeft />
                                        </i>
                                        Go Back
                                    </li>
                                    {menu.noticeboard ? (
                                        <li>
                                            <MobileMenuLink
                                                url="notice-board"
                                                title="Noticeboard"
                                            />
                                        </li>
                                    ) : null}
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <MobileMenuLink url="give" title="Donate Now" />
                    </li>
                    <li>
                        <MobileMenuLink url="login" title="Login" />
                    </li>
                </ul>
            </nav>

            <span className="screen"></span>
        </>
    )
}

export default MobileMenu
