import React from "react"

const PageHeader = ({ image }) => {
    return (
        <section
            className="relative single-page-header"
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className="wd-page-header-text absolute bottom-12 left-2 md:left-10">
                <h1 className="text-xl md:text-5xl lg:text-6xl font-semibold">
                    Why Donate?
                </h1>
            </div>
        </section>
    )
}

export default PageHeader
