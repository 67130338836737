import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch default-class
const fetchDefaultContribution = async () => {
    const url = `/default-class-contribution`
    const response = await api.get(url)
    return response.data
}

//fetch class details
const fetchClassDetails = async (year) => {
    const url = `/class-contribution/${year}`
    const response = await api.get(url)
    return response.data
}

const classContributionService = {
    fetchPageContent,
    fetchDefaultContribution,
    fetchClassDetails,
}

export default classContributionService
