import React from "react"
import { apiBaseURI } from "../../config"

const DevTeamItem = ({ image, name, designation, info }) => {
    return (
        <>
            <div className="mx-4 dt-team-item relative">
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={name}
                    className="rounded-xl hover:grayscale w-full"
                />
                <div className="text-center mt-4">
                    <h2 className="font-bold text-3xl uppercase">{name}</h2>
                    <h4 className="text-xl">{designation}</h4>
                </div>

                <div className="dt-item-popup text-black p-6">
                    <div className="relative">
                        <h2 className="font-bold text-3xl uppercase">{name}</h2>
                        <h4 className="text-xl mb-4">{designation}</h4>
                        <div>{info}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevTeamItem
