import React from "react"
import { FaPlus } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import SectionTitle from "../../../../components/Resume/SectionTitle"
import { openModal } from "../../../../features/modal/modalSlice"
import SkillsForm from "./SkillsForm"

const Skills = ({ skills }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="mt-4">
                <div className="flex justify-between">
                    <SectionTitle title="Skills" />
                    <button
                        className="text-2xl text-blue-dark self-center  ml-3"
                        onClick={() => dispatch(openModal("newSkill"))}
                    >
                        <FaPlus />
                    </button>
                </div>
                <div>
                    <ul className=" list-disc pl-5 mt-4">
                        {skills.map((skill) => (
                            <li
                                key={skill.id}
                                className="font-bold text-lg mb-2"
                            >
                                {skill.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {isOpen && modalName === `newSkill` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Add New Skill</h3>
                        <hr className="my-4" />
                        <SkillsForm />
                    </>
                </Modal>
            )}
        </>
    )
}

export default Skills
