import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchTalkDetails } from "../../features/tech-talks/techTalkSlice"
import About from "./About"
import Details from "./Details"
import "./tech-talk-details.css"

const TechTalkDetails = () => {
    let params = useParams()

    const dispatch = useDispatch()

    const { talkDetails } = useSelector((state) => state.techTalksPage)

    useEffect(() => {
        dispatch(fetchTalkDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${talkDetails.image_url}`}
                text={false}
            />
            <About
                title={talkDetails.title}
                date={talkDetails.formatted_event_date}
                time={talkDetails.event_time}
                speaker={talkDetails.speaker}
            />
            <Details
                content={talkDetails.description}
                videoType={talkDetails.video_type}
                youtubeId={talkDetails.yt_video_id}
                video={talkDetails.video_url}
                title={talkDetails.title}
                youtubeLink={talkDetails.youtube_link}
            />
        </>
    )
}

export default TechTalkDetails
