import DOMPurify from "dompurify"
import NewsletterVideo from "./NewsletterVideo"

const ActiveNewsletters = ({ details }) => {
    let cleanContent = DOMPurify.sanitize(details.description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="bg-blue-dark px-4 py-8">
            <div className="container mx-auto px-6">
                <div className="bg-white p-6 rounded-xl relative -mt-28">
                    <div
                        dangerouslySetInnerHTML={createMarkup(cleanContent)}
                    ></div>

                    {details.video_type && (
                        <NewsletterVideo
                            video={details.video_url}
                            videoType={details.video_type}
                            youtubeId={details.yt_video_id}
                            title={details.title}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default ActiveNewsletters
