import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import techTalkService from "./techTalkService"

const initialState = {
    ongoingTalks: [],
    pastTalks: [],
    upcomingTalks: [],
    isLoading: false,
    isError: false,
    pageContent: {},
    talkDetails: {},
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    type: "all",
    nextPageURI: null,
    prevPageURI: null,
    allTalks: [],
}

export const fetchTalks = createAsyncThunk(
    "techTalks/fetchTalks",
    async (limit, thunkAPI) => {
        try {
            return await techTalkService.fetchTalks(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "techTalks/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await techTalkService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchTalkDetails = createAsyncThunk(
    "techTalks/fetchTalkDetails",
    async (slug, thunkAPI) => {
        try {
            return await techTalkService.fetchTalkDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAllTechTalks = createAsyncThunk(
    "techTalk/fetchAllTechTalks",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().techTalksPage
            return await techTalkService.fetchAllTechTalks(
                state.perPage,
                currentPage,
                state.type
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const techTalkSlice = createSlice({
    name: "techTalk",
    initialState,
    reducers: {
        setType: (state, action) => {
            state.type = action.payload
        },
        resetTalks: (state) => {
            state.allTalks = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTalks.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTalks.fulfilled, (state, action) => {
                state.isLoading = false
                state.upcomingTalks = action.payload.upcoming
                state.pastTalks = action.payload.past
                state.ongoingTalks = action.payload.ongoing
            })
            .addCase(fetchTalks.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
                state.ongoingTalks = []
                state.pastTalks = []
                state.upcomingTalks = []
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchTalkDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTalkDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.talkDetails = action.payload
            })
            .addCase(fetchTalkDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
                state.talkDetails = {}
            })
            .addCase(fetchAllTechTalks.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchAllTechTalks.fulfilled, (state, action) => {
                state.isLoading = false
                state.allTalks = [...state.allTalks, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchAllTechTalks.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.allTalks = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
    },
})

export const { setType, resetTalks } = techTalkSlice.actions
export default techTalkSlice.reducer
