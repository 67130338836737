import Slider from "react-slick"
import { NextArrowBlack, PrevArrowBlack } from "../../components/CustomArrows"
import DevTeamItem from "./DevTeamItem"

const DevelopmentTeam = ({ team }) => {
    const settings = {
        dots: false,
        infinite: team.length >= 3 ? true : false,
        arrows: true,
        className: "center",
        centerMode: team.length >= 3 ? true : false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrowBlack />,
        prevArrow: <PrevArrowBlack />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    arrows: true,
                },
            },
        ],
    }

    return (
        <section className="bg-gradient-to-r from-blue-300 to-cyan-200 px-4 md:px-10 pb-10 overflow-hidden">
            <div className="container mx-auto px-6 py-16 ef-dt-bg relative -mt-8">
                <div className="flex justify-start">
                    <div className="border-r-4 border-black pr-10">
                        <h1 className="text-black font-bold text-2xl md:text-4xl lg:text-6xl uppercase ">
                            Development
                        </h1>
                        <h2 className="uppercase font-semibold text-lg md:text-4xl">
                            Account Team
                        </h2>
                    </div>
                </div>
                <div className="mt-8 md:mt-16 -mx-16">
                    <Slider {...settings} className="development-team">
                        {team.map((item) => (
                            <DevTeamItem
                                key={`accounts-team-${item.id}`}
                                name={item.name}
                                designation={item.designation}
                                image={item.image_url}
                                info={item.description}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="eft-student-team-header mt-10">
                <h1 className="text-black font-bold text-2xl md:text-4xl lg:text-6xl uppercase ">
                    Student
                </h1>
                <h2 className="uppercase font-semibold text-lg md:text-4xl">
                    Team
                </h2>
                <div className="bg-gradient-to-r from-cyan-400 to-blue-400 h-2 w-[20%] mt-4"></div>
            </div>
        </section>
    )
}

export default DevelopmentTeam
