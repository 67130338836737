import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import alumniDatabaseService from "./alumniDatabaseService"

const initialState = {
    database: [],
    years: [],
    selectedYear: "",
    databaseLoading: false,
    databaseError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    alumniRecords: [],
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
    alumniDetails: {},
    recordsError: false,
    recordsLoading: false,
}

export const fetchAlumnis = createAsyncThunk(
    "alumniDatabase/fetchAlumnis",
    async (limit, thunkAPI) => {
        try {
            return await alumniDatabaseService.fetchAlumnis(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAlumniDetails = createAsyncThunk(
    "alumniDatabase/fetchAlumniDetails",
    async (slug, thunkAPI) => {
        try {
            return await alumniDatabaseService.fetchAlumniDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "alumniDatabase/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await alumniDatabaseService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAlumniRecords = createAsyncThunk(
    "alumniDatabase/fetchAlumniRecords",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().alumniDatabasePage
            return await alumniDatabaseService.fetchAlumniRecords(
                state.selectedYear,
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const alumniDatabaseSlice = createSlice({
    name: "alumniDatabse",
    initialState,
    reducers: {
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
        resetAlumniRecords: (state) => {
            state.alumniRecords = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAlumnis.pending, (state) => {
                state.databaseLoading = true
            })
            .addCase(fetchAlumnis.fulfilled, (state, action) => {
                state.databaseLoading = false
                state.database = action.payload.database
                state.years = action.payload.years
            })
            .addCase(fetchAlumnis.rejected, (state, action) => {
                state.databaseError = true
                state.database = []
                state.years = []
                state.databaseLoading = false
                state.databaseErrorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchAlumniRecords.pending, (state) => {
                state.recordsLoading = true
            })
            .addCase(fetchAlumniRecords.fulfilled, (state, action) => {
                state.recordsLoading = false
                state.alumniRecords = [
                    ...state.alumniRecords,
                    ...action.payload.data,
                ]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchAlumniRecords.rejected, (state, action) => {
                state.recordsLoading = false
                state.recordsError = true
                state.alumniRecords = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.recordsErrorMessage = action.payload
            })
            .addCase(fetchAlumniDetails.fulfilled, (state, action) => {
                state.alumniDetails = action.payload
            })
            .addCase(fetchAlumniDetails.rejected, (state) => {
                state.alumniDetails = {}
            })
    },
})

export const { setSelectedYear, resetAlumniRecords } =
    alumniDatabaseSlice.actions
export default alumniDatabaseSlice.reducer
