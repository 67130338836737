import React from "react"
import { staticBaseURI } from "../../../../config"

const NoPosts = () => {
    return (
        <div className="bg-white w-full px-4 py-14 rounded-lg shadow-md">
            <div className="flex justify-center">
                <img
                    src={`${staticBaseURI}/dists/images/no-posts.png`}
                    alt="No Posts"
                    className="w-24"
                />
            </div>
            <div className="text-center mt-4">
                <h1 className="text-2xl font-bold">No posts found...</h1>
            </div>
        </div>
    )
}

export default NoPosts
