import React from "react"
import { apiBaseURI } from "../../config"

export const ECMemberItem = ({ name, image, designation, year }) => {
    return (
        <div className="aa-ec-member-item mb-8">
            <div className="flex justify-center">
                <img
                    src={`${apiBaseURI}${image}`}
                    className="rounded-full w-1/2"
                    alt={name}
                />
            </div>
            <div className="text-center text-white mt-4">
                <h3 className="font-bold text-2xl lg:text-3xl">{name}</h3>
                <h4 className="font-semibold text-lg lg:text-xl">
                    {designation}
                </h4>
                <h4 className="font-semibold text-lg lg:text-xl">
                    Class of {year}
                </h4>
            </div>
        </div>
    )
}
