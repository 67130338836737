import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import faqService from "./faqService"

const initialState = {
    faqs: [],
    isLoading: false,
    isError: false,
    pageContent: {},
}

export const fetchPageContent = createAsyncThunk(
    "chairs/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await faqService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchFaq = createAsyncThunk(
    "chairs/fetchFaq",
    async (_, thunkAPI) => {
        try {
            return await faqService.fetchFaq()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.faqs = action.payload
            })
            .addCase(fetchFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.faqs = []
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
    },
})

export default faqSlice.reducer
