import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import policyService from "./policyService"

const initialState = {
    pageContent: {},
    isLoading: false,
    isError: false,
}

export const fetchPageContent = createAsyncThunk(
    "policy/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await policyService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const policySlice = createSlice({
    name: "policy",
    initialState,
    extraReducers: (builder) => {
        builder

            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
    },
})

export default policySlice.reducer
