import Slider from "react-slick"
import { FounderItem } from "./FounderItem"

const Founders = ({ founders }) => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div className="container mx-auto px-6 relative mt-20">
            <div className="ef-founders bg-gradient p-8 mb-[12rem] rounded-xl">
                <div className="founder-header">
                    <div className="font-bold uppercase text-4xl mb-8">
                        Founders
                        <div className="ef-founder-title-underline"></div>
                    </div>
                </div>
                <div className="-mb-[9rem] -mx-[4.3rem] md:mx-0">
                    <Slider {...settings}>
                        {founders.map((founder) => (
                            <FounderItem
                                key={`founder-${founder.id}`}
                                name={founder.name}
                                image={founder.image_url}
                                batch={founder.batch}
                                designation={founder.designation}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Founders
