import React from "react"
import { apiBaseURI } from "../../config"

const TeamItem = ({ image, name, designation }) => {
    return (
        <div className="mx-4 py-4">
            <div className="flex justify-center">
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={name}
                    className="rounded-full w-[80%]"
                />
            </div>
            <div className="text-white text-center mt-4">
                <h4 className="text-lg md:text-xl font-semibold">
                    {designation}
                </h4>
                <h2 className="text-2xl md:text-3xl font-bold uppercase">
                    {name}
                </h2>
            </div>
        </div>
    )
}

export default TeamItem
