import Slider from "react-slick"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"
import HelpItem from "./HelpItem"

const YourDonation = ({ points }) => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        className: "center",
        centerMode: true,
        slidesToShow: points.length >= 4 ? 4 : points.length,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: points.length >= 3 ? 3 : points.length,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: points.length >= 2 ? 2 : points.length,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: points.length >= 2 ? 2 : points.length,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <div className="container mx-auto px-2 md:px-6 mt-10">
            <div className="wd-your-donation-bg px-6 pt-12 pb-28 rounded-xl relative">
                <div className="">
                    <h1 className="text-3xl md:text-4xl font-bold">
                        Your Donation
                    </h1>
                    <h3 className="text-xl md:text-2xl">will help us for</h3>
                    <div className="wd-page-header-underline h-1 mt-4"></div>
                </div>
                <div className="mt-10 -mx-12 md:-mx-16 ">
                    <Slider {...settings} className="wd-your-donation-carousel">
                        {points.map((point) => (
                            <HelpItem text={point.title} key={point.id} />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default YourDonation
