import { useDispatch } from "react-redux"

import { apiBaseURI } from "../../config"
import { closeModal } from "../../features/modal/modalSlice"

const BoardMemberModal = ({ info }) => {
    const dispatch = useDispatch()

    return (
        <div className="border-2 border-gray-200 p-2">
            <div className="grid grid-cols-2 gap-4">
                <div className="self-center">
                    <div className="flex justify-center">
                        <img
                            className="rounded-full w-[70%] member-img"
                            src={`${apiBaseURI}${info.image_url}`}
                            alt={info.name}
                        />
                    </div>
                    <h1 className="mt-4 text-center font-bold text-base md:text-xl">
                        {info.name}
                    </h1>
                </div>
                <div className="member-info-modal self-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div>
                            <h3>Batch:</h3>
                            <div className="member-info-text">{info.year}</div>
                        </div>
                        <div>
                            <h3>Department:</h3>
                            <div className="member-info-text">
                                {info.department.name}
                            </div>
                        </div>
                        <div>
                            <h3>Designation:</h3>
                            <div className="member-info-text">
                                {info.designation}
                            </div>
                        </div>
                        <div>
                            <h3>Funded:</h3>
                            <div className="member-info-text">
                                {info.formatted_amount}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3 block md:hidden">
                <hr />
                <button
                    onClick={() => dispatch(closeModal())}
                    className="w-full text-center bg-blue-dark text-white mt-3 py-1 rounded"
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default BoardMemberModal
