import { Formik, Form } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

import Steps from "../Steps/Steps"
import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import "./profile-forms.css"
import RadioInput from "../../../../components/Inputs/RadioInput"
import {
    fetchCities,
    fetchCountries,
    fetchCourses,
    fetchDepartments,
    fetchStates,
} from "../../../../features/config/configSlice"
import validateBasicDetails from "./validation/validateBasicDetails"
import { apiBaseURI, scrollTop, staticBaseURI } from "../../../../config"
import {
    fetchBasicDetails,
    setFormSubmitted,
    submitBasicDetails,
} from "../../../../features/auth/authSlice"

const BasicDetails = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const { courses, departments, countries, states, cities } = useSelector(
        (state) => state.config
    )

    const { user, login, isLoading, formSubmitted, basicDetails } = useSelector(
        (state) => state.auth
    )

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    const coursesList = courses.map((course) => {
        return { value: course.id, label: course.title }
    })

    const departmentsList = departments.map((department) => {
        return { value: department.id, label: department.name }
    })

    const countriesList = countries.map((country) => {
        return { value: country.id, label: country.name }
    })

    const statesList = states.map((state) => {
        return { value: state.id, label: state.name }
    })

    const citiesList = cities.map((city) => {
        return { value: city.id, label: city.name }
    })

    const isInitialMount = useRef(true)

    useEffect(() => {
        scrollTop()

        if (isInitialMount.current) {
            dispatch(fetchDepartments())
            dispatch(fetchCountries())
            dispatch(fetchBasicDetails())
            isInitialMount.current = false
        } else {
            if (basicDetails.country_id) {
                dispatch(fetchStates(basicDetails.country_id))
            }

            if (basicDetails.department_id) {
                dispatch(fetchCourses(basicDetails.department_id))
            }

            if (basicDetails.state_id) {
                dispatch(fetchCities(basicDetails.state_id))
            }
        }

        return () => {
            // when component unmounts, set formSubmitted to false
            dispatch(setFormSubmitted(false))
        }
    }, [
        dispatch,
        basicDetails.country_id,
        basicDetails.department_id,
        basicDetails.state_id,
    ])

    const initialValues = {
        name: user.name,
        email: user.email,
        mobile: user.mobile,
        gender: basicDetails.gender ? basicDetails.gender : "",
        dob: basicDetails.dob ? new Date(basicDetails.dob) : "",
        course: basicDetails.course_id ? parseInt(basicDetails.course_id) : "",
        department: basicDetails.department_id
            ? parseInt(basicDetails.department_id)
            : "",
        batch: basicDetails.batch
            ? new Date(`${basicDetails.batch}-01-01`)
            : "",
        registration_id: basicDetails.registration_id
            ? basicDetails.registration_id
            : "",
        adr_line_1: basicDetails.adr_line_1 ? basicDetails.adr_line_1 : "",
        adr_line_2: basicDetails.adr_line_2 ? basicDetails.adr_line_2 : "",
        country: basicDetails.country_id
            ? parseInt(basicDetails.country_id)
            : "",
        state: basicDetails.state_id ? parseInt(basicDetails.state_id) : "",
        city: basicDetails.city_id ? parseInt(basicDetails.city_id) : "",
        pin: basicDetails.pin ? basicDetails.pin : "",
        facebook: basicDetails.facebook_url ? basicDetails.facebook_url : "",
        twitter: basicDetails.twitter_url ? basicDetails.twitter_url : "",
        linkedin: basicDetails.linkedin_url ? basicDetails.linkedin_url : "",
        photo: "",
        photo_url: basicDetails.photo_url
            ? `${apiBaseURI}${basicDetails.photo_url}`
            : `${staticBaseURI}/dists/images/default-user.jpg`,
    }

    const handleSubmit = (values, actions) => {
        dispatch(submitBasicDetails({ values, actions }))
    }

    function handleSelectInput(ev, props, field) {
        props.setFieldValue(field, ev.value)
        if (field === "department") dispatch(fetchCourses(ev.value))
        else if (field === "country") dispatch(fetchStates(ev.value))
        else if (field === "state") dispatch(fetchCities(ev.value))
    }

    function handleFileSelect(evt, props, fieldName, target) {
        let file = evt.target.files[0]

        props.setFieldValue(fieldName, file)

        const image = document.getElementById(target)

        image.setAttribute("src", URL.createObjectURL(file))
    }

    if (formSubmitted) {
        //window.location.href = `${staticBaseURI}/profile/education-details`
        navigate(`/profile/education-details`)
    }

    return (
        <>
            <div className="grid grid-cols-5">
                <div className="col-span-5 md:col-span-2">
                    <Steps step={1} />
                </div>
                <motion.div
                    initial={{ opacity: 0, x: 300 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="col-span-5 md:col-span-3 py-16 px-8"
                >
                    <h1 className="font-bold text-4xl">Basic Details</h1>
                    <h3 className="text-lg text-gray-700">
                        Please enter your basic information
                    </h3>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validateBasicDetails}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                handleSubmit(values, actions)
                            }}
                        >
                            {(formikProps: FormikProps<any>) => (
                                <Form className="pt-8">
                                    <TextInput
                                        name="name"
                                        type="text"
                                        placeholder="NAME"
                                        color="dark"
                                        readOnly={true}
                                    />
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <TextInput
                                            name="mobile"
                                            type="number"
                                            placeholder="MOBILE"
                                            color="dark"
                                            readOnly={true}
                                        />
                                        <TextInput
                                            name="email"
                                            type="email"
                                            placeholder="EMAIL"
                                            color="dark"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div>
                                            <label>Gender: </label>
                                            <div className="grid grid-cols-2 gap-4 mt-2">
                                                <RadioInput
                                                    label="Male"
                                                    value="M"
                                                    type="radio"
                                                    name="gender"
                                                />
                                                <RadioInput
                                                    label="Female"
                                                    value="F"
                                                    type="radio"
                                                    name="gender"
                                                />
                                            </div>
                                            {formikProps.touched.gender &&
                                            formikProps.errors.gender ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.gender}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="w-full">
                                            <label>Date Of Birth: </label>
                                            <DatePicker
                                                selected={
                                                    formikProps.values.dob
                                                }
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "dob",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                className="text-input"
                                                dateFormat="dd-MM-yyyy"
                                                placeholderText="Click to select date"
                                            />
                                            {formikProps.touched.dob &&
                                            formikProps.errors.dob ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.dob}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div>
                                            <label>Department: </label>
                                            <Select
                                                value={departmentsList.find(
                                                    ({ value }) =>
                                                        value ===
                                                        formikProps.values
                                                            .department
                                                )}
                                                options={departmentsList}
                                                className="w-full mt-2 border-none"
                                                name="department"
                                                onChange={(ev) =>
                                                    handleSelectInput(
                                                        ev,
                                                        formikProps,
                                                        "department"
                                                    )
                                                }
                                            />

                                            {formikProps.touched.department &&
                                            formikProps.errors.department ? (
                                                <div className="input-error ">
                                                    {
                                                        formikProps.errors
                                                            .department
                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            <label>Course: </label>
                                            <Select
                                                options={coursesList}
                                                className="w-full mt-2 border-none"
                                                onChange={(ev) =>
                                                    formikProps.setFieldValue(
                                                        "course",
                                                        ev.value
                                                    )
                                                }
                                                value={coursesList.find(
                                                    ({ value }) =>
                                                        value ===
                                                        formikProps.values
                                                            .course
                                                )}
                                            />
                                            {formikProps.touched.course &&
                                            formikProps.errors.course ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.course}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div className="w-full">
                                            <label>Batch: </label>
                                            <DatePicker
                                                selected={
                                                    formikProps.values.batch
                                                }
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "batch",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="text-input"
                                                placeholderText="Click to select year"
                                            />
                                            {formikProps.touched.batch &&
                                            formikProps.errors.batch ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.batch}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="pt-6">
                                            <TextInput
                                                name="registration_id"
                                                type="text"
                                                placeholder="REGISTRATION ID"
                                                color="dark"
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div className="w-full self-center">
                                            <label>Photo: </label>
                                            <input
                                                className={`text-input text-black caret-black`}
                                                name="photo"
                                                type="file"
                                                onChange={(event) =>
                                                    handleFileSelect(
                                                        event,
                                                        formikProps,
                                                        "photo",
                                                        "selected-photo"
                                                    )
                                                }
                                            />
                                            <label className=" text-blue-500">
                                                *Image resolution 300x300 px
                                            </label>
                                            <br />
                                            {formikProps.touched.photo &&
                                            formikProps.errors.photo ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.photo}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="">
                                            <img
                                                alt="profile"
                                                src={
                                                    formikProps.values.photo_url
                                                }
                                                id="selected-photo"
                                                className="border-2 border-gray-200"
                                                style={{
                                                    width: "150px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h3 className="font-bold text-xl">
                                            Address:
                                        </h3>
                                        <div>
                                            <TextInput
                                                name="adr_line_1"
                                                type="text"
                                                placeholder="ADDRESS LINE 1"
                                                color="dark"
                                            />
                                            <TextInput
                                                name="adr_line_2"
                                                type="text"
                                                placeholder="ADDRESS LINE 2"
                                                color="dark"
                                            />
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                                <div>
                                                    <label>Country: </label>
                                                    <Select
                                                        options={countriesList}
                                                        className="w-full mt-2 border-none"
                                                        name="country"
                                                        onChange={(ev) =>
                                                            handleSelectInput(
                                                                ev,
                                                                formikProps,
                                                                "country"
                                                            )
                                                        }
                                                        value={countriesList.find(
                                                            ({ value }) =>
                                                                value ===
                                                                formikProps
                                                                    .values
                                                                    .country
                                                        )}
                                                    />
                                                    {formikProps.touched
                                                        .country &&
                                                    formikProps.errors
                                                        .country ? (
                                                        <div className="input-error ">
                                                            {
                                                                formikProps
                                                                    .errors
                                                                    .country
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <label>State: </label>
                                                    <Select
                                                        options={statesList}
                                                        className="w-full mt-2 border-none"
                                                        name="state"
                                                        onChange={(ev) =>
                                                            handleSelectInput(
                                                                ev,
                                                                formikProps,
                                                                "state"
                                                            )
                                                        }
                                                        value={statesList.find(
                                                            ({ value }) =>
                                                                value ===
                                                                formikProps
                                                                    .values
                                                                    .state
                                                        )}
                                                    />
                                                    {formikProps.touched
                                                        .state &&
                                                    formikProps.errors.state ? (
                                                        <div className="input-error ">
                                                            {
                                                                formikProps
                                                                    .errors
                                                                    .state
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <label>City: </label>
                                                    <Select
                                                        options={citiesList}
                                                        className="w-full mt-2 border-none"
                                                        name="city"
                                                        onChange={(ev) =>
                                                            handleSelectInput(
                                                                ev,
                                                                formikProps,
                                                                "city"
                                                            )
                                                        }
                                                        value={citiesList.find(
                                                            ({ value }) =>
                                                                value ===
                                                                formikProps
                                                                    .values.city
                                                        )}
                                                    />
                                                    {formikProps.touched.city &&
                                                    formikProps.errors.city ? (
                                                        <div className="input-error ">
                                                            {
                                                                formikProps
                                                                    .errors.city
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <div className="pt-6">
                                                    <TextInput
                                                        name="pin"
                                                        type="number"
                                                        placeholder="PINCODE"
                                                        color="dark"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h3 className="font-bold text-xl">
                                            Social Media Profiles:
                                        </h3>
                                        <div>
                                            <TextInput
                                                name="facebook"
                                                type="url"
                                                placeholder="FACEBOOK"
                                                color="dark"
                                            />
                                            <TextInput
                                                name="twitter"
                                                type="url"
                                                placeholder="TWITTER"
                                                color="dark"
                                            />
                                            <TextInput
                                                name="linkedin"
                                                type="url"
                                                placeholder="LINKEDIN"
                                                color="dark"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-8">
                                        <button
                                            type="submit"
                                            className="text-white px-6 py-2 submit-btn"
                                            disabled={isLoading ? true : false}
                                        >
                                            {isLoading ? (
                                                <div className="inline-flex">
                                                    <Loader
                                                        size="6"
                                                        classes="mr-2 w-6 h-6 fill-blue-200"
                                                    />
                                                    <div className="self-center">
                                                        Please wait...
                                                    </div>
                                                </div>
                                            ) : (
                                                `Save & Continue`
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default BasicDetails
