import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch vc members
const fetchVcMembers = async (limit) => {
    const url = `/vc-members/${limit}`
    const response = await api.get(url)
    return response.data.data
}

const alumniAssociationService = {
    fetchPageContent,
    fetchVcMembers,
}

export default alumniAssociationService
