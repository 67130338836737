import { toast } from "react-toastify"

import { api } from "../../api"
import { staticBaseURI } from "../../config"
import { getCookie } from "../../config/cookie"

/* Get Forum Cateogirs */
const fetchForumCategories = async () => {
    const url = `/forum-categories`
    const response = await api.get(url)
    return response.data
}

/* Fetch Forum Posts */
const fetchForumPosts = async (limit, category, page) => {
    const url = `/auth/forum-posts/${limit}/${category}?page=${page}`
    const token = getCookie("userToken")
    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response.data
}

/* Fetch Forum Post Details */
const fetchForumPostDetails = async (slug) => {
    const url = `/auth/forum-post/${slug}`
    const token = getCookie("userToken")
    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    return response.data
}

/* Create New Forum Post */
const createForumPost = async (values, actions) => {
    const url = `/auth/create-forum-post`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("title", values.title)
    formdata.append("category", values.category)
    formdata.append("content", values.content)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

/* Create New Post Comment */
const createPostComment = async (values, actions) => {
    const url = `/auth/create-post-comment`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("comment", values.comment)
    formdata.append("post_id", values.post_id)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    } else if (response.status === 404 || response.status === 500) {
        toast.error("Oops! Something went wrong", {
            position: toast.POSITION.BOTTOM_CENTER,
        })
    }

    return response.data
}

const forumService = {
    fetchForumCategories,
    createForumPost,
    fetchForumPosts,
    fetchForumPostDetails,
    createPostComment,
}

export default forumService
