import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni news
const fetchNews = async (limit) => {
    const url = `/news/${limit}`
    const response = await api.get(url)
    return response.data.data
}

//fetch alumni team
const fetchAlumniTeam = async (limit) => {
    const url = `/alumni-affairs/team/${limit}`
    const response = await api.get(url)
    return response.data
}

const alumniAffairsService = {
    fetchPageContent,
    fetchNews,
    fetchAlumniTeam,
}

export default alumniAffairsService
