import * as Yup from "yup"

const validateWorkDetails = Yup.object({
    job_title: Yup.string().required("Please enter your job title"),
    company: Yup.string().required("Please enter your company name"),
    from: Yup.string().required("Please select your start year"),
    to: Yup.string().when("present", {
        is: (present) => !present,
        then: Yup.string().required("Please select your end year"),
        otherwise: Yup.string().notRequired(),
    }),
    present: Yup.boolean(),
})

export default validateWorkDetails
