import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import chairsService from "./chairsService"

const initialState = {
    chairs: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    chairDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchChairs = createAsyncThunk(
    "chairs/fetchChairs",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().chairsPage
            return await chairsService.fetchChairs(state.perPage, currentPage)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "chairs/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await chairsService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchChairDetails = createAsyncThunk(
    "chairs/fetchChairDetails",
    async (slug, thunkAPI) => {
        try {
            return await chairsService.fetchChairDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const chairsSlice = createSlice({
    name: "chairs",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChairs.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchChairs.fulfilled, (state, action) => {
                state.isLoading = false
                state.chairs = [...state.chairs, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchChairs.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.chairs = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchChairDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchChairDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.chairDetails = action.payload
            })
            .addCase(fetchChairDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export const { setCurrentPage } = chairsSlice.actions
export default chairsSlice.reducer
