import { staticBaseURI } from "../../config"

const index = () => {
    return (
        <div className="flex justify-center">
            <img src={`${staticBaseURI}/dists/images/oval.svg`} alt="loading" />
        </div>
    )
}

export default index
