import * as DOMPurify from "dompurify"

const WireTransfer = ({ content }) => {
    let cleanContent = DOMPurify.sanitize(content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="px-4 pt-16 pb-28 relative">
            <div className="container mx-auto px-6">
                <div className="border-b-4 border-b-black pb-2">
                    <h1 className="text-2xl md:text-4xl lg:text-6xl font-semibold uppercase">
                        Wire Transfers
                    </h1>
                </div>
                <div
                    className="mt-4 text-lg"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </div>
        </section>
    )
}

export default WireTransfer
