import { FiArrowRight } from "react-icons/fi"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const About = () => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div className="container mx-auto px-6 relative" ref={ref}>
            <div className="ef-about-head flex justify-between -mx-6 md:mx-0">
                <motion.div
                    initial={framerAnimationInitial}
                    animate={controls}
                    transition={{
                        duration: 0.3,
                        delay: 0.2,
                    }}
                    className="font-bold md:text-5xl lg:text-6xl"
                >
                    Why RGU needs money?
                    <div className="gradient-underline h-1 mt-4 w-[80%]">
                        &nbsp;
                    </div>
                </motion.div>
                <motion.div
                    initial={framerAnimationInitial}
                    animate={controls}
                    transition={{
                        duration: 0.3,
                        delay: 0.3,
                    }}
                    className="ef-stats bg-black p-4 md:p-6 -mt-32 md:-mt-48 xl:-mt-56 rounded-2xl"
                >
                    <div className="text-gradient">
                        <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-center">
                            Become a
                        </h1>
                        <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold flex justify-center">
                            Donor
                            <span className="text-white">
                                <FiArrowRight />
                            </span>
                        </h1>
                        <h3 className="text-base md:text-2xl uppercase mt-4 text-center">
                            <span>Pledged By</span> | <span>21 Alumni</span>
                        </h3>
                    </div>
                    <div className="flex justify-center mt-6">
                        <div className="self-center mr-2">
                            <img
                                src="./dists/images/give-money.png"
                                className="w-8 md:w-12 lg:w-16"
                                alt="give money"
                            />
                        </div>
                        <div className="uppercase text-white self-center text-xs md:text-xl lg:text-2xl">
                            Every Contribution Counts
                        </div>
                    </div>
                </motion.div>
            </div>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.4,
                }}
                className="ef-about mt-8 text-lg"
            >
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc quis pretium enim. Donec mattis eros et justo lobortis,
                    at facilisis erat lobortis. Nulla quis dui vitae urna
                    pellentesque tristique. Aliquam lacinia mauris a magna
                    posuere finibus. Nam id risus non nisl tristique porttitor
                    eu vel lacus. In vel lectus et leo tincidunt sodales. Nam
                    non felis fermentum, gravida dolor vitae, finibus augue.
                    Orci varius natoque penatibus et magnis dis parturient
                    montes, nascetur ridiculus mus. Aliquam ut euismod orci,
                    interdum pretium tellus. Nunc dapibus vitae sapien sed
                    vehicula. Integer scelerisque sollicitudin lacinia. In hac
                    habitasse platea dictumst. In accumsan vulputate varius.
                    Donec iaculis laoreet orci, at volutpat turpis. Vivamus nisi
                    tortor, cursus vel efficitur id, rutrum id nunc.
                </p>
                <p>
                    Sed id ligula pulvinar dolor mattis interdum. In ut commodo
                    nunc. Maecenas porttitor augue at quam consectetur pulvinar.
                    Donec bibendum, purus posuere blandit feugiat, risus erat
                    finibus eros, ac porttitor dui sapien at urna. Pellentesque
                    id augue dolor. Sed consequat massa et nisl semper, non
                    aliquam felis semper. Vestibulum feugiat ligula ac urna
                    sagittis hendrerit.
                </p>
            </motion.div>
        </div>
    )
}

export default About
