import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch days
const fetchDays = async (limit, page) => {
    const url = `/alumni-days/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni day details
const fetchAlumniDayDetails = async (slug) => {
    const url = `/alumni-day-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const alumniDayService = {
    fetchDays,
    fetchPageContent,
    fetchAlumniDayDetails,
}

export default alumniDayService
