import { apiBaseURI, staticBaseURI } from "../../config"

const NextAlumni = ({ next, prev }) => {
    return (
        <section className="bg-blue-newsletter px-4 pt-8 pb-16 relative overflow-hidden">
            <div className="bg-text uppercase top-[30%] -left-20">Awards</div>
            <div className="container mx-auto px-6">
                <div className="flex justify-end">
                    <div className="dap-social-btns flex self-center">
                        <a href="#0">
                            <img
                                src={`${staticBaseURI}/dists/images/s-fb.png`}
                                alt="facebook"
                            />
                        </a>
                        <a href="#0">
                            <img
                                src={`${staticBaseURI}/dists/images/s-tw.png`}
                                alt="twiiter"
                            />
                        </a>
                        <a href="#0">
                            <img
                                src={`${staticBaseURI}/dists/images/s-wa.png`}
                                alt="whatsapp"
                            />
                        </a>
                    </div>
                    <div className="uppercase dap-share-text font-semibold text-2xl border-l-2 border-white ml-8">
                        Share
                    </div>
                </div>

                {next && prev === null && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${next.image_url}`}
                                    alt={next.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-single/${next.id}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-3xl decoration-cyan-300"
                                >
                                    Next
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-3xl font-semibold uppercase">
                                        {next.name}
                                    </h3>
                                    <h5 className="text-2xl">
                                        Degree Year {next.batch}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {next === null && prev && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${prev.image_url}`}
                                    alt={prev.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-single/${prev.id}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-3xl decoration-cyan-300"
                                >
                                    Previous
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-3xl font-semibold uppercase">
                                        {prev.name}
                                    </h3>
                                    <h5 className="text-2xl">
                                        Degree Year {prev.batch}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {next && prev && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${next.image_url}`}
                                    alt={next.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-single/${next.id}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-3xl decoration-cyan-300"
                                >
                                    Next
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-3xl font-semibold uppercase">
                                        {next.name}
                                    </h3>
                                    <h5 className="text-2xl">
                                        Degree Year {next.batch}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default NextAlumni
