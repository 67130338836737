import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import About from "./About"
import MessageFromPresident from "./MessageFromPresident"
import "./alumni-association.css"
import Board from "./Board"
import ECMembers from "./ECMembers"
import { fetchPageContent } from "../../features/alumni-association/alumniAssociationSlice"
import { apiBaseURI } from "../../config"

function AlumniAssociation() {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.alumniAssociationPage)

    useEffect(() => {
        dispatch(fetchPageContent("alumni-association"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About about={pageContent.content} />
            <section className="aa-bg-president-message py-1">
                <MessageFromPresident about={pageContent.content_2} />
                <Board />
            </section>
            <ECMembers />
        </>
    )
}

export default AlumniAssociation
