import * as Yup from "yup"

const validateEducationDetails = Yup.object({
    college: Yup.string().required("Please enter your college name"),
    course: Yup.string().required("Please enter your course name"),
    from: Yup.string().required("Please select your start year"),
    to: Yup.string().required("Please select your end year"),
})

export default validateEducationDetails
