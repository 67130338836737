import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { MdDeleteForever } from "react-icons/md"

import TextInput from "../../../../components/Inputs/TextInput"
import Textarea from "../../../../components/Inputs/Textarea"
import Loader from "../../../../components/Loader/Loader"
import { closeModal } from "../../../../features/modal/modalSlice"
import {
    deleteProjectRow,
    editProjectDetails,
    submitProjectDetails,
} from "../../../../features/auth/authSlice"
import validateProjectSchema from "./validateProject"

const ProjectForm = ({ project: selectedRow }) => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state.auth)

    const handleSubmit = (values, actions) => {
        if (selectedRow) {
            dispatch(editProjectDetails({ values, actions })).then(() =>
                dispatch(closeModal())
            )
        } else {
            dispatch(submitProjectDetails({ values, actions })).then(() =>
                dispatch(closeModal())
            )
        }
    }

    const handleDeleteBtn = (id) => {
        if (window.confirm("Are you sure you want to delete this details?")) {
            dispatch(deleteProjectRow(id))
        }
    }

    const initialValues = {
        title: "",
        description: "",
    }

    let editRowValues = {
        id: "",
        title: "",
        description: "",
    }

    if (selectedRow) {
        editRowValues = {
            id: selectedRow.id,
            title: selectedRow.title,
            description: selectedRow.description,
        }
    }

    return (
        <>
            <Formik
                initialValues={selectedRow ? editRowValues : initialValues}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions)
                }}
                validationSchema={validateProjectSchema}
                enableReinitialize={true}
            >
                {(props) => (
                    <Form>
                        <TextInput
                            name="title"
                            type="text"
                            placeholder="PROJECT TITLE"
                            color="dark"
                        />
                        <Textarea
                            name="description"
                            placeholder="DESCRIPTION"
                            color="dark"
                            value=""
                        />
                        <div className="flex justify-end">
                            {selectedRow && selectedRow.id && (
                                <button
                                    className="text-2xl mr-2 text-red-500 self-center"
                                    type="button"
                                    title="Delete"
                                    onClick={() =>
                                        handleDeleteBtn(selectedRow.id)
                                    }
                                >
                                    <MdDeleteForever />
                                </button>
                            )}

                            <button
                                type="submit"
                                className="text-white px-6 py-2 submit-btn"
                                disabled={isLoading ? true : false}
                            >
                                {isLoading ? (
                                    <div className="inline-flex">
                                        <Loader
                                            size="6"
                                            classes="mr-2 w-6 h-6 fill-blue-200"
                                        />
                                        <div className="self-center">
                                            Please wait...
                                        </div>
                                    </div>
                                ) : selectedRow ? (
                                    `Update Details`
                                ) : (
                                    `Add Details`
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ProjectForm
