import React from "react"
import Slider from "react-slick"
import { staticBaseURI } from "../../config"

import AlumniNewsItem from "../Homepage/AlumniNews/AlumniNewsItem"

const NewsDetailsMore = ({ moreNews, nextNews }) => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="nd-split-bg-color px-4 pt-16 pb-20">
            <div className="container mx-auto px-4 md:px-6">
                <div className="flex justify-between">
                    <h1 className="underline text-white decoration-cyan-300 underline-offset-8 font-bold uppercase text-2xl md:text-3xl lg:text-4xl">
                        More News
                    </h1>
                    {nextNews && (
                        <a
                            href={`${staticBaseURI}/news-details/${nextNews.slug}`}
                            className="uppercase text-white font-bold text-2xl md:text-3xl lg:text-4xl"
                        >
                            Next &gt;&gt;
                        </a>
                    )}
                </div>
                <div className="bg-white px-0 md:px-6 py-6 mt-8 rounded-2xl">
                    <div className="flex justify-end">
                        <div className="flex pr-2">
                            <span className="blue-dot rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
                            <a
                                href={`${staticBaseURI}/news`}
                                className="font-bold text-base md:text-xl"
                            >
                                View All
                            </a>
                        </div>
                    </div>
                    <div className="alumni-news-wrapper mt-4 relative -mb-14 -mx-8 md:-mx-0">
                        <Slider {...settings}>
                            {moreNews.map((item) => (
                                <AlumniNewsItem
                                    key={item.slug}
                                    image={item.image_url}
                                    title={item.title}
                                    slug={item.slug}
                                />
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NewsDetailsMore
