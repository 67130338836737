import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
} from "../../config"

const TeamItem = ({ image, name, index }) => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div ref={ref}>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3 + parseFloat(`0.${index}`),
                }}
                className="mx-4 py-4"
            >
                <div className="flex justify-center">
                    <img
                        src={`${apiBaseURI}${image}`}
                        alt={name}
                        className="rounded-full w-[80%]"
                    />
                </div>
                <div className="text-white text-center mt-4">
                    <h2 className="text-2xl md:text-3xl font-bold">{name}</h2>
                </div>
            </motion.div>
        </div>
    )
}

export default TeamItem
