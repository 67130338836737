import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import homepageService from "./homepageService"

const initialState = {
    news: [],
    distinguishedAlumns: [],
    ongoingEvents: [],
    pastEvents: [],
    slides: [],
    upcomingEvents: [],
    memories: [],
    loadingEvents: false,
    errorEvents: false,
    loadingNews: false,
    errorNews: false,
    loadingDistinguishedAlumns: false,
    errorDistinguishedAlumns: false,
    pageContent: {},
}

export const fetchNews = createAsyncThunk(
    "homepage/fetchNews",
    async (limit, thunkAPI) => {
        try {
            return await homepageService.fetchNews(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchDistinguishedAlumns = createAsyncThunk(
    "homepage/fetchDistinguishedAlumns",
    async (limit, thunkAPI) => {
        try {
            return await homepageService.fetchDistinguishedAlumns(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchEvents = createAsyncThunk(
    "homepage/fetchEvents",
    async (limit, thunkAPI) => {
        try {
            return await homepageService.fetchEvents(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchSlides = createAsyncThunk(
    "homepage/fetchSlides",
    async (_, thunkAPI) => {
        try {
            return await homepageService.fetchSlides()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchMemories = createAsyncThunk(
    "homepage/fetchMemories",
    async (_, thunkAPI) => {
        try {
            return await homepageService.fetchMemories()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "homepage/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await homepageService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const homepageSlice = createSlice({
    name: "homepage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNews.pending, (state) => {
                state.loadingNews = true
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.loadingNews = false
                state.news = action.payload
            })
            .addCase(fetchNews.rejected, (state, action) => {
                state.loadingNews = false
                state.errorNews = true
                state.messageNews = action.payload
                state.news = []
            })
            .addCase(fetchDistinguishedAlumns.pending, (state) => {
                state.loadingDistinguishedAlumns = true
            })
            .addCase(fetchDistinguishedAlumns.fulfilled, (state, action) => {
                state.distinguishedAlumns = action.payload
                state.loadingDistinguishedAlumns = false
            })
            .addCase(fetchDistinguishedAlumns.rejected, (state, action) => {
                state.errorDistinguishedAlumns = true
                state.loadingDistinguishedAlumns = false
                state.distinguishedAlumns = []
                state.messageDistinguishedAlumns = action.payload
            })
            .addCase(fetchEvents.pending, (state) => {
                state.loadingEvents = true
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loadingEvents = false
                state.upcomingEvents = action.payload.upcoming
                state.pastEvents = action.payload.past
                state.ongoingEvents = action.payload.ongoing
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.errorEvents = true
                state.messageEvents = action.payload
                state.ongoingEvents = []
                state.pastEvents = []
                state.upcomingEvents = []
            })
            .addCase(fetchSlides.fulfilled, (state, action) => {
                state.slides = action.payload
            })
            .addCase(fetchSlides.rejected, (state, action) => {
                state.slides = []
            })
            .addCase(fetchMemories.fulfilled, (state, action) => {
                state.memories = action.payload
            })
            .addCase(fetchMemories.rejected, (state) => {
                state.memories = []
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
    },
})

export default homepageSlice.reducer
