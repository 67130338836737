import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import chapterService from "./chapterService"

const initialState = {
    chapters: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    chapterDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchChapters = createAsyncThunk(
    "chapters/fetchChapters",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().chaptersPage
            return await chapterService.fetchChapters(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "chapters/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await chapterService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchChapterDetails = createAsyncThunk(
    "chapters/fetchChapterDetails",
    async (slug, thunkAPI) => {
        try {
            return await chapterService.fetchChapterDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const chapterSlice = createSlice({
    name: "chapters",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChapters.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchChapters.fulfilled, (state, action) => {
                state.isLoading = false
                state.chapters = [...state.chapters, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchChapters.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.chapters = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchChapterDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchChapterDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.chapterDetails = action.payload
            })
            .addCase(fetchChapterDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export default chapterSlice.reducer
