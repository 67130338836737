import { staticBaseURI } from "."
import { api } from "../api"
import { getCookie } from "./cookie"

export const openRazorpayUi = (values) => {
    var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: values.amount,
        currency: "INR",
        name: "Rajiv Gandhi University",
        description: "Alumni Donation",
        image: "https://admissions.rgu.ac.in/images/logo_new.png",
        order_id: values.order_id,
        handler: async (values) => {
            const url = `/auth/payment-success`
            var formdata = new FormData()
            const token = getCookie("userToken")

            formdata.append("payment_id", values.razorpay_payment_id)
            formdata.append("order_id", values.razorpay_order_id)
            formdata.append("razorpay_signature", values.razorpay_signature)

            await api
                .post(url, formdata, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    window.location.href = `${staticBaseURI}/donation/payment-successfull`
                })
                .catch((error) => {
                    //window.location.href = "/donation/payment-failed"
                    alert(error.response.data.message)
                })
        },
        prefill: {
            name: values.name,
            email: values.email,
            contact: values.mobile,
        },
        notes: {
            Name: values.name,
        },
        theme: {
            color: "#F37254",
        },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on("payment.failed", function (response) {
        alert(response.error.description)
    })

    rzp1.open()
}
