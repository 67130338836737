import React, { useEffect } from "react"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux"

import TeamItem from "./TeamItem"
import { fetchAlumniTeam } from "../../features/alumni-affairs/alumniAffairsSlice"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"
import PhotoCardRound from "../../components/Skeletons/PhotoCardRound"

const MeetTheTeam = () => {
    const dispatch = useDispatch()

    const { team } = useSelector((state) => state.alumniAffairsPage)

    useEffect(() => {
        dispatch(fetchAlumniTeam(8))
    }, [dispatch])

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div className="container mx-auto px-6 mt-20">
            <div className="aaf-team-header">
                <h2 className="uppercase font-bold text-4xl lg:text-6xl underline decoration-blue-400 underline-offset-8 text-white">
                    Meet The Team
                </h2>
            </div>
            <div className="aaf-tem-bg-gradient p-4  mt-8 rounded-3xl">
                <div className="md:mx-0 relative -mx-1">
                    <Slider {...settings} className="aaf-team-carousel">
                        {team.length < 1
                            ? [...Array(4)].map((i) => (
                                  <PhotoCardRound key={i} />
                              ))
                            : team.map((item, index) => (
                                  <TeamItem
                                      key={`alumni-team-${item.id}`}
                                      name={item.name}
                                      index={index}
                                      image={item.image_url}
                                  />
                              ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default MeetTheTeam
