import { useField } from "formik"
import "./custom-inputs.css"

const CheckBox = ({ children, color, ...props }) => {
    const [field, meta] = useField({ ...props, type: "checkbox" })
    return (
        <div className="mb-4">
            <label
                className={`checkbox-input ${
                    color === "black" ? "text-black" : "text-white"
                }`}
            >
                <input
                    type="checkbox"
                    className={`mr-2`}
                    {...field}
                    {...props}
                />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="input-error">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default CheckBox
