import { GoCommentDiscussion } from "react-icons/go"
import * as DOMPurify from "dompurify"

import { apiBaseURI, formatDate, staticBaseURI } from "../../../../config"

const PostItem = ({ title, user, comments, time, description, slug }) => {
    const postTime = formatDate(new Date(time))
    //const summary = summarizeText(description, 200)

    let cleanContent = DOMPurify.sanitize(description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <div className="bg-white p-4 rounded-lg shadow-md w-full mb-4">
            <div className="flex justify-between">
                <div className="flex-1">
                    <a
                        href={`${staticBaseURI}/forum/post/${slug}`}
                        className="font-bold text-md md:text-lg block"
                    >
                        {title}
                    </a>
                </div>
                <div className="flex-0 self-center">{`${postTime.day} ${postTime.monthName}, ${postTime.year}`}</div>
            </div>
            <hr className="my-2" />
            <p
                className="text-gray-500 text-sm"
                dangerouslySetInnerHTML={createMarkup(cleanContent)}
            ></p>
            <div className="flex justify-between mt-2">
                <div className="post-user">
                    <div className="flex justify-center items-center">
                        <img
                            src={`${apiBaseURI}${user?.basic_details?.photo_url}`}
                            alt={user?.name}
                            className="rounded-full w-10 h-10"
                        />
                        <div className="user-info ml-2">
                            <h4 className="font-medium text-base">
                                {user?.name}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="flex self-center" title="Comments">
                    <GoCommentDiscussion className="text-xl mt-[3px] mr-2" />
                    <span className="text-md">{comments}</span>
                </div>
            </div>
        </div>
    )
}

export default PostItem
