import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import forumService from "./forumService"

const initialState = {
    categories: [],
    isLoading: false,
    postsLoading: false,
    formSubmitted: false,
    isError: false,
    posts: [],
    similarPosts: [],
    currentPage: 1,
    lastPage: 1,
    perPage: 20,
    postDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    selectedCategory: "all",
    myPosts: [],
}

export const fetchForumCategories = createAsyncThunk(
    "forum/fetchForumCategories",
    async (_, thunkAPI) => {
        try {
            return await forumService.fetchForumCategories()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchForumPosts = createAsyncThunk(
    "forum/fetchForumPosts",
    async (data, thunkAPI) => {
        try {
            return await forumService.fetchForumPosts(
                data.limit,
                data.category,
                data.page
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createForumPost = createAsyncThunk(
    "forum/createForumPost",
    async (data, thunkAPI) => {
        try {
            return await forumService.createForumPost(data.values, data.actions)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createPostComment = createAsyncThunk(
    "forum/createPostComment",
    async (data, thunkAPI) => {
        try {
            return await forumService.createPostComment(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchForumPostDetails = createAsyncThunk(
    "forum/fetchForumPostDetails",
    async (slug, thunkAPI) => {
        try {
            return await forumService.fetchForumPostDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const forumSlice = createSlice({
    name: "forum",
    initialState,
    reducers: {
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        resetFormSubmitted: (state) => {
            state.formSubmitted = false
        },
        resetPostsList: (state) => {
            state.posts = []
            state.currentPage = 1
            state.lastPage = 1
            state.perPage = 20
            state.nextPageURI = null
            state.prevPageURI = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchForumCategories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchForumCategories.fulfilled, (state, action) => {
                state.isLoading = false
                state.categories = action.payload
            })
            .addCase(fetchForumCategories.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
                state.categories = []
            })
            .addCase(createForumPost.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createForumPost.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isLoading = false
            })
            .addCase(createForumPost.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchForumPosts.pending, (state) => {
                state.postsLoading = true
            })
            .addCase(fetchForumPosts.fulfilled, (state, action) => {
                state.postsLoading = false
                state.posts = [...state.posts, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchForumPosts.rejected, (state, action) => {
                state.postsLoading = false
                state.isError = true
                state.posts = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchForumPostDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchForumPostDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.postDetails = action.payload.post
                state.similarPosts = action.payload.similar_posts
            })
            .addCase(fetchForumPostDetails.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
                state.postDetails = {}
                state.similarPosts = []
            })
            .addCase(createPostComment.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createPostComment.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isLoading = false
                state.postDetails = {
                    ...state.postDetails,
                    comments: action.payload.comments,
                    comments_count: action.payload.comments.length,
                }
            })
            .addCase(createPostComment.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
    },
})

export const { setSelectedCategory, resetFormSubmitted, resetPostsList } =
    forumSlice.actions

export default forumSlice.reducer
