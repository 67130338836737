import { Field, useField } from "formik"
import "./custom-inputs.css"

const RadioInput = ({ label, ...props }) => {
    const [field] = useField(props)

    return (
        <div>
            <label>
                <Field {...props} {...field} /> {label}
            </label>
        </div>
    )
}

export default RadioInput
