import React, { Component } from "react"
import Slider from "react-slick"
import { BoardItem } from "./BoardItem"

class Board extends Component {
  render() {
    const founders = [
      {
        id: 1,
        image: "./dists/images/aw-1.jpg",
        designation: "Founder",
        batch: "1993",
        name: "Dr. R.K. Gupta",
      },
      {
        id: 2,
        image: "./dists/images/aw-2.jpg",
        designation: "Patron",
        batch: "1995",
        name: "Saurabh Mittal",
      },
      {
        id: 3,
        image: "./dists/images/aw-3.jpg",
        designation: "Chairman",
        batch: "1996",
        name: "Mr. Neeraj Arora",
      },
      {
        id: 4,
        image: "./dists/images/aw-4.jpg",
        designation: "Vice Chairman",
        batch: "2000",
        name: "Arun Kumar",
      },
    ]

    const settings = {
      dots: false,
      infinite: false,
      arrows: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            className: "center",
            centerMode: true,
            infinite: true,
            arrows: false,
          },
        },
      ],
    }

    return (
      <div className="container mx-auto px-6 relative">
        <div className="aa-board-bg-gradient p-8 mb-[12rem] rounded-xl">
          <div className="-mb-[5.8rem] -mx-[3.3rem] md:mx-0">
            <Slider {...settings}>
              {founders.map((founder) => (
                <BoardItem
                  key={founder.id}
                  name={founder.name}
                  image={founder.image}
                  batch={founder.batch}
                  designation={founder.designation}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

export default Board
