import React from "react"
import AddCommentBtn from "./AddCommentBtn"
import { apiBaseURI } from "../../../../config"

const Comments = ({ comments, count }) => {
    return (
        <div>
            <h2 className="text-xl font-bold mb-4">{count} Comments</h2>
            <div className="mb-4">
                {comments.map((comment) => (
                    <div
                        key={comment.id}
                        className="border-b border-b-gray-200 py-2 px-4"
                    >
                        <div className="flex items-center space-x-4">
                            <img
                                className="w-10 h-10 rounded-full"
                                src={`${apiBaseURI}${comment.member?.basic_details?.photo_url}`}
                                alt={comment.member?.name}
                            />
                            <div className="font-medium">
                                <div>{comment.member?.name}</div>
                                <div className="text-sm text-gray-500">
                                    {comment.commented_at}
                                </div>
                            </div>
                        </div>
                        <p className="mt-2 text-sm md:text-base">
                            {comment.comment}
                        </p>
                    </div>
                ))}
            </div>
            <AddCommentBtn />
        </div>
    )
}

export default Comments
