import { apiBaseURI, staticBaseURI } from "../../config"

const AlumniDayItem = ({ title, image, slug, year }) => {
    return (
        // <div className="alumni-news-item m-2">
        //     <div className="rounded-2xl overflow-hidden shadow-md">
        //         <img
        //             className="w-full"
        //             src={`${apiBaseURI}${image}`}
        //             alt={title}
        //         />
        //         <div className=" border-gray-300 border bg-white">
        //             <div className="px-6 py-4">
        //                 <div className="font-bold xs:text-2xl sm:text-2xl mb-2 h-20 md:h-16">
        //                     {title}
        //                 </div>
        //             </div>
        //             <div className="px-6 pt-4 pb-8">
        //                 <a
        //                     href={`${staticBaseURI}/chair-details/${slug}`}
        //                     className="font-bold xs:text-base sm:text-lg"
        //                 >
        //                     Read More &gt;
        //                 </a>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="relative award-winner-item bg-blue-semi-dark mx-2 py-4 px-8 rounded-xl my-4">
            <a href={`${staticBaseURI}/alumni-day-details/${slug}`}>
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={title}
                    className="-mt-8 rounded-xl"
                />
                <h4 className="text-white text-xl h-20 md:text-2xl lg:text-3xl font-bold mt-4">
                    {title}
                </h4>
                <h6 className="text-white md:text-lg lg:text-xl">
                    Year: {year}
                </h6>
                <div className="flex justify-center mt-6">
                    <a
                        href="#0"
                        className="text-white uppercase absolute -bottom-4 font-semibold rounded-lg py-1 px-3 young-read-more-btn"
                    >
                        Read More
                    </a>
                </div>
            </a>
        </div>
    )
}

export default AlumniDayItem
