import PointItem from "./PointItem"

const EndowmentPoints = ({ points = [] }) => {
    return (
        <section className="wd-endowment-points bg-white">
            <div className="container mx-auto py-12">
                <div className="px-10 wd-endowment-points-header mb-10">
                    <h1 className="uppercase text-xl md:text-2xl lg:text-4xl font-bold underline underline-offset-8">
                        Globally endowments allow institutions to:
                    </h1>
                </div>
                <div className="pr-10">
                    {points.map((point) => (
                        <PointItem key={point.id} title={point.title} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default EndowmentPoints
