import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchDonations } from "../../../features/auth/authSlice"
import { staticBaseURI } from "../../../config"

const Payments = () => {
    const dispatch = useDispatch()

    const { login, isLoading, donations } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(fetchDonations())
    }, [dispatch])

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    return (
        <>
            <div className=" bg-slate-300 py-16 px-5 md:px-10 flex justify-center ">
                <div className="border-2 border-slate-400 p-4 md:p-8 rounded-lg bg-white w-full">
                    <h1 className="text-2xl font-bold text-center uppercase mb-4">
                        Donations
                    </h1>
                    <hr />
                    <div className=" overflow-x-auto mt-6">
                        <table className="custom-table table-auto w-[200%] md:w-full">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th width="30%">Date</th>
                                    <th>Transaction ID</th>
                                    <th>Amount</th>
                                    <th>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {donations.length > 0 ? (
                                    donations.map((donation, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{donation.formatted_date}</td>
                                            <td>{donation.transaction_id}</td>
                                            <td>{donation.amount}</td>
                                            <td>
                                                <a
                                                    href={`${staticBaseURI}/payment-receipt/${donation.transaction_id}`}
                                                    className="bg-blue-dark text-white px-3 text-sm py-1 rounded-md"
                                                >
                                                    View Receipt
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            You have not made any donations yet.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payments
