// import { staticBaseURI } from "../../config"
import { AlumniItem } from "./AlumniItem"
import ImageCard from "../../components/Skeletons/ImageCard"

const SingleYearView = ({ alumniRecords, year, isLoading }) => {
    return (
        <div className="mt-12">
            <div className="flex justify-end">
                <h1 className="text-blue-light underline underline-offset-8 decoration-cyan-300 text-2xl md:text-3xl lg:text-5xl">
                    Alumni Database {year && `: ${year}`}
                </h1>
            </div>
            <div className="mt-16">
                {isLoading ? (
                    // <div className="flex justify-center">
                    //     <img
                    //         src={`${staticBaseURI}/dists/images/oval.svg`}
                    //         alt="loading"
                    //     />
                    // </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {[...Array(4)].map((i) => (
                            <ImageCard key={i} />
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
                        {alumniRecords.map((alumni) => (
                            <AlumniItem
                                key={`alumni-item-${alumni.id}`}
                                name={alumni.name}
                                batch={alumni.batch}
                                image={alumni.image_url}
                                slug={alumni.id}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SingleYearView
