import * as DOMPurify from "dompurify"

const WhyGiveRgu = ({ content }) => {
    let cleanContent = DOMPurify.sanitize(content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="px-4 pt-16 pb-0 relative">
            <div className="container mx-auto px-6">
                <div className="mb-4">
                    <h1 className="text-2xl md:text-4xl font-bold">
                        Why Give to RGU?
                    </h1>
                </div>
                <div dangerouslySetInnerHTML={createMarkup(cleanContent)}></div>
                <div className="ef-bg-green-gradient p-4 md:p-8 mt-8 rounded-tl-2xl rounded-tr-2xl">
                    <div className="flex justify-center relative -mb-20">
                        <div className="relative">
                            <img
                                src="./dists/images/video-img.jpg"
                                alt="video"
                            />
                            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <img
                                    src="./dists/images/video-play.png"
                                    className="w-16 md:w-32"
                                    alt="play button"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyGiveRgu
