import { Link } from "react-router-dom"
import { FaBars } from "react-icons/fa"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import "./header.css"
import RGUNetwork from "./RGUNetwork"
import DropdownContentHead from "./DropdownContentHead"
import { staticBaseURI } from "../../config"
import MenuLink from "./MenuLink"
import { fetchMenuSettings } from "../../features/config/configSlice"
import { checkUser, logout } from "../../features/auth/authSlice"
import MobileMenu from "./MobileMenu"
import { BiChevronRight } from "react-icons/bi"

function Header() {
    const dispatch = useDispatch()

    const { menu } = useSelector((state) => state.config)
    const { user, login } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(fetchMenuSettings())
        dispatch(checkUser())
    }, [dispatch])

    function handleLogout(e) {
        e.preventDefault()
        dispatch(logout())
    }

    return (
        <>
            <section className="navbar-wrapper">
                <nav
                    className={`w-full top-0 flex justify-between text-white px-8 z-10 bg-blue-semi-dark`}
                >
                    <div className="site-logo">
                        <div className="flex">
                            <div className="flex-initial self-center mr-2">
                                <Link to="/">
                                    <img
                                        src={`${staticBaseURI}/dists/images/logo.png`}
                                        alt="logo"
                                        className="w-20"
                                    />
                                </Link>
                            </div>
                            <div className="flex-1 self-center logo-text">
                                {/* hidden lg:block */}
                                <h3 className="font-bold">Alumni</h3>
                                <h3 className="font-bold">Affairs</h3>
                            </div>
                            <div className="ml-12 my-1 border-r-2 border-light-blue hidden lg:block"></div>
                        </div>
                    </div>
                    <div className="text-lg self-center">
                        <ul className="navbar-menu-items hidden sm:hidden md:hidden lg:flex">
                            <li className="dropdown menu-item">
                                <a href="#0">Alumni Affairs</a>
                                <div className="dropdown-content">
                                    <div className="triangle-top"></div>
                                    <h3 className="font-bold text-lg underline underline-offset-2">
                                        Alumni Affairs
                                    </h3>
                                    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mt-4">
                                        <div className="">
                                            <DropdownContentHead text="About" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.alumni_affairs ? (
                                                    <li>
                                                        <MenuLink
                                                            url="alumni-affairs"
                                                            title="Introduction"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.alumni_news ? (
                                                    <li>
                                                        <MenuLink
                                                            url="news"
                                                            title="Alumni News"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.alumni_database ? (
                                                    <li>
                                                        <MenuLink
                                                            url="alumni-database"
                                                            title="Alumni Database"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.newsletter ? (
                                                    <li>
                                                        <MenuLink
                                                            url="newsletter"
                                                            title="Newsletter"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.newsletter_archive ? (
                                                    <li>
                                                        <MenuLink
                                                            url="newsletter-archive"
                                                            title="Newsletter Archive"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.memories ? (
                                                    <li>
                                                        <MenuLink
                                                            url="memories"
                                                            title="Memories"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Recognitions" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.distinguished_alumni ? (
                                                    <li>
                                                        <MenuLink
                                                            url="distinguished-alumni-award"
                                                            title="Distinguished Alumni Awards"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.distinguished_alumni_service ? (
                                                    <li>
                                                        <MenuLink
                                                            url="young-alumni-achievements"
                                                            title="Young Alumni Achievements"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.young_alumni ? (
                                                    <li>
                                                        <MenuLink
                                                            url="distinguished-alumni-service-award"
                                                            title="Distinguished Alumni Service
                                                    Award"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.alumni_of_the_month ? (
                                                    <li>
                                                        <MenuLink
                                                            url="alumni-of-the-month"
                                                            title="Alumni of The Month"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Contributions" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.scholarships_awards ? (
                                                    <li>
                                                        <MenuLink
                                                            url="scholarships-awards"
                                                            title="Scholarships and Awards"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.chairs ? (
                                                    <li>
                                                        <MenuLink
                                                            url="chairs"
                                                            title="Chairs"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.special_purposes ? (
                                                    <li>
                                                        <MenuLink
                                                            url="special-purposes"
                                                            title="Special Purposes"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.class_contribution ? (
                                                    <li>
                                                        <MenuLink
                                                            url="class-contribution"
                                                            title="Class Contributions"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div className="z-10">
                                            <DropdownContentHead text="Edowment Fund" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.endowment_fund ? (
                                                    <li>
                                                        <MenuLink
                                                            url="endowment-fund"
                                                            title="About Us"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.why_donate ? (
                                                    <li>
                                                        <MenuLink
                                                            url="why-donate-us"
                                                            title="Why Donate"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.how_to_donate ? (
                                                    <li>
                                                        <MenuLink
                                                            url="how-to-donate"
                                                            title="How To Donate"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.team ? (
                                                    <li>
                                                        <MenuLink
                                                            url="team"
                                                            title="Team"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.recognition ? (
                                                    <li>
                                                        <MenuLink
                                                            url="recognition"
                                                            title="Recognition"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.faq ? (
                                                    <li>
                                                        <MenuLink
                                                            url="faq"
                                                            title="FAQ's"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Occassions" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {/* {menu.alumni_lecture ? (
                                                    <li>
                                                        <MenuLink title="Alumni Lecture Series" />
                                                    </li>
                                                ) : null} */}

                                                {menu.tech_talks ? (
                                                    <li>
                                                        <MenuLink
                                                            url="tech-talks"
                                                            title="Tech Talks"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Services" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.degree_certificates ? (
                                                    <li>
                                                        <MenuLink
                                                            url="degree-certificates"
                                                            title="Degrees &amp; Certificates"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.facilities ? (
                                                    <li>
                                                        <MenuLink
                                                            url="facilities"
                                                            title="Institute Facilities"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div className="col-span-2 rounded-br-lg relative dropdown-content-image z-0">
                                            <div className="img-overlay"></div>
                                            <img
                                                src={`${staticBaseURI}/dists/images/e-4.jpg`}
                                                alt="header-img"
                                                className="absolute -bottom-8 -right-8 rounded-br-[1.70rem]"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="dropdown menu-item dropdown-compact">
                                <a href="#0">Alumni Association</a>
                                <div className="dropdown-content">
                                    <h3 className="font-bold text-lg underline underline-offset-2">
                                        Alumni Association
                                    </h3>
                                    <div className="grid grid-cols-3 gap-x-4 gap-y-4 mt-4">
                                        <div>
                                            <DropdownContentHead text="About" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.alumni_association ? (
                                                    <li>
                                                        <MenuLink
                                                            url="alumni-association"
                                                            title="About"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.office_bearers ? (
                                                    <li>
                                                        <MenuLink
                                                            url="office-bearers"
                                                            title="Office Bearers"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.bye_laws ? (
                                                    <li>
                                                        <MenuLink
                                                            url="by-laws"
                                                            title="Bye Laws"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.chapter_policy ? (
                                                    <li>
                                                        <MenuLink
                                                            url="chapter-policy"
                                                            title="Chapter Policy"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Events" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.latest_events ? (
                                                    <li>
                                                        <MenuLink
                                                            url="events"
                                                            title="Latest Events"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.reunions ? (
                                                    <li>
                                                        <MenuLink
                                                            url="reunions"
                                                            title="Reunions"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.chapters ? (
                                                    <li>
                                                        <MenuLink
                                                            url="chapters"
                                                            title="Chapters"
                                                        />
                                                    </li>
                                                ) : null}

                                                {menu.alumni_day ? (
                                                    <li>
                                                        <MenuLink
                                                            url="alumni-day"
                                                            title="Alumni Day"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                        <div>
                                            <DropdownContentHead text="Updates" />
                                            <ul className="mt-6 dropdown-content-links">
                                                {menu.noticeboard ? (
                                                    <li>
                                                        <MenuLink
                                                            url="notice-board"
                                                            title="Noticeboard"
                                                        />
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item">
                                <a href={`${staticBaseURI}/give`}>Donate Now</a>
                            </li>

                            {login ? (
                                <li className="dropdown menu-item dropdown-login">
                                    <div className="relative">
                                        <a href="#0" className="userinfo">
                                            Hi, {user.name}
                                        </a>
                                        <div className="dropdown-content">
                                            <ul className="dropdown-content-links">
                                                {user.profile_completed === 0 &&
                                                    user.mobile && (
                                                        <li>
                                                            <MenuLink
                                                                url="profile/basic-details"
                                                                title="Profile"
                                                            />
                                                        </li>
                                                    )}

                                                {user.profile_completed ===
                                                    1 && (
                                                    <>
                                                        <li>
                                                            <MenuLink
                                                                url="dashboard"
                                                                title="Dashboard"
                                                            />
                                                        </li>
                                                        <li>
                                                            <MenuLink
                                                                url="resume"
                                                                title="Resume"
                                                            />
                                                        </li>
                                                        <li>
                                                            <MenuLink
                                                                url="forum"
                                                                title="Forum"
                                                            />
                                                        </li>
                                                    </>
                                                )}

                                                <li>
                                                    <MenuLink
                                                        url="my-donations"
                                                        title="Donations"
                                                    />
                                                </li>

                                                <li>
                                                    <a
                                                        href="#0"
                                                        className="flex"
                                                        onClick={(e) =>
                                                            handleLogout(e)
                                                        }
                                                    >
                                                        <span>
                                                            <BiChevronRight />
                                                        </span>
                                                        Logout
                                                    </a>
                                                </li>
                                            </ul>
                                            {/* <button
                                                type="button"
                                                className="lg:p-4 py-2 block"
                                            >
                                                Logout
                                            </button> */}
                                        </div>
                                    </div>
                                </li>
                            ) : (
                                <li className="menu-item">
                                    <a href={`${staticBaseURI}/login`}>Login</a>
                                </li>
                            )}
                        </ul>
                        <button
                            type="button"
                            className="md:block lg:hidden text-white text-xl js-menuToggle"
                        >
                            <FaBars />
                        </button>
                    </div>
                </nav>
                <RGUNetwork />
            </section>
            <MobileMenu />
        </>
    )
}

export default Header
