import { FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import Modal from "../../../../components/Modal/Modal"

import { openModal } from "../../../../features/modal/modalSlice"
import ExperienceForm from "./ExperienceForm"

const ExperienceItem = ({ work }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="mb-4 flex justify-between">
                <div className="flex-1">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <h2 className="font-semibold text-base md:text-xl">
                            {work.job_title}
                        </h2>
                        <h3 className="text-base md:text-xl text-gray-700">{` ${
                            work.start
                        } - ${work.present ? "Present" : work.end}`}</h3>
                    </div>
                    <h4 className="text-sm md:text-lg text-gray-600">
                        {work.company}
                    </h4>
                </div>
                <div className="flex-0 self-center ml-3">
                    <button className="text-2xl text-blue-dark">
                        <FaRegEdit
                            className="text-2xl"
                            onClick={() =>
                                dispatch(openModal(`editExperience${work.id}`))
                            }
                        />
                    </button>
                </div>
            </div>

            {isOpen && modalName === `editExperience${work.id}` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Edit Experience</h3>
                        <hr className="my-4" />
                        <ExperienceForm work={work} />
                    </>
                </Modal>
            )}
        </>
    )
}

export default ExperienceItem
