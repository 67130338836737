import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"

import TextInput from "../../../components/Inputs/TextInput"
import { login } from "../../../features/auth/authSlice"
import Toastify from "../../../components/Notification/Toastify"
import Loader from "../../../components/Loader/Loader"

const LoginForm = () => {
    const dispatch = useDispatch()

    // const { otpSent, otpLoading } = useSelector((state) => state.auth)

    function handleSubmit(values, actions) {
        dispatch(login({ values, actions }))
        // if (otpSent && values.otp) {
        //     dispatch(login({ values, actions }))
        // } else if (!otpSent) {
        //     actions.setSubmitting(false)
        //     actions.setFieldTouched("mobile", true, false)
        //     actions.setFieldError("mobile", "Please click on Send OTP button")
        // } else if (otpSent && !values.otp) {
        //     actions.setFieldError("otp", "Please enter OTP")
        //     actions.setFieldTouched("otp", true, false)
        //     actions.setSubmitting(false)
        // }
    }

    // function handleSendOTP({ values, setFieldError, setFieldTouched }) {
    //     if (!values.mobile) {
    //         setFieldError("mobile", "Please enter mobile number")
    //         setFieldTouched("mobile", true, true)
    //     } else if (values.mobile && values.mobile.toString().length === 10) {
    //         dispatch(sendOTP(values.mobile))
    //     }
    // }

    const LoginSchema = Yup.object({
        // mobile: Yup.string()
        //     .min(10, "Invalid mobile number")
        //     .max(10, "Invalid mobile number")
        //     .required("Please enter mobile number"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Please enter your email"),
        password: Yup.string().required("Please enter password"),
        // otp: otpSent
        //     ? Yup.string().required("Please enter OTP")
        //     : Yup.string().nullable(),
    })

    return (
        <>
            <div>
                <Formik
                    initialValues={{
                        // mobile: "",
                        // otp: "",
                        email: "",
                        password: "",
                    }}
                    validationSchema={LoginSchema}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        handleSubmit(values, actions)
                    }}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            {/* <div className="flex w-full">
                                <div className="flex-1">
                                    <TextInput
                                        name="mobile"
                                        type="number"
                                        placeholder="MOBILE"
                                    />
                                </div>
                                <div className="ml-2">
                                    <button
                                        type="button"
                                        className="bg-white px-4 md:px-2 lg:px-4 py-1 text-md appearance-none mt-3 font-semibold"
                                        onClick={() =>
                                            handleSendOTP(formikProps)
                                        }
                                        disabled={otpLoading ? true : false}
                                    >
                                        {otpLoading ? (
                                            <div className="inline-flex">
                                                <Loader
                                                    size="4"
                                                    classes="mr-1 -mt-1"
                                                />
                                                Sending...
                                            </div>
                                        ) : (
                                            "Send OTP"
                                        )}
                                    </button>
                                </div>
                            </div>
                            {otpSent && (
                                <TextInput
                                    name="otp"
                                    type="number"
                                    placeholder="OTP"
                                />
                            )} */}

                            <TextInput
                                name="email"
                                type="email"
                                placeholder="EMAIL"
                            />
                            <TextInput
                                name="password"
                                type="password"
                                placeholder="PASSWORD"
                            />

                            <button
                                className="bg-white px-8 py-2 text-xl text-center uppercase font-bold mt-6"
                                type="submit"
                                disabled={
                                    formikProps.isSubmitting ? true : false
                                }
                            >
                                {formikProps.isSubmitting ? (
                                    <div className="inline-flex">
                                        <Loader size="6" classes="mr-1 -mt-1" />
                                        Loading...
                                    </div>
                                ) : (
                                    "Login"
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
            <Toastify />
        </>
    )
}

export default LoginForm
