import { toast } from "react-toastify"

import { api } from "../../api"
import { formatDate, staticBaseURI } from "../../config"
import { delete_cookie, getCookie, setCookie } from "../../config/cookie"
import { openRazorpayUi } from "../../config/razorpay"

const signUp = async (values) => {
    const url = `/auth/register`

    const formdata = new FormData()
    formdata.append("full_name", values.full_name)
    formdata.append("mobile", values.mobile)
    formdata.append("otp", values.otp)
    formdata.append("email", values.email)
    formdata.append("password", values.password)
    formdata.append("password_confirmation", values.password_confirmation)

    const response = await api.post(url, formdata)

    return response.data
}

/* Get Logged In User */
const checkUser = async () => {
    const url = `/auth/me`
    const token = getCookie("userToken")

    const response = await api.post(url, { token })

    return response.data
}

/* Get Basic Details */
const fetchBasicDetails = async () => {
    const url = `/auth/fetch-basic-details`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

/* Get Education Details */
const fetchEducationDetails = async () => {
    const url = `/auth/fetch-education-details`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

/* Get Work Details */
const fetchWorkDetails = async () => {
    const url = `/auth/fetch-work-details`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

/* Logout User */
const logout = async () => {
    const token = getCookie("userToken")
    const url = `/auth/logout`

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
    delete_cookie("userToken")
    return response.data
}

const login = async (values) => {
    const url = `/auth/login`

    const formdata = new FormData()
    // formdata.append("mobile", values.mobile)
    // formdata.append("otp", values.otp)
    formdata.append("email", values.email)
    formdata.append("password", values.password)

    const response = await api.post(url, formdata)

    //add cookie if success
    if (response.status === 200) {
        setCookie("userToken", response.data.access_token, 2)
    }

    return response.data
}

const socialLogin = async (values, type) => {
    const url = `/auth/social-login/${type}`

    const formdata = new FormData()
    // formdata.append("mobile", values.mobile)
    // formdata.append("otp", values.otp)
    formdata.append("email", values.email)
    formdata.append("name", values.name)
    formdata.append("id", type === "facebook" ? values.id : values.googleId)

    const response = await api.post(url, formdata)

    //add cookie if success
    if (response.status === 200) {
        setCookie("userToken", response.data.access_token, 2)
    }

    return response.data
}

const linkedinLogin = async (code) => {
    const url = `/auth/linkedin-login`

    const formdata = new FormData()
    formdata.append("code", code)
    const response = await api.post(url, formdata)

    //add cookie if success
    if (response.status === 200) {
        setCookie("userToken", response.data.access_token, 2)
    }

    return response.data
}

const sendOTP = async (mobile) => {
    const url = `/auth/send-otp`

    const formdata = new FormData()
    formdata.append("mobile", mobile)

    const response = await api.post(url, formdata)

    if (response.status === 200) {
        toast.success("OTP successfully sent", {
            position: toast.POSITION.BOTTOM_CENTER,
        })
    }

    return response.data
}

const updateMobile = async (values) => {
    const url = `/auth/update-mobile`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("mobile", values.mobile)
    formdata.append("otp", values.otp)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const makeDonation = async (values) => {
    const url = `/auth/make-donation`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("amount", values.amount)
    formdata.append("form_type", values.form_type)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        openRazorpayUi(response.data)
    } else {
        alert(
            "Oops! Something went wrong. Please refresh the page and try again."
        )
    }
}

const submitBasicDetails = async (values) => {
    const url = `/auth/store-basic-details`
    const token = getCookie("userToken")

    let dob = formatDate(values.dob)
    let batch = formatDate(values.batch)

    const formdata = new FormData()
    formdata.append("name", values.name)
    formdata.append("email", values.email)
    formdata.append("mobile", values.mobile)
    formdata.append("gender", values.gender)
    formdata.append("dob", `${dob.year}-${dob.month}-${dob.day}`)
    formdata.append("department", values.department)
    formdata.append("course", values.course)
    formdata.append("batch", `${batch.year}`)
    formdata.append("registration_id", values.registration_id)
    formdata.append("adr_line_1", values.adr_line_1)
    formdata.append("adr_line_2", values.adr_line_2)
    formdata.append("country", values.country)
    formdata.append("state", values.state)
    formdata.append("city", values.city)
    formdata.append("pin", values.pin)
    formdata.append("facebook", values.facebook)
    formdata.append("twitter", values.twitter)
    formdata.append("linkedin", values.linkedin)
    if (values.photo) {
        formdata.append("photo", values.photo)
    }

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const submitEducationDetails = async (values, actions) => {
    const url = `/auth/store-education-details`
    const token = getCookie("userToken")

    let from = formatDate(values.from)
    let to = formatDate(values.to)

    const formdata = new FormData()
    formdata.append("college", values.college)
    formdata.append("course", values.course)
    formdata.append("from", `${from.year}`)
    formdata.append("to", `${to.year}`)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const updateAbout = async (values) => {
    const url = `/auth/update-about`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("about", values.data)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const editEducationDetails = async (values, actions) => {
    const url = `/auth/edit-education-details/${values.id}`
    const token = getCookie("userToken")

    let from = formatDate(values.from)
    let to = formatDate(values.to)

    const formdata = new FormData()
    formdata.append("college", values.college)
    formdata.append("course", values.course)
    formdata.append("from", `${from.year}`)
    formdata.append("to", `${to.year}`)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const deleteEducationRow = async (id) => {
    const token = getCookie("userToken")
    const url = `/auth/delete-education-row/${id}`

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const submitWorkDetails = async (values, actions) => {
    const url = `/auth/store-work-details`
    const token = getCookie("userToken")

    let from = formatDate(values.from)
    let to = values.to ? formatDate(values.to) : null

    const formdata = new FormData()
    formdata.append("job_title", values.job_title)
    formdata.append("company", values.company)
    formdata.append("from", `${from.year}`)
    formdata.append("to", to ? `${to.year}` : "")
    formdata.append("present", values.present)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    }

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const editWorkDetails = async (values, actions) => {
    const url = `/auth/edit-work-details/${values.id}`
    const token = getCookie("userToken")

    let from = formatDate(values.from)
    let to = values.to ? formatDate(values.to) : null

    const formdata = new FormData()
    formdata.append("job_title", values.job_title)
    formdata.append("company", values.company)
    formdata.append("from", `${from.year}`)
    formdata.append("to", to ? `${to.year}` : "")
    formdata.append("present", values.present)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const addSkill = async (values, actions) => {
    const url = `/auth/add-skill`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("name", values.name)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

/* Fetch Skills */
const fetchSkills = async () => {
    const url = `/auth/fetch-skills`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const deleteWorkRow = async (id) => {
    const token = getCookie("userToken")
    const url = `/auth/delete-work-row/${id}`

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

/* Fetch Projects */
const fetchProjects = async () => {
    const url = `/auth/fetch-project-details`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const submitProjectDetails = async (values, actions) => {
    const url = `/auth/store-project-details`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("title", values.title)
    formdata.append("description", values.description)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    }

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const editProjectDetails = async (values, actions) => {
    const url = `/auth/edit-project-details/${values.id}`
    const token = getCookie("userToken")

    const formdata = new FormData()
    formdata.append("title", values.title)
    formdata.append("description", values.description)

    const response = await api.post(url, formdata, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 200) {
        actions.resetForm()
    } else if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

const deleteProjectRow = async (id) => {
    const token = getCookie("userToken")
    const url = `/auth/delete-project-row/${id}`

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.status === 401) {
        window.location.href = `${staticBaseURI}/login`
    }

    return response.data
}

/* Get Receipt Details */
const fetchReceiptDetails = async (transactionId) => {
    const url = `/auth/receipt-details/${transactionId}`

    const response = await api.get(url)

    return response.data
}

/* Fetch Donations */
const fetchDonations = async () => {
    const url = `/auth/my-donations`
    const token = getCookie("userToken")

    const response = await api.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    return response.data
}

const authService = {
    signUp,
    sendOTP,
    login,
    checkUser,
    logout,
    makeDonation,
    submitBasicDetails,
    fetchBasicDetails,
    submitEducationDetails,
    fetchEducationDetails,
    editEducationDetails,
    deleteEducationRow,
    submitWorkDetails,
    editWorkDetails,
    deleteWorkRow,
    fetchWorkDetails,
    socialLogin,
    updateMobile,
    linkedinLogin,
    updateAbout,
    addSkill,
    fetchSkills,
    submitProjectDetails,
    editProjectDetails,
    fetchProjects,
    deleteProjectRow,
    fetchReceiptDetails,
    fetchDonations,
}

export default authService
