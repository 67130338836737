import Slider from "react-slick"
import EventItem from "../Homepage/Events/EventItem"

const OngoingEvents = ({ events }) => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: events.length >= 3 ? 3 : events.length,
                    infinite: true,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: events.length >= 2 ? 2 : events.length,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: events.length >= 2 ? 2 : events.length,
                    infinite: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark py-16">
            <div className="container mx-auto">
                <div className="flex justify-end px-4 md:px-10">
                    <h3 className="uppercase font-bold text-3xl text-white">
                        Ongoing Events
                    </h3>
                </div>
                <div className="mt-10 tt-ongoing-talks">
                    <Slider {...settings}>
                        {events.map((event) => (
                            <div className="px-2 md:px-4">
                                <EventItem
                                    key={event.id}
                                    title={event.title}
                                    image={event.image_url}
                                    date={event.formatted_event_date}
                                    time={event.time}
                                    day={event.event_day}
                                    type={event.type}
                                    url={event.slug}
                                    category="Ongoing"
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default OngoingEvents
