import { api } from "../../api"

//fetch
const fetchEvents = async (limit, page, type) => {
    const url = `/events-list/${limit}/${type}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch event details
const fetchEventDetails = async (slug) => {
    const url = `/event-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const eventsService = {
    fetchEvents,
    fetchPageContent,
    fetchEventDetails,
}

export default eventsService
