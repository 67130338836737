import RegisterForm from "./RegisterForm"
import { useSelector } from "react-redux"
import RegistrationComplete from "./RegistrationComplete"
import { staticBaseURI } from "../../../config"

const RegsiterFormWrapper = () => {
    const { registered } = useSelector((state) => state.auth)

    return (
        <section
            className="register-from-wrapper relative"
            style={{ backgroundImage: `url('./dists/images/auth-bg.jpg')` }}
        >
            <div className="background-overlay h-full w-full">
                <div className="container mx-auto px-10 py-20">
                    <div className="w-full md:w-[80%]">
                        <div className="text-center mb-4 hidden md:block">
                            <div className="-ml-4 text-white text-xl font-bold">
                                OR
                            </div>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="md:border-r-4 border-r-cyan-400 md:pr-8">
                                {registered ? (
                                    <RegistrationComplete />
                                ) : (
                                    <RegisterForm />
                                )}
                            </div>
                            <div className="pl-4 text-center">
                                <div className="text-center mb-8 block md:hidden mt-8">
                                    <div className=" text-white text-xl font-bold">
                                        OR
                                    </div>
                                </div>
                                <a
                                    href={`${staticBaseURI}/login`}
                                    className="mt-8 uppercase font-bold text-2xl login-btn px-8 py-2"
                                >
                                    Login
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RegsiterFormWrapper
