import { api } from "../../api"

const fetchNews = async (limit, page) => {
    const url = `/news/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch news details
const fetchNewsDetails = async (slug) => {
    const url = `/news/details/${slug}`
    const response = await api.get(url)
    return response.data
}

const newsService = {
    fetchNews,
    fetchNewsDetails,
}

export default newsService
