import React from "react"
import { staticBaseURI } from "../../config"

const NoticeItem = ({ date, month, year, title, slug }) => {
    return (
        <div className="bg-white rounded-2xl py-4 px-4 md:px-8 mb-6">
            <a href={`${staticBaseURI}/notice-board-details/${slug}`}>
                <div className="flex justify-between">
                    <div className="notice-date-wrapper border-r-2 pr-4 mr-4 border-r-black text-center">
                        <h1 className="font-bold text-4xl md:text-6xl">
                            {date}
                        </h1>
                        <h3 className="font-bold text-xl md:text-3xl uppercase">
                            {month}
                        </h3>
                        <h3 className="font-bold text-lg md:text-2xl">
                            {year}
                        </h3>
                    </div>
                    <div className="self-center font-semibold text-lg md:text-2xl lg:text-3xl">
                        {title}
                    </div>
                </div>
            </a>
        </div>
    )
}

export default NoticeItem
