import Slider from "react-slick"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { AlumniItem } from "./AlumniItem"
import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const MultiYearItem = ({ loop, year, alumnis }) => {
    const isEven = loop % 2 === 0 ? true : false

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <motion.div
            initial={framerAnimationInitial}
            animate={controls}
            transition={{
                duration: 0.3,
                delay: 0.3,
            }}
            className="multi-year-item grid grid-cols-5 my-6 relative"
            ref={ref}
        >
            {!isEven && (
                <div className="col-span-1 multi-year-text font-bold self-center text-center">
                    <h3 className="text-xl md:text-2xl lg:text-3xl">Year</h3>
                    <h1 className="text-2xl md:text-4xl lg:text-6xl">{year}</h1>
                </div>
            )}

            <div className="col-span-4 relative">
                <Slider {...settings} className="multi-year-carousel">
                    {alumnis.map((alumni) => (
                        <AlumniItem
                            key={`alumni-item-${alumni.id}`}
                            name={alumni.name}
                            batch={alumni.batch}
                            image={alumni.image_url}
                            slug={alumni.id}
                        />
                    ))}
                </Slider>
            </div>

            {isEven && (
                <div className="col-span-1 multi-year-text font-bold  self-center text-center">
                    <h3 className="text-xl md:text-2xl lg:text-3xl">Year</h3>
                    <h1 className="text-2xl md:text-4xl lg:text-6xl">{year}</h1>
                </div>
            )}
        </motion.div>
    )
}

export default MultiYearItem
