import React from "react"
import { staticBaseURI } from "../../config"

const MobileMenuLink = ({ url = "#0", title }) => {
    return (
        <>
            <a href={`${staticBaseURI}/${url}`}>{title}</a>
        </>
    )
}

export default MobileMenuLink
