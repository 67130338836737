import { useDispatch, useSelector } from "react-redux"

import { closeModal, openModal } from "../../../../features/modal/modalSlice"
import Modal from "../../../../components/Modal/Modal"
import NewCommentForm from "./NewCommentForm"
import { useEffect } from "react"

const AddCommentBtn = () => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)
    const { isLoading, formSubmitted } = useSelector((state) => state.forum)

    const closeCommentModal = () => {
        dispatch(closeModal())
    }

    useEffect(() => {
        if (!isLoading && formSubmitted) {
            closeCommentModal()
        }
    }, [isLoading, formSubmitted])

    return (
        <>
            <button
                className="bg-blue-dark text-white px-6 py-2 rounded-md"
                onClick={() => dispatch(openModal("newForumComment"))}
            >
                Add New Comment
            </button>

            {isOpen && modalName === `newForumComment` && (
                <Modal>
                    <div className="text-left">
                        <h3 className="font-bold text-2xl">New Comment</h3>
                        <hr className="my-4" />
                        <NewCommentForm />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default AddCommentBtn
