import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    modalName: "",
    isOpen: false,
}

export const modalSlide = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.modalName = action.payload
            state.isOpen = true
        },
        closeModal: (state) => {
            state.modalName = ""
            state.isOpen = false
        },
    },
})

export const { openModal, closeModal } = modalSlide.actions
export default modalSlide.reducer
