import { FaChevronDown } from "react-icons/fa"
import * as DOMPurify from "dompurify"

const FaqItem = ({ index, question, answer }) => {
    let cleanContent = DOMPurify.sanitize(answer)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <div
            className="group outline-none accordion-section mb-2"
            tabIndex={index}
        >
            <div className="group bg-blue-dark flex justify-between px-4 py-3 items-center text-gray-300 transition ease duration-500 cursor-pointer pr-10 relative">
                <div className="group-focus:text-white transition ease duration-500">
                    {question}
                </div>
                <div className="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 group-focus:text-white group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                    <FaChevronDown />
                </div>
            </div>
            <div className="group-focus:max-h-screen max-h-0 bg-white px-4 overflow-hidden ease duration-500">
                <div
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                    className="text-md md:text-base p-4"
                ></div>
            </div>
        </div>
    )
}

export default FaqItem
