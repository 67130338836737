import { Formik, Form } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import * as Yup from "yup"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import TextInput from "../../components/Inputs/TextInput"
import Loader from "../../components/Loader/Loader"
import {
    framerAnimationFinal,
    framerAnimationInitial,
    staticBaseURI,
} from "../../config"
import { makeDonation } from "../../features/auth/authSlice"

const DonateForm = ({ form_type = "endowment_fund" }) => {
    const dispatch = useDispatch()
    const location = useLocation()

    const handleSubmit = (values, actions) => {
        dispatch(makeDonation({ values, actions }))
    }

    const encodedURI = encodeURIComponent(location.pathname)

    const { login, user, isLoading } = useSelector((state) => state.auth)

    const DonationSchema = Yup.object({
        full_name: Yup.string().required("Please enter your name"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Please enter your email"),
        mobile: Yup.string()
            .min(10, "Invalid mobile number")
            .max(10, "Invalid mobile number")
            .required("Please enter mobile number"),
        amount: Yup.number()
            .min(1, "Donation amount must be of minimum Rs.1")
            .required("Please enter donation amount"),
    })

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div ref={ref}>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.4,
                }}
                className="bg-blue-dark p-4 md:p-8 relative -mt-6 rounded-2xl"
            >
                <div className="flex justify-between text-white">
                    <div className="text-sm md:text-xl lg:text-2xl">
                        Make a Donation, Make a Change
                    </div>
                    <div className="flex">
                        <div className="htd-gradient-underline h-3 w-3 rounded-full mr-2 mt-3"></div>
                        <div className="text-sm md:text-xl lg:text-2xl">
                            Check FAQ
                        </div>
                    </div>
                </div>
                <div className="donation-form-wrapper mt-8">
                    <Formik
                        initialValues={{
                            full_name: login ? user.name : "",
                            mobile: login ? user.mobile : "",
                            email: login ? user.email : "",
                            amount: "",
                            form_type: form_type,
                        }}
                        validationSchema={DonationSchema}
                        enableReinitialize={true}
                        onSubmit={(values, actions) => {
                            handleSubmit(values, actions)
                        }}
                    >
                        {(formikProps: FormikProps<any>) => (
                            <Form>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4">
                                    <TextInput
                                        type="number"
                                        name="amount"
                                        placeholder="Enter donation amount"
                                    />
                                    <TextInput
                                        name="full_name"
                                        type="text"
                                        disabled={login ? true : false}
                                        placeholder="Your name"
                                    />
                                    <TextInput
                                        name="mobile"
                                        type="number"
                                        disabled={login ? true : false}
                                        placeholder="Contact No"
                                    />
                                </div>
                                <div className="flex justify-between mt-4">
                                    <div className="">
                                        <div className="flex">
                                            <div>
                                                <img
                                                    src={`${staticBaseURI}/dists/images/logo.png`}
                                                    className=" grayscale w-10 md:w-20"
                                                    alt="rgu-logo"
                                                />
                                            </div>
                                            <div className="uppercase text-xs md:text-xl font-semibold self-center ml-2 text-white">
                                                <h2>Rajiv Gandhi</h2>
                                                <h2>University</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="self-center animated-btn">
                                        {login ? (
                                            isLoading ? (
                                                <button
                                                    type="button"
                                                    disabled={true}
                                                    className="inline-flex uppercase border-2 border-white px-4 py-2 text-xs md:text-2xl font-bold text-white hover:bg-white hover:text-black"
                                                >
                                                    <Loader
                                                        size="4"
                                                        classes="mr-1 -mt-1"
                                                    />
                                                    Please wait...
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="uppercase border-2 border-white px-4 py-2 text-xs md:text-2xl font-bold text-white hover:bg-white hover:text-black"
                                                >
                                                    Donate Now
                                                </button>
                                            )
                                        ) : (
                                            <a
                                                href={`${staticBaseURI}/login?redirect=${encodedURI}`}
                                                className="as-btn white-bg uppercase border-2 border-white px-6 py-3 text-xs md:text-2xl font-bold text-white hover:text-black"
                                            >
                                                Donate Now
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </motion.div>
        </div>
    )
}

export default DonateForm
