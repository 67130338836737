import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch Purposes
const fetchPurposes = async (limit, page) => {
    const url = `/special-purposes/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch purpose details
const fetchPurposeDetails = async (slug) => {
    const url = `/special-purpose-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const specialpurposesService = {
    fetchPurposes,
    fetchPageContent,
    fetchPurposeDetails,
}

export default specialpurposesService
