import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import ChapterDetailsBody from "./ChapterDetailsBody"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"
import { fetchChapterDetails } from "../../features/chapters/chapterSlice"

const ChapterDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { chapterDetails } = useSelector((state) => state.chaptersPage)

    useEffect(() => {
        dispatch(fetchChapterDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{chapterDetails.meta_title}</title>
                <meta
                    name="description"
                    content={chapterDetails.meta_description}
                />
                <meta name="keywords" content={chapterDetails.meta_keywords} />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${chapterDetails.image_url}`}
            />
            <ChapterDetailsBody
                content={chapterDetails.description}
                title={chapterDetails.title}
            />
        </>
    )
}

export default ChapterDetails
