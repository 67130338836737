import { useEffect } from "react"

import Slider from "react-slick"
import CarouselItem from "./CarouselItem"
import { apiBaseURI } from "../../config"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"

const FacilitiesCarousel = ({ facilities }) => {
    useEffect(() => {
        showActiveFacilityInfo(0)
    })

    function showActiveFacilityInfo(activeIndex) {
        const activeSlide = document.querySelector(
            `[data-index="${activeIndex}"]`
        )

        if (activeSlide) {
            const facilityItem = activeSlide.querySelector(
                `.facilitites-carousel-item`
            )

            const displayElemId = facilityItem.dataset.facility

            hideOtherFacilityInfo()

            const displayElement = document.querySelector(displayElemId)
            displayElement.classList.add("active")
        }
    }

    function hideOtherFacilityInfo() {
        const facilityInfo = document.querySelectorAll(".facility-info-item")
        for (let i = 0; i < facilityInfo.length; i++) {
            const contentArea = facilityInfo[i]
            contentArea.classList.remove("active")
        }
    }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: (current) => showActiveFacilityInfo(current),
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark lg:px-4 py-20">
            <div className="container mx-auto lg:px-6 relative -mt-48 md:-mt-48 lg:-mt-60">
                <Slider {...settings} className="facilities-carousel">
                    {facilities.map((facility) => (
                        <CarouselItem
                            key={`facility-item-${facility.id}`}
                            image={`${apiBaseURI}${facility.cover.image_url}`}
                            name={facility.name}
                            slug={facility.slug}
                        />
                    ))}
                </Slider>
            </div>
        </section>
    )
}

export default FacilitiesCarousel
