import { useField } from "formik"
import "./custom-inputs.css"

const TextInput = ({ label, color = "white", ...props }) => {
    const [field, meta] = useField(props)
    return (
        <div className="mb-4">
            <label htmlFor={props.id || props.name}>{label}</label>
            <input
                className={`text-input ${
                    color === "dark"
                        ? "text-black caret-black"
                        : "text-white caret-white"
                }`}
                {...field}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="input-error ">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default TextInput
