import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"

import {
    fetchAlumniRecords,
    fetchAlumnis,
    setSelectedYear,
    resetAlumniRecords,
} from "../../features/alumni-database/alumniDatabaseSlice"
import YearCarousel from "./YearCarousel"
import MultiYearView from "./MultiYearView"
import SingleYearView from "./SingleYearView"

const DatabaseWrapper = () => {
    const dispatch = useDispatch()

    const {
        selectedYear,
        database,
        years,
        alumniRecords,
        currentPage,
        recordsLoading,
    } = useSelector((state) => state.alumniDatabasePage)

    useEffect(() => {
        dispatch(fetchAlumnis(4))
    }, [dispatch])

    const handleSelectInput = (ev) => {
        const selectValue = ev.value

        dispatch(setSelectedYear(selectValue !== "" ? selectValue : ""))
        if (selectValue !== "") {
            dispatch(resetAlumniRecords())
            dispatch(fetchAlumniRecords(currentPage))
        }
    }

    const yearOptions = years.map((year) => {
        return { value: year, label: year }
    })

    return (
        <section className="alumni-database-wrapper px-4 py-16">
            <div className="container mx-auto px-6">
                <div className="year-select-input-wrapper relative -mt-20">
                    {/* <select
                        className="custom-select-input rounded-md focus:outline-none w-[80%] md:w-[40%] lg:w-[20%]"
                        onChange={(ev) => handleSelectInput(ev)}
                        value={selectedYear}
                    >
                        <option value="">Select Alumni Year</option>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select> */}
                    <Select
                        options={yearOptions}
                        className="w-[80%] md:w-[40%] lg:w-[20%]"
                        onChange={(ev) => handleSelectInput(ev)}
                        defaultValue={selectedYear}
                    />
                </div>
                <div className="flex justify-end mt-8">
                    <div>
                        <h1 className="text-blue-light text-2xl md:text-3xl lg:text-4xl">
                            Alumni Database
                        </h1>
                        <h3 className="text-white text-xl md:text-2xl lg:text-3xl">
                            Year Wise
                        </h3>
                    </div>
                </div>
                <YearCarousel years={years} selectedYear={selectedYear} />
                {selectedYear === "" ? (
                    <MultiYearView database={database} year={selectedYear} />
                ) : (
                    <SingleYearView
                        alumniRecords={alumniRecords}
                        year={selectedYear}
                        isLoading={recordsLoading}
                    />
                )}
            </div>
        </section>
    )
}

export default DatabaseWrapper
