import { api } from "../../api"

//fetch donors
const fetchDonors = async () => {
    const url = `/recognitions`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

const recognitionService = {
    fetchDonors,
    fetchPageContent,
}

export default recognitionService
