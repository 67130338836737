import Slider from "react-slick"
import {
    NextArrowGradient,
    PrevArrowGradient,
} from "../../components/CustomArrows"
import TeamItem from "./TeamItem"

const TeamWrapper = ({ team }) => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrowGradient />,
        prevArrow: <PrevArrowGradient />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-black py-16 relative">
            <div className="container mx-auto px-0 md:px-10 ">
                <div className="ef-team-wrapper-header px-10 md:px-0">
                    <h1 className="font-bold md:text-4xl lg:text-6xl uppercase ">
                        Meet the team
                    </h1>
                    <div className="gradient-underline h-1 mt-2 xl:w-[33%] lg:w-[49%] md:w-[40%]"></div>
                </div>
                <div className="mt-8 md:mt-16">
                    <Slider {...settings} className="main-team-carousel">
                        {team.map((item) => (
                            <TeamItem
                                key={`main-team-${item.id}`}
                                name={item.name}
                                image={item.image_url}
                                designation={item.designation}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default TeamWrapper
