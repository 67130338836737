import { useSelector } from "react-redux"
import { formatDate, staticBaseURI } from "../../../../config"

const SimilarPosts = () => {
    const { similarPosts } = useSelector((state) => state.forum)

    return (
        <div className="bg-white rounded-lg p-4 shadow-md">
            <h1 className="font-bold text-xl lg:text-2xl">Similar Posts</h1>
            <hr className="mb-3 mt-2" />
            <div>
                {similarPosts.map((post) => (
                    <div
                        className="mb-2 border-b border-b-gray-300 pb-2 last:pb-0 last:border-b-0"
                        key={post.uuid}
                    >
                        <a href={`${staticBaseURI}/forum/post/${post.uuid}`}>
                            <h3 className="font-medium text-base leading-5">
                                {post.title}
                            </h3>
                            <div className="flex justify-between text-sm text-gray-500 space-x-4 mt-2">
                                <div>By: {post.member?.name}</div>
                                <div>
                                    {`${
                                        formatDate(new Date(post.api_date)).day
                                    } ${
                                        formatDate(new Date(post.api_date))
                                            .monthName
                                    }, ${
                                        formatDate(new Date(post.api_date)).year
                                    }`}
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SimilarPosts
