import { api } from "../../api"

//fetch facilities
const fetchFacilities = async () => {
    const url = `/facilities`
    const response = await api.get(url)
    return response.data
}

const facilitiesService = {
    fetchFacilities,
}

export default facilitiesService
