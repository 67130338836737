import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
    staticBaseURI,
} from "../../../config"

const EventItem = ({
    image,
    title,
    url = "#0",
    time,
    day,
    date,
    type,
    category,
    index,
}) => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <motion.div
            initial={framerAnimationInitial}
            animate={controls}
            transition={{
                duration: 0.3,
                delay: 0.3 + parseFloat(`0.${index}`),
            }}
            className="event-item"
            ref={ref}
        >
            <div className="max-w-sm rounded-2xl overflow-hidden shadow-md bg-white ">
                <a href={`${staticBaseURI}/event-details/${url}`}>
                    <img
                        className="w-full"
                        src={`${apiBaseURI}${image}`}
                        alt={title}
                        loading="lazy"
                    />
                    <div className="">
                        <div className="px-6 py-4">
                            <div className="font-bold md:text-xl lg:text-2xl mb-2 h-20 md:h-28 lg:h-24">
                                {title}
                            </div>
                            <span className="bg-blue-dark text-white text-sm md:text-base rounded-xl px-2 py-1 uppercase">
                                {category}
                            </span>
                            <div className="mt-3">
                                <div className="uppercase text-gray-500 text-lg">
                                    {date}
                                </div>
                                <div className="uppercase text-sm md:text-base">
                                    {day}{" "}
                                    <span className="font-bold">{time}</span>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-4 border-t-2 border-blue-500 flex justify-end">
                            <button
                                type="button"
                                className="font-bold text-base uppercase"
                            >
                                View Event
                            </button>
                        </div>
                    </div>
                </a>
            </div>
        </motion.div>
    )
}

export default EventItem
