import { useDispatch } from "react-redux"
import { FaFacebook } from "react-icons/fa"
import FacebookLogin from "@greatsumini/react-facebook-login"

import { socialLogin } from "../../../features/auth/authSlice"

const Facebook = ({ hide = false }) => {
    const dispatch = useDispatch()

    const redirect = (response) => {
        dispatch(socialLogin({ data: response, type: "facebook" }))
    }

    return (
        <>
            <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                initParams={{
                    version: "v15.0",
                }}
                onSuccess={(response) => {
                    console.log("Login Success!", response)
                }}
                onFail={(error) => {
                    console.log("Login Failed!", error)
                }}
                onProfileSuccess={(response) => {
                    // console.log("Get Profile Success!", response)
                    redirect(response)
                }}
                render={({ onClick, logout }) => (
                    <a
                        href="#0"
                        className="facebook-connect-btn flex"
                        onClick={onClick}
                        // onLogoutClick={logout}
                    >
                        <div className={hide ? `hidden md:block` : `block`}>
                            Connect With
                        </div>
                        <div
                            className={`mt-0 ${
                                hide ? `ml-0` : `ml-2`
                            } lg:ml-2 self-center`}
                        >
                            <FaFacebook />
                        </div>
                    </a>
                )}
            />
        </>
    )
}

export default Facebook
