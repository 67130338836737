import React from "react"

export const WFDItem = ({ title, image, url }) => {
    return (
        <div className="flex justify-center mb-16 md:mb-0">
            <div className="wfd-item bg-white p-6 rounded-2xl">
                <img src={image} alt={title} className="rounded-2xl -mt-20" />
                <div className="mt-4">
                    <h3 className="font-bold text-3xl md:text-2xl lg:text-4xl uppercase h-20">
                        {title}
                    </h3>
                    <div className="flex justify-end">
                        <a href={url} className="font-bold text-lg uppercase">
                            Know More
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
