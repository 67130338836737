import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import About from "./About"
import EventsList from "./EventsList"
import Pagination from "./Pagination"

import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/events/eventsSlice"

const Events = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.eventsPage)

    useEffect(() => {
        dispatch(fetchPageContent("events"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <About about={pageContent.content} />
            <EventsList />
            <Pagination />
        </>
    )
}

export default Events
