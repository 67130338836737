import { FaLinkedin } from "react-icons/fa"
import { LinkedIn } from "react-linkedin-login-oauth2"

const Linkedin = ({ hide = false }) => {
    return (
        <>
            <LinkedIn
                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                redirectUri={`${window.location.origin}/linkedin-callback`}
                scope="r_liteprofile,r_emailaddress"
                onSuccess={(code) => {
                    window.location.href = `${window.location.origin}/linkedin-login/${code}`
                }}
                onError={(error) => {
                    console.log(error)
                }}
            >
                {({ linkedInLogin }) => (
                    <a
                        href="#0"
                        onClick={linkedInLogin}
                        className="linkedin-connect-btn flex"
                    >
                        <div className={hide ? `hidden md:block` : `block`}>
                            Connect With
                        </div>
                        <div
                            className={`mt-0 ${
                                hide ? `ml-0` : `ml-2`
                            } lg:ml-2 self-center`}
                        >
                            <FaLinkedin />
                        </div>
                    </a>
                )}
            </LinkedIn>
        </>
    )
}

export default Linkedin
