import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { apiBaseURI, staticBaseURI } from "../../config"

const AwardWinnerItem = ({ image, name, year, link }) => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start({
            opacity: 1,
            y: 0,
        })
    }

    return (
        <div ref={ref}>
            <motion.div
                initial={{ opacity: 0, y: 100 }}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                }}
                className="relative award-winner-item bg-blue-dark py-4 px-8 rounded-xl"
            >
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={name}
                    className="-mt-8"
                />
                <h4 className="text-white text-xl md:text-2xl lg:text-3xl font-bold mt-2">
                    {name}
                </h4>
                <h6 className="text-white md:text-lg lg:text-xl">
                    Award Year: {year}
                </h6>
                <div className="flex justify-end mt-3">
                    <a
                        href={`${staticBaseURI}/distinguished-alumni/${link}`}
                        className="text-blue-light uppercase font-bold px-3 border-l-2 read-more-btn"
                    >
                        Read
                    </a>
                </div>
            </motion.div>
        </div>
    )
}

export default AwardWinnerItem
