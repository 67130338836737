import * as DOMPurify from "dompurify"

const Bio = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="bg-black px-4 pt-2 pb-8">
            <div className="container mx-auto px-6">
                <div className="font-bold text-white dap-bio-head flex">
                    bio <div className="dap-gradient-border">&nbsp;</div>
                </div>
                <div
                    className="font-bold text-white text-base  md:text-xl dap-bio-body"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </div>
        </section>
    )
}

export default Bio
