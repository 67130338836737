import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

import "./categories.css"
import {
    fetchForumCategories,
    resetPostsList,
    setSelectedCategory,
} from "../../../../features/forum/forumSlice"
import { scrollTop } from "../../../../config"

const Categories = () => {
    const dispatch = useDispatch()

    const { categories, selectedCategory } = useSelector((state) => state.forum)

    useEffect(() => {
        dispatch(fetchForumCategories())
    }, [dispatch])

    const setSelectCategory = (id) => {
        dispatch(resetPostsList())
        dispatch(setSelectedCategory(id))
        scrollTop()
    }

    return (
        <div className="bg-white rounded-lg shadow-md px-4 py-4">
            <h1 className="font-bold text-xl lg:text-2xl">Categories</h1>
            <hr className="mb-3 mt-2" />
            <ul className="forum-ul-categories">
                {categories.map((category) => (
                    <li
                        className={
                            selectedCategory === category.id ? "active" : ""
                        }
                        key={category.id}
                    >
                        <a
                            href="#0"
                            onClick={() => setSelectCategory(category.id)}
                        >
                            {category.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Categories
