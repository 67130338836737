import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import authService from "./authService"

const initialState = {
    registered: false,
    login: false,
    isLoading: true,
    isError: false,
    user: {},
    formSubmitted: false,
    basicDetails: {},
    educationDetails: [],
    workDetails: [],
    otpSent: false,
    otpLoading: false,
    otpError: false,
    isEdit: false,
    editRow: "",
    skills: [],
    projectDetails: [],
    receiptDetails: {},
    donations: [],
}

export const signUp = createAsyncThunk(
    "auth/signUp",
    async (data, thunkAPI) => {
        try {
            return await authService.signUp(data.values)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateMobile = createAsyncThunk(
    "auth/updateMobile",
    async (data, thunkAPI) => {
        try {
            return await authService.updateMobile(data.values)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
    try {
        return await authService.login(data.values)
    } catch (error) {
        data.actions.setSubmitting(false)
        data.actions.setErrors(error.response.data.errors)
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//social login
export const socialLogin = createAsyncThunk(
    "auth/socialLogin",
    async ({ data, type }, thunkAPI) => {
        try {
            return await authService.socialLogin(data, type)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

//linkedin login
export const linkedinLogin = createAsyncThunk(
    "auth/linkedinLogin",
    async (code, thunkAPI) => {
        try {
            return await authService.linkedinLogin(code)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const checkUser = createAsyncThunk(
    "auth/checkUser",
    async (_, thunkAPI) => {
        try {
            return await authService.checkUser()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    try {
        return await authService.logout()
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const fetchBasicDetails = createAsyncThunk(
    "auth/fetchBasicDetails",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchBasicDetails()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchEducationDetails = createAsyncThunk(
    "auth/fetchEducationDetails",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchEducationDetails()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchWorkDetails = createAsyncThunk(
    "auth/fetchWorkDetails",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchWorkDetails()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const makeDonation = createAsyncThunk(
    "auth/makeDonation",
    async (data, thunkAPI) => {
        try {
            return await authService.makeDonation(data.values)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const submitBasicDetails = createAsyncThunk(
    "auth/submitBasicDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.submitBasicDetails(data.values)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const submitEducationDetails = createAsyncThunk(
    "auth/submitEducationDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.submitEducationDetails(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const editEducationDetails = createAsyncThunk(
    "auth/editEducationDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.editEducationDetails(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteEducationRow = createAsyncThunk(
    "auth/deleteEducationRow",
    async (id, thunkAPI) => {
        try {
            return await authService.deleteEducationRow(id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const submitWorkDetails = createAsyncThunk(
    "auth/submitWorkDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.submitWorkDetails(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateAbout = createAsyncThunk(
    "auth/updateAbout",
    async (values, thunkAPI) => {
        try {
            return await authService.updateAbout(values)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const editWorkDetails = createAsyncThunk(
    "auth/editWorkDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.editWorkDetails(data.values, data.actions)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const addSkill = createAsyncThunk(
    "auth/addSkill",
    async (data, thunkAPI) => {
        try {
            return await authService.addSkill(data.values, data.actions)
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchSkills = createAsyncThunk(
    "auth/fetchSkills",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchSkills()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteWorkRow = createAsyncThunk(
    "auth/deleteWorkRow",
    async (id, thunkAPI) => {
        try {
            return await authService.deleteWorkRow(id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const sendOTP = createAsyncThunk(
    "auth/sendOTP",
    async (mobile, thunkAPI) => {
        try {
            return await authService.sendOTP(mobile)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()

            if (
                error.response.status !== 200 ||
                error.response.status !== 422
            ) {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }

            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchProjects = createAsyncThunk(
    "auth/fetchProjects",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchProjects()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const editProjectDetails = createAsyncThunk(
    "auth/editProjectDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.editProjectDetails(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const submitProjectDetails = createAsyncThunk(
    "auth/submitProjectDetails",
    async (data, thunkAPI) => {
        try {
            return await authService.submitProjectDetails(
                data.values,
                data.actions
            )
        } catch (error) {
            data.actions.setSubmitting(false)
            data.actions.setErrors(error.response.data.errors)
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const deleteProjectRow = createAsyncThunk(
    "auth/deleteProjectRow",
    async (id, thunkAPI) => {
        try {
            return await authService.deleteProjectRow(id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchReceiptDetails = createAsyncThunk(
    "auth/fetchReceiptDetails",
    async (id, thunkAPI) => {
        try {
            return await authService.fetchReceiptDetails(id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchDonations = createAsyncThunk(
    "auth/fetchDonations",
    async (_, thunkAPI) => {
        try {
            return await authService.fetchDonations()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        changeIsEdit: (state) => {
            state.isEdit = true
        },
        changeEditRow: (state, action) => {
            state.editRow = action.payload
        },
        setFormSubmitted: (state, action) => {
            state.formSubmitted = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.fulfilled, (state) => {
                state.registered = true
            })
            .addCase(signUp.rejected, (status) => {
                status.registered = false
            })
            .addCase(sendOTP.pending, (state) => {
                state.otpLoading = true
            })
            .addCase(sendOTP.fulfilled, (state) => {
                state.otpLoading = false
                state.otpSent = true
            })
            .addCase(sendOTP.rejected, (state, action) => {
                state.otpError = true
                state.otpSent = false
                state.otpLoading = false
                state.otpErrorMessage = action.payload
            })
            .addCase(updateMobile.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMobile.fulfilled, (state) => {
                state.isLoading = false
                state.formSubmitted = true
            })
            .addCase(updateMobile.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.formSubmitted = false
                state.errorMessage = action.payload
            })
            .addCase(login.fulfilled, (state, action) => {
                state.login = true
                state.user = action.payload.user
            })
            .addCase(login.rejected, (state) => {
                state.login = false
                state.user = {}
            })
            .addCase(socialLogin.pending, (state) => {
                state.isLoading = true
            })
            .addCase(socialLogin.fulfilled, (state, action) => {
                state.login = true
                state.isLoading = false
                state.user = action.payload.user
            })
            .addCase(socialLogin.rejected, (state) => {
                state.login = false
                state.isLoading = false
                state.user = {}
            })
            .addCase(linkedinLogin.pending, (state) => {
                state.isLoading = true
            })
            .addCase(linkedinLogin.fulfilled, (state, action) => {
                state.login = true
                state.isLoading = false
                state.user = action.payload.user
            })
            .addCase(linkedinLogin.rejected, (state) => {
                state.login = false
                state.isLoading = false
                state.user = {}
            })
            .addCase(checkUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(checkUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.login = true
                state.user = action.payload
            })
            .addCase(checkUser.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.login = false
                state.errorMessage = action.payload
                state.user = {}
            })
            .addCase(makeDonation.pending, (state) => {
                state.isLoading = true
            })
            .addCase(makeDonation.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(makeDonation.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(submitBasicDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(submitBasicDetails.fulfilled, (state, action) => {
                state.user = action.payload.member
                state.formSubmitted = true
                state.isLoading = false
            })
            .addCase(submitBasicDetails.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchBasicDetails.fulfilled, (state, action) => {
                state.basicDetails = action.payload
            })
            .addCase(fetchBasicDetails.rejected, (state, action) => {
                state.basicDetails = {}
                state.isError = true
                state.errorMessage = action.payload
            })
            .addCase(submitEducationDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(submitEducationDetails.fulfilled, (state, action) => {
                state.user = action.payload.member
                state.formSubmitted = true
                state.isLoading = false
                state.educationDetails = action.payload.education_details
            })
            .addCase(submitEducationDetails.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
                state.educationDetails = []
            })
            .addCase(fetchEducationDetails.fulfilled, (state, action) => {
                state.educationDetails = action.payload
            })
            .addCase(fetchEducationDetails.rejected, (state, action) => {
                state.educationDetails = []
                state.isError = true
                state.errorMessage = action.payload
            })
            .addCase(editEducationDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(editEducationDetails.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isEdit = false
                state.editRow = ""
                state.isLoading = false
                state.educationDetails = action.payload.education_details
            })
            .addCase(editEducationDetails.rejected, (state, action) => {
                state.isError = true
                state.isEdit = false
                state.editRow = ""
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(deleteEducationRow.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteEducationRow.fulfilled, (state, action) => {
                state.isLoading = false
                state.educationDetails = action.payload.education_details
            })
            .addCase(deleteEducationRow.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(submitWorkDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(submitWorkDetails.fulfilled, (state, action) => {
                state.user = action.payload.member
                state.formSubmitted = true
                state.isLoading = false
                state.workDetails = action.payload.work_details
            })
            .addCase(submitWorkDetails.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
                state.workDetails = []
            })
            .addCase(fetchWorkDetails.fulfilled, (state, action) => {
                state.workDetails = action.payload
            })
            .addCase(fetchWorkDetails.rejected, (state, action) => {
                state.workDetails = []
                state.isError = true
                state.errorMessage = action.payload
            })
            .addCase(editWorkDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(editWorkDetails.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isEdit = false
                state.editRow = ""
                state.isLoading = false
                state.workDetails = action.payload.work_details
            })
            .addCase(editWorkDetails.rejected, (state, action) => {
                state.isError = true
                state.isEdit = false
                state.editRow = ""
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(deleteWorkRow.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteWorkRow.fulfilled, (state, action) => {
                state.isLoading = false
                state.workDetails = action.payload.work_details
            })
            .addCase(deleteWorkRow.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(updateAbout.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateAbout.fulfilled, (state, action) => {
                state.isLoading = false
                state.basicDetails = action.payload.basic_details
            })
            .addCase(updateAbout.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(addSkill.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addSkill.fulfilled, (state, action) => {
                state.isLoading = false
                state.skills = action.payload.skills
            })
            .addCase(addSkill.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchSkills.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchSkills.fulfilled, (state, action) => {
                state.isLoading = false
                state.skills = action.payload
            })
            .addCase(fetchSkills.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.skills = []
                state.errorMessage = action.payload
            })
            .addCase(submitProjectDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(submitProjectDetails.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isLoading = false
                state.projectDetails = action.payload.project_details
            })
            .addCase(submitProjectDetails.rejected, (state, action) => {
                state.isError = true
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
                state.projectDetails = []
            })
            .addCase(editProjectDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(editProjectDetails.fulfilled, (state, action) => {
                state.formSubmitted = true
                state.isEdit = false
                state.editRow = ""
                state.isLoading = false
                state.projectDetails = action.payload.project_details
            })
            .addCase(editProjectDetails.rejected, (state, action) => {
                state.isError = true
                state.isEdit = false
                state.editRow = ""
                state.formSubmitted = false
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchProjects.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.isLoading = false
                state.projectDetails = action.payload
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.projects = []
                state.errorMessage = action.payload
            })
            .addCase(deleteProjectRow.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteProjectRow.fulfilled, (state, action) => {
                state.isLoading = false
                state.projectDetails = action.payload.project_details
            })
            .addCase(deleteProjectRow.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(logout.fulfilled, (state) => {
                state.login = false
                state.user = {}
            })
            .addCase(fetchReceiptDetails.pending, (state) => {
                state.isLoading = true
                state.receiptDetails = {}
            })
            .addCase(fetchReceiptDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.receiptDetails = action.payload
            })
            .addCase(fetchReceiptDetails.rejected, (state, action) => {
                state.isLoading = false
                state.receiptDetails = {}
                state.isError = true
                state.errorMessage = action.payload
            })
            .addCase(fetchDonations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDonations.fulfilled, (state, action) => {
                state.isLoading = false
                state.donations = action.payload
            })
            .addCase(fetchDonations.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.donations = []
                state.errorMessage = action.payload
            })
    },
})

export const { changeIsEdit, changeEditRow, setFormSubmitted } =
    authSlice.actions
export default authSlice.reducer
