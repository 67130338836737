import { useEffect } from "react"
import { useSelector } from "react-redux"
import { scrollTop, staticBaseURI } from "../../../config"

const Dashboard = () => {
    const { login, isLoading } = useSelector((state) => state.auth)

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    useEffect(() => {
        scrollTop()
    })

    return (
        <>
            <div className=" bg-slate-300 py-16 h-[90vh] px-10 flex justify-center items-center ">
                <div className="border-2 border-slate-400 p-8 rounded-lg bg-white">
                    <div className="flex justify-center">
                        <img
                            src={`${staticBaseURI}/dists/images/immigration.png`}
                            className="w-28"
                            alt="verification"
                        />
                    </div>
                    <div className="text-center mt-8">
                        <h1 className="font-bold text-3xl">
                            Thank you for registering!
                        </h1>
                        <h3 className="mt-4 text-lg">
                            Your profile is under verification. You will be
                            notified through your registered email and mobile
                            number once your profile is approved.
                        </h3>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
