import React from "react"

const HelpItem = ({ text }) => {
    return (
        <div className="bg-white rounded-xl p-8 mx-4 border-4 border-green-200 text-3xl font-semibold">
            <h1 className="h-20">{text}</h1>
        </div>
    )
}

export default HelpItem
