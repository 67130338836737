import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import AwardWinnerItem from "./AwardWinnerItem"
import { fetchAlumns } from "../../features/distinguished-alumns/distinguishedAlumnsSlice"
//import { staticBaseURI } from "../../config"
import ImageCard from "../../components/Skeletons/ImageCard"

const AwardWinners = () => {
    const dispatch = useDispatch()

    const { alumns, currentPage, isLoading } = useSelector(
        (state) => state.distinguishedAlumnsPage
    )

    useEffect(() => {
        dispatch(fetchAlumns(currentPage))
    }, [dispatch, currentPage])

    return (
        <section
            className="split-winners-bg-colors pt-8 px-4"
            id="winners-list"
        >
            <div className="container mx-auto px-4 pt-4">
                {isLoading ? (
                    // <div className="flex justify-center mt-12">
                    //     <img
                    //         src={`${staticBaseURI}/dists/images/oval.svg`}
                    //         alt="loading"
                    //     />
                    // </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-12">
                        {[...Array(4)].map((i) => (
                            <ImageCard key={i} />
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-12">
                        {alumns.map((winner) => (
                            <AwardWinnerItem
                                key={winner.id}
                                image={winner.image_url}
                                name={winner.name}
                                year={winner.award_year}
                                link={winner.slug}
                            />
                        ))}
                    </div>
                )}
            </div>
        </section>
    )
}

export default AwardWinners
