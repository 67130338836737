import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/noticeboard/noticeboardSlice"
import About from "./About"
import "./notice-board.css"
import NoticeWrapper from "./NoticeWrapper"

const NoticeBoard = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.noticeboardPage)

    useEffect(() => {
        dispatch(fetchPageContent("notice-board"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About />
            <NoticeWrapper />
        </>
    )
}

export default NoticeBoard
