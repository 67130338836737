import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { apiBaseURI } from "../../config"
import About from "./About"
import PageHeader from "../../components/PageHeader"
import {
    fetchAllTechTalks,
    fetchPageContent,
    resetTalks,
    setType,
} from "../../features/tech-talks/techTalkSlice"
import TalkItem from "./TalkItem"
import Loading from "../../components/Loading"

const ViewAllTalks = () => {
    const dispatch = useDispatch()

    const { pageContent, currentPage, allTalks, isLoading, type } = useSelector(
        (state) => state.techTalksPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("tech-talks"))
        dispatch(fetchAllTechTalks(currentPage))
    }, [dispatch, type, currentPage])

    function handleSelectInput(ev) {
        dispatch(resetTalks())
        dispatch(setType(ev.target.value))
    }

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <About about={pageContent.content} />
            <section className="bg-blue-semi-dark py-10">
                <div className="container mx-auto">
                    <div className="px-10 flex justify-between">
                        <div className="tt-header-title border-r-4 pr-4 text-white">
                            <h1 className="text-4xl font-bold">
                                All Tech Talks
                            </h1>
                            <h3 className="text-2xl">Alumni Lecture Series</h3>
                        </div>
                        <div>
                            <div className="year-select-input-wrapper flex flex-col w-full">
                                <label className="text-white text-lg mb-1">
                                    Filter Talks:
                                </label>
                                <select
                                    className="custom-select-input rounded-md focus:outline-none w-fit"
                                    onChange={(ev) => handleSelectInput(ev)}
                                    value={type}
                                >
                                    <option value="all">All Talks</option>
                                    <option value="past">Past Talks</option>
                                    <option value="ongoing">
                                        Ongoing Talks
                                    </option>
                                    <option value="upcoming">
                                        Upcoming Talks
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-20 md:px-10 pb-16">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-8">
                            {allTalks.map((talk) => (
                                <TalkItem
                                    key={talk.id}
                                    title={talk.title}
                                    image={talk.image_url}
                                    date={talk.formatted_event_date}
                                    time={talk.event_time}
                                    slug={talk.slug}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default ViewAllTalks
