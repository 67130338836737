import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import TeamWrapper from "./TeamWrapper"
import "./ef-team.css"
import DevelopmentTeam from "./DevelopmentTeam"
import StudentTeam from "./StudentTeam"
import {
    fetchPageContent,
    fetchTeamMembers,
} from "../../features/endowment-team/endowmentTeamSlice"
import { apiBaseURI } from "../../config"

const Team = () => {
    const dispatch = useDispatch()

    const { pageContent, studentTeam, accountsTeam, mainTeam } = useSelector(
        (state) => state.endowmentTeamPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("endowment-fund-team"))
        dispatch(fetchTeamMembers(20))
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <TeamWrapper team={mainTeam} />
            <DevelopmentTeam team={accountsTeam} />
            <StudentTeam team={studentTeam} />
        </>
    )
}

export default Team
