import { api } from "../../api"

const fetchAwardees = async (limit, page) => {
    const url = `/young-alumni-achievements/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni details
const fetchAlumniDetails = async (slug) => {
    const url = `/young-alumn/details/${slug}`
    const response = await api.get(url)
    return response.data
}

const youngAlumniAwardService = {
    fetchAwardees,
    fetchPageContent,
    fetchAlumniDetails,
}

export default youngAlumniAwardService
