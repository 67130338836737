import React from "react"

const About = ({ title, date, time, speaker }) => {
    return (
        <section className="bg-black px-4 py-20">
            <div className="container px-6 relative">
                <div className="flex justify-between relative">
                    <div className="">
                        <div className="bg-text top-0 ttd-bg-text">talks</div>
                    </div>
                    <div className="mt-1/2">
                        <h1 className="ttd-about-blue-text font-semibold text-3xl md:text-5xl h-20">
                            {title}
                        </h1>
                        <h3 className="text-white text-3xl">
                            {date} | {time}
                        </h3>
                    </div>
                </div>
                <div className=" text-white mt-20 text-2xl">
                    <span className="underline ttd-blue-underline underline-offset-4">
                        Speaker
                    </span>
                    - {speaker}
                </div>
            </div>
        </section>
    )
}

export default About
