import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import * as DOMPurify from "dompurify"
import { FaArrowLeft } from "react-icons/fa"

import { fetchForumPostDetails } from "../../../features/forum/forumSlice"
import {
    formatDate,
    redirectUser,
    scrollTop,
    staticBaseURI,
} from "../../../config"
import SimilarPosts from "./Partials/SimilarPosts"
import Comments from "./Partials/Comments"
import NoComments from "./Partials/NoComments"
import PostBy from "./Partials/PostBy"

const PostDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()

    const { postDetails, similarPosts } = useSelector((state) => state.forum)
    const { login, isLoading, user } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(fetchForumPostDetails(params.slug))
        scrollTop()
    }, [dispatch, params.slug])

    const postTime = formatDate(new Date(postDetails.api_date))

    let cleanContent = DOMPurify.sanitize(postDetails.content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    if (!isLoading && login && !user.profile_completed) {
        redirectUser(user)
    } else if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    return (
        <section className="bg-gray-100 py-20">
            <div className="container mx-auto px-4 lg:px-6">
                <div className="grid grid-cols-3 lg:grid-cols-4 gap-4">
                    <div className="grid-cols-1 col-span-3 md:col-span-1 order-last md:order-first">
                        <PostBy member={postDetails.member} />
                        {similarPosts && similarPosts.length > 0 && (
                            <SimilarPosts />
                        )}
                    </div>
                    <div className="grid-cols-2 col-span-3 md:col-span-2 lg:col-span-3 order-first md:order-last">
                        <div className="bg-white p-4 rounded-md shadow-md">
                            <div className="flex items-center space-x-4">
                                <div>
                                    <Link
                                        to={`/forum`}
                                        className="text-xl"
                                        title="Back to Forum"
                                    >
                                        <FaArrowLeft />
                                    </Link>
                                </div>
                                <div>
                                    <h2 className="font-bold text-md md:text-lg lg:text-xl flex-1">
                                        {postDetails.title}
                                    </h2>
                                    <span className="text-sm text-gray-600">
                                        Posted On:
                                        {postDetails &&
                                            postDetails.api_date &&
                                            ` ${postTime.day} ${postTime.monthName}, ${postTime.year}`}
                                    </span>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div
                                className="leading-relaxed"
                                dangerouslySetInnerHTML={createMarkup(
                                    cleanContent
                                )}
                            ></div>
                            <hr className="my-4" />

                            {postDetails.comments_count > 0 ? (
                                <Comments
                                    comments={postDetails.comments}
                                    count={postDetails.comments_count}
                                />
                            ) : (
                                <NoComments />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PostDetails
