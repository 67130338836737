import * as DOMPurify from "dompurify"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const Introduction = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div className="container mx-auto px-6">
            <div className="flex justify-end mt-10">
                <div className="text-white w-full md:w-2/3 text-xl" ref={ref}>
                    <motion.h2
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                        }}
                        className="uppercase introduction-title font-bold text-3xl md:text-4xl lg:text-6xl mb-4"
                    >
                        Introduction
                    </motion.h2>
                    <motion.div
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.4,
                        }}
                        dangerouslySetInnerHTML={createMarkup(cleanContent)}
                    ></motion.div>
                </div>
            </div>
        </div>
    )
}

export default Introduction
