import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const HowToMakeDonation = () => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div ref={ref}>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                }}
                className="container mx-auto relative px-2 md:px-6 mt-10 "
            >
                <div className="bg-blue-dark  -mb-10 px-4 md:px-8 py-8 rounded-2xl text-white">
                    <h3 className="font-bold text-2xl md:text-4xl">
                        How to make the donation?
                    </h3>
                    <h5 className="text-lg md:text-2xl">Steps towards good</h5>
                    <div className="htd-gradient-underline h-1 mt-4"></div>
                    <div className="grid grid-cols-5 gap-4 mt-8">
                        <div className="flex justify-center text-sm md:text-2xl">
                            <div className="text-center">
                                <div className="flex justify-center mb-4">
                                    <img
                                        src="./dists/images/give.png"
                                        className="w-10 md:w-[50%]"
                                        alt="gift"
                                    />
                                </div>
                                Choose Gift Amount
                            </div>
                        </div>
                        <div className="htd-gradient-underline h-1 self-center"></div>
                        <div className="flex justify-center text-sm md:text-2xl">
                            <div className="text-center">
                                <div className="flex justify-center mb-4">
                                    <img
                                        src="./dists/images/user-info-icon.png"
                                        alt="gift"
                                        className="w-10 md:w-[50%]"
                                    />
                                </div>
                                Add your Information
                            </div>
                        </div>
                        <div className="htd-gradient-underline h-1 self-center"></div>
                        <div className="flex justify-center text-sm md:text-2xl">
                            <div className="text-center">
                                <div className="flex justify-center mb-4">
                                    <img
                                        src="./dists/images/card.png"
                                        className="w-10 md:w-[50%]"
                                        alt="gift"
                                    />
                                </div>
                                Pay
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default HowToMakeDonation
