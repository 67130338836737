import { useField } from "formik"
import "./custom-inputs.css"

const Textarea = ({
    label,
    value = "",
    rows = "5",
    color = "white",
    ...props
}) => {
    const [field, meta] = useField(props)
    return (
        <div className="mb-4">
            <label htmlFor={props.id || props.name}>{label}</label>
            <textarea
                className={`text-input ${
                    color === "dark"
                        ? "text-black caret-black"
                        : "text-white caret-white"
                }`}
                {...field}
                {...props}
                rows={rows}
            >
                {value}
            </textarea>
            {meta.touched && meta.error ? (
                <div className="input-error ">{meta.error}</div>
            ) : null}
        </div>
    )
}

export default Textarea
