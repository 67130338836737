import React from "react"
import Slider from "react-slick"
import { FaChevronRight } from "react-icons/fa"

const PageHeader = () => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const images = [
        {
            id: 1,
            photo: "./dists/images/ps-1.jpg",
        },
        {
            id: 2,
            photo: "./dists/images/ps-2.jpg",
        },
        {
            id: 3,
            photo: "./dists/images/ps-3.jpg",
        },
    ]

    return (
        <section className="anp-header relative">
            <Slider {...settings}>
                {images.map((image) => (
                    <div key={image.id}>
                        <div
                            className="ph-img-wrapper"
                            style={{
                                backgroundImage: `url(${image.photo})`,
                            }}
                        ></div>
                    </div>
                ))}
            </Slider>
            <div className="absolute bottom-1 left-0 anp-slider-caption px-4 pb-12 pt-8">
                <div className="container mx-auto px-6">
                    <div className="flex justify-between text-white">
                        <div className="flex flex-col lg:flex-row news-highlights self-center">
                            <h3 className="xs:text-lg md:text-2xl xl:text-3xl mr-2 xl:mr-4 font-bold flex-1 underline uppercase">
                                News Highlights:
                            </h3>
                            <div className="flex self-center overflow-hidden">
                                <div className="xs:text-base md:text-lg xl:text-xl flex-1 lg:mt-0 mt-4">
                                    Generous scholarship to help RGU students
                                    achieve their dreams
                                </div>
                                <div className="self-center">
                                    <button className="right-arrow-btn px-3 py-2 rounded-lg mr-2">
                                        <FaChevronRight />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="text-right text-white anp-page-name border-l-4 pl-6 md:mt-0 mt-4">
                            <h1 className="text-3xl md:text-4xl xl:text-5xl font-bold">
                                Alumni
                            </h1>
                            <h3 className="text-xl md:text-2xl xl:text-3xl font-semibold">
                                News
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageHeader
