import React from "react"

const SectionTitle = ({ title }) => {
    return (
        <h1 className="font-bold text-2xl border-b-2 border-gray-200 pb-1 uppercase w-full">
            {title}
        </h1>
    )
}

export default SectionTitle
