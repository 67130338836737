import MultiYearItem from "./MultiYearItem"

const MultiYearView = ({ database, year }) => {
    let loop = 1

    return (
        <div className="mt-12">
            <div className="flex justify-end">
                <h1 className="text-blue-light underline underline-offset-8 decoration-cyan-300 text-2xl md:text-3xl lg:text-5xl">
                    Alumni Database {year && `: ${year}`}
                </h1>
            </div>
            <div className="mt-12">
                {Object.entries(database).map(([key, value]) => (
                    <MultiYearItem
                        key={key}
                        loop={loop++}
                        year={key}
                        alumnis={value}
                    />
                ))}
            </div>
        </div>
    )
}

export default MultiYearView
