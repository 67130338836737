import { GoMail } from "react-icons/go"
import { staticBaseURI } from "../../../config"

import "./contact.css"

const Contact = () => {
    return (
        <section className="px-4 py-16 md:py-20 relative gradient-bg-contact">
            <div className="container mx-auto text-center">
                <h1 className="font-bold text-[21px] md:text-[40px] text-white">
                    RGU Alumni Association, Arunachal Pradesh, India
                </h1>
                <h3 className="flex justify-center text-white mt-8 font-bold text-[18px] md:text-[28px] items-center">
                    <span className="mr-3 text-3xl md:text-4xl lg:text-6xl">
                        <GoMail />
                    </span>{" "}
                    office@rgualumni.ac.in
                </h3>
                <div className=" absolute left-0 -bottom-7 flex justify-center w-full animated-btn">
                    <a
                        href={`${staticBaseURI}/give`}
                        className="px-4 py-2 md:px-6 md:py-3 semi-dark-blue-bg hover:text-white text-black bg-white rounded-md font-bold text-base md:text-lg as-btn"
                    >
                        Donate Now
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Contact
