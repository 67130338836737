import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch memories
const fetchMemories = async (limit, page) => {
    const url = `/gallery-albums/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch memory details
const fetchMemoryDetails = async (slug) => {
    const url = `/album-images/${slug}`
    const response = await api.get(url)
    return response.data
}

const memoriesService = {
    fetchMemories,
    fetchPageContent,
    fetchMemoryDetails,
}

export default memoriesService
