import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import {
    fetchDefaultContribution,
    fetchPageContent,
} from "../../features/class-contribution/classContributionSlice"
import About from "./About"
import "./class-contribution.css"
import ClassShowcase from "./ClassShowcase"

const ClassContribution = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.classContributionPage)

    useEffect(() => {
        dispatch(fetchPageContent("class-contribution"))
        dispatch(fetchDefaultContribution())
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About about={pageContent.content} />
            <ClassShowcase />
        </>
    )
}

export default ClassContribution
