import DOMPurify from "dompurify"

const AlumniDayDetailsBody = ({ content, title, year }) => {
    let cleanContent = DOMPurify.sanitize(content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="bg-cyan-200 px-4">
            <div className="container mx-auto bg-white relative -mt-8 px-6 py-8 rounded-tl-2xl rounded-tr-2xl">
                <div>
                    <h1 className="font-bold text-2xl md:text-3xl lg:text-4xl mb-4">
                        {title}
                    </h1>
                    <h3 className="text-2xl mt-4 underline decoration-cyan-300 underline-offset-8">
                        Year : <span className="font-bold"> {year} </span>
                    </h3>
                </div>
                <div
                    className="text-base mt-8"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </div>
        </section>
    )
}

export default AlumniDayDetailsBody
