import Slider from "react-slick"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"
import StudentTeamItem from "./StudentTeamItem"

const StudentTeam = ({ team }) => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark -px-4 md:px-4 pt-20 py-60 relative overflow-hidden">
            <div className="container mx-auto -px-6 md:px-6">
                <Slider {...settings} className="student-team-carousel">
                    {team.map((student) => (
                        <StudentTeamItem
                            key={`student-team-${student.id}`}
                            name={student.name}
                            image={student.image_url}
                            designation={student.designation}
                        />
                    ))}
                </Slider>
            </div>
            <div className="bg-text uppercase eft-stdteam-bg-text">Team</div>
        </section>
    )
}

export default StudentTeam
