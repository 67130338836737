import { Formik, Form } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux"
import { parseISO } from "date-fns"

import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import validateWorkDetails from "../../Profile/Forms/validation/validateWorkDetails"
import {
    deleteWorkRow,
    editWorkDetails,
    submitWorkDetails,
} from "../../../../features/auth/authSlice"
import CheckBox from "../../../../components/Inputs/CheckBox"
import { MdDeleteForever } from "react-icons/md"
import { closeModal } from "../../../../features/modal/modalSlice"

const ExperienceForm = ({ work: selectedRow }) => {
    const dispatch = useDispatch()

    const { isLoading } = useSelector((state) => state.auth)

    const handleSubmit = (values, actions) => {
        if (selectedRow) {
            dispatch(editWorkDetails({ values, actions })).then(() =>
                dispatch(closeModal())
            )
        } else {
            dispatch(submitWorkDetails({ values, actions })).then(() =>
                dispatch(closeModal())
            )
        }
    }

    const handleDeleteBtn = (id) => {
        if (window.confirm("Are you sure you want to delete this details?")) {
            dispatch(deleteWorkRow(id))
        }
    }

    const initialValues = {
        job_title: "",
        company: "",
        from: "",
        to: "",
        present: "",
    }

    let editRowValues = {
        job_title: "",
        company: "",
        from: "",
        to: "",
        id: "",
        present: "",
    }

    if (selectedRow) {
        editRowValues = {
            job_title: selectedRow.job_title,
            company: selectedRow.company,
            from: parseISO(`${selectedRow.start}-01-01`),
            to: selectedRow.end ? parseISO(`${selectedRow.end}-01-01`) : "",
            id: selectedRow.id,
            present: selectedRow.present ? true : false,
        }
    }

    return (
        <div>
            <Formik
                initialValues={selectedRow ? editRowValues : initialValues}
                validationSchema={validateWorkDetails}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions)
                }}
            >
                {(formikProps) => (
                    <Form>
                        <TextInput
                            name="job_title"
                            type="text"
                            placeholder="JOB TITLE"
                            color="dark"
                        />
                        <TextInput
                            name="company"
                            type="text"
                            placeholder="COMPANY NAME"
                            color="dark"
                        />
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <div className="w-full">
                                <label>From: </label>
                                <DatePicker
                                    selected={formikProps.values.from}
                                    onChange={(date) =>
                                        formikProps.setFieldValue("from", date)
                                    }
                                    maxDate={new Date()}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    className="text-input"
                                    placeholderText="Click to select year"
                                />
                                {formikProps.touched.from &&
                                formikProps.errors.from ? (
                                    <div className="input-error ">
                                        {formikProps.errors.from}
                                    </div>
                                ) : null}
                            </div>
                            <div className="w-full">
                                <label>To: </label>
                                <DatePicker
                                    selected={formikProps.values.to}
                                    onChange={(date) =>
                                        formikProps.setFieldValue("to", date)
                                    }
                                    maxDate={new Date()}
                                    showYearPicker
                                    dateFormat="yyyy"
                                    className="text-input"
                                    disabled={
                                        formikProps.values.present
                                            ? true
                                            : false
                                    }
                                    placeholderText="Click to select year"
                                />
                                {formikProps.touched.to &&
                                formikProps.errors.to ? (
                                    <div className="input-error ">
                                        {formikProps.errors.to}
                                    </div>
                                ) : null}
                                <div className="mt-4">
                                    <CheckBox name="present" color="black">
                                        I currently work here
                                    </CheckBox>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end mt-8">
                            <div className="flex space-x-4">
                                {selectedRow && selectedRow.id && (
                                    <button
                                        className="text-2xl mr-2 text-red-500 self-center"
                                        type="button"
                                        title="Delete"
                                        onClick={() =>
                                            handleDeleteBtn(selectedRow.id)
                                        }
                                    >
                                        <MdDeleteForever />
                                    </button>
                                )}

                                <button
                                    type="submit"
                                    className="text-white px-6 py-2 submit-btn"
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? (
                                        <div className="inline-flex">
                                            <Loader
                                                size="6"
                                                classes="mr-2 w-6 h-6 fill-blue-200"
                                            />
                                            <div className="self-center">
                                                Please wait...
                                            </div>
                                        </div>
                                    ) : selectedRow ? (
                                        `Update Details`
                                    ) : (
                                        `Add Details`
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ExperienceForm
