import { configureStore } from "@reduxjs/toolkit"

import homepageReducer from "../features/homepage/homepageSlice"
import newsReducer from "../features/news/newsSlice"
import distinguishedAlumnsReducer from "../features/distinguished-alumns/distinguishedAlumnsSlice"
import youngAlumniAwardReducer from "../features/young-alumni-award/youngAlumniAwardSlice"
import alumniAffairsReducer from "../features/alumni-affairs/alumniAffairsSlice"
import distinguishedAlumniServiceAwardReducer from "../features/distinguished-alumni-service-award/distinguishedAlumniServiceAwardSlice"
import techTalkReducer from "../features/tech-talks/techTalkSlice"
import officeBearerReducer from "../features/office-bearers/officeBearerSlice"
import alumniAssociationReducer from "../features/alumni-association/alumniAssociationSlice"
import noticeboardReducer from "../features/noticeboard/noticeboardSlice"
import facilitiesReducer from "../features/facilities/facilitiesSlice"
import newsletterReducer from "../features/newsletters/newsletterSlice"
import recognitionReducer from "../features/recognitions/recognitionSlice"
import alumniDatabaseReducer from "../features/alumni-database/alumniDatabaseSlice"
import classContributionReducer from "../features/class-contribution/classContributionSlice"
import authReducer from "../features/auth/authSlice"
import endowmentTeamReducer from "../features/endowment-team/endowmentTeamSlice"
import endowmentFundReducer from "../features/endowment-fund/endowmentFundSlice"
import byelawsReducer from "../features/byelaws/byelawsSlice"
import chapterpolicyReducer from "../features/chapter-policy/chapterpolicySlice"
import scholarshipsReducer from "../features/scholarships-awards/scholarshipsSlice"
import chairsReducer from "../features/chairs/chairsSlice"
import specialpurposesReducer from "../features/special-purposes/specialpurposesSlice"
import modalReducer from "../features/modal/modalSlice"
import faqReducer from "../features/faq/faqSlice"
import alumniOfTheMonthReducer from "../features/alumni-of-the-month/alumniOfTheMonthSlice"
import configReducer from "../features/config/configSlice"
import degreeCertificateReducer from "../features/degree-certificates/degreeCertificateSlice"
import memoriesReducer from "../features/memories/memoriesSlice"
import eventsReducer from "../features/events/eventsSlice"
import chapterReducer from "../features/chapters/chapterSlice"
import reunionReducer from "../features/reunions/reunionSlice"
import whyDonateUsReducer from "../features/why-donate-us/whyDonateUsSlice"
import alumniDayReducer from "../features/alumni-day/alumniDaySlice"
import newsletterSignupReducer from "../features/newsletter-signup/newsletterSignupSlice"
import policyReducer from "../features/policy-pages/policySlice"
import forumSlice from "../features/forum/forumSlice"

export const store = configureStore({
    reducer: {
        homepage: homepageReducer,
        newspage: newsReducer,
        distinguishedAlumnsPage: distinguishedAlumnsReducer,
        youngAlumniAwardPage: youngAlumniAwardReducer,
        alumniAffairsPage: alumniAffairsReducer,
        distinguishedAlumniServiceAwardPage:
            distinguishedAlumniServiceAwardReducer,
        techTalksPage: techTalkReducer,
        officeBearersPage: officeBearerReducer,
        alumniAssociationPage: alumniAssociationReducer,
        noticeboardPage: noticeboardReducer,
        facilitiesPage: facilitiesReducer,
        newsletterPage: newsletterReducer,
        recognitionsPage: recognitionReducer,
        alumniDatabasePage: alumniDatabaseReducer,
        classContributionPage: classContributionReducer,
        auth: authReducer,
        endowmentTeamPage: endowmentTeamReducer,
        endowmentFundPage: endowmentFundReducer,
        byelawsPage: byelawsReducer,
        chapterpolicyPage: chapterpolicyReducer,
        scholarshipsPage: scholarshipsReducer,
        chairsPage: chairsReducer,
        specialPurposesPage: specialpurposesReducer,
        faqPage: faqReducer,
        alumniOfTheMonthpage: alumniOfTheMonthReducer,
        degreeCertificatePage: degreeCertificateReducer,
        memoriesPage: memoriesReducer,
        eventsPage: eventsReducer,
        chaptersPage: chapterReducer,
        reunionsPage: reunionReducer,
        whyDonateUsPage: whyDonateUsReducer,
        alumniDayPage: alumniDayReducer,
        modal: modalReducer,
        config: configReducer,
        newsletterSignup: newsletterSignupReducer,
        policyPages: policyReducer,
        forum: forumSlice,
    },
})
