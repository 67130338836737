import Slider from "react-slick"
import { useDispatch } from "react-redux"

import {
    fetchAlumniRecords,
    setSelectedYear,
    resetAlumniRecords,
} from "../../features/alumni-database/alumniDatabaseSlice"

const YearCarousel = ({ years, selectedYear }) => {
    const dispatch = useDispatch()

    function handleYearClick(year) {
        dispatch(resetAlumniRecords())
        dispatch(setSelectedYear(year))
        dispatch(fetchAlumniRecords(1))
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
        ],
    }

    return (
        <div className="flex justify-end mt-12">
            <div className="w-full md:w-2/3 overflow-hidden">
                <div className="year-carousel-wrapper">
                    <Slider {...settings} className="alumni-db-year-carousel">
                        {years.map((year) => (
                            <div key={year} className="text-center">
                                <button
                                    className={`text-blue-light text-3xl text-right year-carousel-btn ${
                                        year === selectedYear ? "active" : ""
                                    }`}
                                    onClick={() => handleYearClick(year)}
                                >
                                    {year}
                                </button>
                            </div>
                        ))}
                    </Slider>
                    <div className="year-carousel-underline"></div>
                </div>
            </div>
        </div>
    )
}

export default YearCarousel
