import { api } from "../../api"

//fetch
const fetchNotices = async (limit, page) => {
    const url = `/notices/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch notice details
const fetchNoticeDetails = async (slug) => {
    const url = `/notice-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const officeBearerService = {
    fetchNotices,
    fetchPageContent,
    fetchNoticeDetails,
}

export default officeBearerService
