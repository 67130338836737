import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import degreeCertificateService from "./degreeCertificateService"

const initialState = {
    details: {},
    isLoading: false,
    isError: false,
}

export const fetchDetails = createAsyncThunk(
    "degreeCertificate/fetchDetails",
    async (_, thunkAPI) => {
        try {
            return await degreeCertificateService.fetchDetails()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const degreeCertificateSlice = createSlice({
    name: "degreeCertificate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDetails.fulfilled, (state, action) => {
                state.details = action.payload
                state.isLoading = false
            })
            .addCase(fetchDetails.rejected, (state, action) => {
                state.details = {}
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export default degreeCertificateSlice.reducer
