import React, { useEffect } from "react"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux"

import SectionTitle from "../../components/SectionTitle"
import { staticBaseURI } from "../../config"
import AlumniNewsItem from "../Homepage/AlumniNews/AlumniNewsItem"
import { fetchNews } from "../../features/alumni-affairs/alumniAffairsSlice"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"
import ImageCard from "../../components/Skeletons/ImageCard"

const TopStories = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchNews(8))
    }, [dispatch])

    const { news } = useSelector((state) => state.alumniAffairsPage)

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div className="container mx-auto px-6 mt-16">
            <div className="aaf-top-stories-head">
                <h2 className="uppercase font-bold text-4xl lg:text-6xl underline decoration-blue-400 underline-offset-8 text-white">
                    Top Stories
                </h2>
            </div>
            <div className="container mx-auto aaf-top-stories-bg py-6 px-0 md:px-6 mt-8 relative rounded-2xl">
                <div className="px-4 sm:px-0">
                    <SectionTitle
                        title="Alumni News"
                        button={true}
                        link={`${staticBaseURI}/news`}
                    />
                </div>
                <div className="alumni-news-wrapper mt-8 relative -mb-14 -mx-10 md:-mx-0">
                    <Slider {...settings}>
                        {news.length < 1
                            ? [...Array(4)].map((i) => <ImageCard key={i} />)
                            : news.map((item, index) => (
                                  <AlumniNewsItem
                                      key={item.slug}
                                      index={index}
                                      image={item.image_url}
                                      title={item.title}
                                      slug={item.slug}
                                  />
                              ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default TopStories
