import { apiBaseURI } from "../../config"
import GuestOfHonourItem from "./GuestOfHonourItem"

const GuestOfHonour = ({ guest_name, guest_image, guest_info }) => {
    return (
        <div className="mt-12">
            <h2 className="uppercase text-2xl md:text-4xl lg:text-5xl text-white border-b-2 border-b-white pb-4">
                Guest of Honour
            </h2>
            <div className="mt-8">
                <GuestOfHonourItem
                    name={guest_name}
                    image={`${apiBaseURI}${guest_image}`}
                    designation={guest_info}
                />
            </div>
        </div>
    )
}

export default GuestOfHonour
