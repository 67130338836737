import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import "./awards-page.css"
import About from "./About"
import AwardWinners from "./AwardWinners"
import WinnersPagination from "./WinnersPagination"
import { fetchPageContent } from "../../features/distinguished-alumns/distinguishedAlumnsSlice"
import { apiBaseURI } from "../../config"

const Awards = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector(
        (state) => state.distinguishedAlumnsPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("distinguished-alumni-award"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                title="Distinguished Alumni Awards"
                image={`${apiBaseURI}${pageContent.image_url}`}
                position="left"
            />
            <About about={pageContent.content} />
            <AwardWinners />
            <WinnersPagination />
        </>
    )
}

export default Awards
