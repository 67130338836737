import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
    staticBaseURI,
} from "../../config"

const ScholarshipItem = ({ title, image, slug }) => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div ref={ref}>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                }}
                className="alumni-news-item m-2"
            >
                <div className="rounded-2xl overflow-hidden shadow-md">
                    <a href={`${staticBaseURI}/scholarship-details/${slug}`}>
                        <img
                            className="w-full"
                            src={`${apiBaseURI}${image}`}
                            alt={title}
                        />
                        <div className=" border-gray-300 border bg-white">
                            <div className="px-6 py-4">
                                <div className="font-bold xs:text-2xl sm:text-2xl mb-2 h-20 md:h-16">
                                    {title}
                                </div>
                            </div>
                            <div className="px-6 pt-4 pb-8">
                                <button className="font-bold xs:text-base sm:text-lg">
                                    Read More &gt;
                                </button>
                            </div>
                        </div>
                    </a>
                </div>
            </motion.div>
        </div>
    )
}

export default ScholarshipItem
