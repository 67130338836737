import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchForumPosts } from "../../../../features/forum/forumSlice"
import PostSkeleton from "./PostSkeleton"
import PostItem from "./PostItem"
import NoPosts from "./NoPosts"

const PostsList = () => {
    const dispatch = useDispatch()

    const {
        postsLoading,
        selectedCategory,
        currentPage,
        lastPage,
        perPage,
        posts,
    } = useSelector((state) => state.forum)

    useEffect(() => {
        dispatch(
            fetchForumPosts({
                limit: perPage,
                category: selectedCategory,
                page: currentPage,
            })
        )
    }, [dispatch, selectedCategory, currentPage, perPage])

    return (
        <>
            {postsLoading && posts.length < 1 ? (
                [...Array(6)].map((_, i) => <PostSkeleton key={i} />)
            ) : !postsLoading && posts.length > 0 ? (
                posts.map((post) => (
                    <PostItem
                        title={post.title}
                        description={post.short_content}
                        time={post.api_date}
                        user={post.member}
                        comments={post.comments_count}
                        slug={post.uuid}
                        key={post.uuid}
                    />
                ))
            ) : (
                <NoPosts />
            )}
        </>
    )
}

export default PostsList
