import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import newsletterSignupService from "./newsletterSignupService"

const initialState = {
    isSubmitted: false,
    isError: false,
    isLoading: false,
}

export const newsletterSignup = createAsyncThunk(
    "newsletter-signup/newsletterSignup",
    async (email, thunkAPI) => {
        try {
            return await newsletterSignupService.newsletterSignup(email)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const newsletterSignupSlice = createSlice({
    name: "newsletterSignup",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(newsletterSignup.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newsletterSignup.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.isSubmitted = true
                state.errorMessage = ""
            })
            .addCase(newsletterSignup.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.isSubmitted = false
                state.errorMessage = action.payload
            })
    },
})

export default newsletterSignupSlice.reducer
