import Slider from "react-slick"
import { useDispatch } from "react-redux"
import {
    fetchClassDetails,
    setSelectedYear,
} from "../../features/class-contribution/classContributionSlice"

const YearCarousel = ({ years, selectedYear }) => {
    const dispatch = useDispatch()

    function handleYearClick(year) {
        dispatch(setSelectedYear(year))
        dispatch(fetchClassDetails(year))
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    }

    return (
        <div className="mt-12">
            <div className="w-full relative">
                <div className="year-carousel-wrapper ">
                    <Slider {...settings} style={{ overflow: "inherit" }}>
                        {years.map((year) => (
                            <div key={year} className="text-center relative">
                                <button
                                    className={` year-carousel-btn font-bold ${
                                        year === selectedYear
                                            ? "class-contribution-header"
                                            : "text-white"
                                    }`}
                                    onClick={() => handleYearClick(year)}
                                >
                                    <h4 className="uppercase text-xl md:text-2xl">
                                        Class
                                    </h4>
                                    <h3 className="text-2xl md:text-3xl">
                                        {year}
                                    </h3>
                                </button>
                                {year === selectedYear && (
                                    <div className="rounded-full h-8 w-8 bg-blue-light shadow-lg relative left-[50%] -translate-x-[50%] -bottom-[2.5rem]"></div>
                                )}
                            </div>
                        ))}
                    </Slider>
                    <div className="contribution-year-carousel-underline mt-4"></div>
                </div>
            </div>
        </div>
    )
}

export default YearCarousel
