import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch faq
const fetchFaq = async () => {
    const url = `/faq`
    const response = await api.get(url)
    return response.data
}

const faqService = {
    fetchPageContent,
    fetchFaq,
}

export default faqService
