import React from "react"

const About = () => {
    return (
        <section className="bg-black px-4 pt-10 pb-40 md:pb-48 lg:pb-60 facilities-about">
            <div className="container mx-auto px-6">
                <h1 className="font-bold text-3xl md:text-5xl lg:text-7xl">
                    Institute
                </h1>
                <h1 className="font-bold text-3xl md:text-5xl lg:text-7xl">
                    Facilties
                </h1>
            </div>
        </section>
    )
}

export default About
