const About = ({ image, name, degreeYear, award_year }) => {
    return (
        <section className="bg-blue-semi-dark relative">
            <div className="container mx-auto px-4 md:px-10 pb-10">
                <div className="bg-text top-[25%] xl:top-[38%] left-4 lg:left-20 xl:left-4 -translate-y-1/2">
                    About
                </div>
                <div className="grid grid-cols-5 md:grid-cols-4 gap-4 md:gap-8">
                    <div className="col-span-2 md:col-span-1 -mt-5 md:-mt-10 lg:-mt-20 z-10">
                        <div className="dap-about-img-wrapper ">
                            <img
                                src={image}
                                alt={name}
                                className="rounded-full ring-offset-2"
                            />
                        </div>
                    </div>
                    <div className="col-span-3 md:col-span-3 pt-4 lg:pt-6 xl:pt-10 text-white">
                        <h1 className="md:text-4xl lg:text-6xl font-bold mb-2 xl:mb-4">
                            {name}
                        </h1>
                        <h3 className=" text-sm md:text-xl lg:text-2xl font-bold">
                            Batch: {degreeYear}
                        </h3>
                        <h3 className="text-sm md:text-lg lg:text-xl font-bold">
                            Award Year: {award_year}
                        </h3>
                    </div>
                </div>
                {/* <div className="text-white font-bold text-base md:text-xl mt-12">
                    Aliquam pulvinar ipsum sit amet venenatis hendrerit. Mauris
                    congue blandit dolor a rhoncus. Fusce vel tincidunt odio.
                    Quisque maximus nibh dui, in malesuada justo pellentesque
                    vel.
                </div> */}
            </div>
        </section>
    )
}

export default About
