import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch chairs
const fetchChairs = async (limit, page) => {
    const url = `/chairs/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch chair details
const fetchChairDetails = async (slug) => {
    const url = `/chair-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const chairsService = {
    fetchChairs,
    fetchPageContent,
    fetchChairDetails,
}

export default chairsService
