import React, { useEffect } from "react"
import { HiArrowRight } from "react-icons/hi"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { framerAnimationFinal, framerAnimationInitial } from "../../config"
import { useDispatch, useSelector } from "react-redux"
import { fetchBankTransferDetails } from "../../features/config/configSlice"

const TransferMethods = () => {
    const { inView, ref } = useInView()
    const controls = useAnimation()
    const dispatch = useDispatch()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    const { bankTransferDetails } = useSelector((state) => state.config)

    useEffect(() => {
        //toggleTabs()

        dispatch(fetchBankTransferDetails())
    }, [dispatch])

    toggleTabs()

    function toggleTabs() {
        let tabs = document.querySelectorAll(
            ".htd-transfer-methods-tabs > .tab-item"
        )

        for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i]
            tab.addEventListener("click", function () {
                const selector = this.dataset.activate
                if (selector) {
                    const element = document.querySelector(selector)
                    hideOtherContentAreas()
                    deactivateOtherTabs()
                    this.classList.add("active")
                    element.classList.toggle("active")
                }
            })
        }
    }

    function deactivateOtherTabs() {
        let tabs = document.querySelectorAll(
            ".htd-transfer-methods-tabs > .tab-item"
        )

        for (let i = 0; i < tabs.length; i++) {
            const tab = tabs[i]
            tab.classList.remove("active")
        }
    }

    function hideOtherContentAreas() {
        const contentAreas = document.querySelectorAll(
            ".htd-transfer-info-bg > .tab-info"
        )
        for (let i = 0; i < contentAreas.length; i++) {
            const contentArea = contentAreas[i]
            contentArea.classList.remove("active")
        }
    }

    return (
        <section className="bg-dark-blue-newsletter px-4 py-16">
            <div className="container mx-auto px-0 md:px-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <div>
                            <h2 className="text-white text-4xl font-semibold">
                                Wire Transfer Details
                            </h2>
                            <h3 className="text-white text-xl flex mt-2">
                                Incoming Wires from Donors
                                <div className="text-white mt-1 ml-2 text-3xl">
                                    <HiArrowRight />
                                </div>
                            </h3>
                        </div>
                        <div className="htd-transfer-methods-tabs mt-8 grid grid-cols-2 md:grid-cols-1 md:gap-0 gap-4">
                            <div
                                className="tab-item active"
                                data-activate="#neft"
                            >
                                NEFT | Wire Transfer
                            </div>
                            <div className="tab-item" data-activate="#cheque">
                                Cheque Transfer
                            </div>
                        </div>
                    </div>
                    <motion.div
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                        }}
                        className="relative mt-0 md:-mt-28 htd-transfer-info-bg p-4 md:p-6 lg:p-8 rounded-2xl"
                        ref={ref}
                    >
                        <div className="tab-info active" id="neft">
                            <div className="">
                                <h3 className="font-bold text-2xl md:text-3xl lg:text-4xl uppercase">
                                    NEFT | Wire Transfer
                                </h3>
                                <h4 className="text-xl uppercase">
                                    Rajiv Gandhi University
                                </h4>
                            </div>
                            <div className="mt-8 payment-methods-info">
                                <table className="transfer-info-table">
                                    <tbody>
                                        {bankTransferDetails.bank_transfer &&
                                            bankTransferDetails.bank_transfer.map(
                                                (item) => (
                                                    <tr>
                                                        <td>
                                                            {item.item_name}
                                                        </td>
                                                        <td>
                                                            {item.item_value}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-info" id="cheque">
                            <div className="">
                                <h3 className="font-bold text-2xl md:text-3xl lg:text-4xl uppercase">
                                    Cheque Transfer
                                </h3>
                                <h4 className="text-xl uppercase">
                                    Rajiv Gandhi University
                                </h4>
                            </div>
                            <div className="mt-8 payment-methods-info">
                                <table className="transfer-info-table">
                                    <tbody>
                                        {bankTransferDetails.cheque &&
                                            bankTransferDetails.cheque.map(
                                                (item) => (
                                                    <tr>
                                                        <td>
                                                            {item.item_name}
                                                        </td>
                                                        <td>
                                                            {item.item_value}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

export default TransferMethods
