import { api } from "../../api"

//fetch
const fetchDetails = async () => {
    const url = `/degree-certificates`
    const response = await api.get(url)
    return response.data
}

const degreeCertificateService = {
    fetchDetails,
}

export default degreeCertificateService
