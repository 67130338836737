import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"

import Textarea from "../../../../components/Inputs/Textarea"
import Loader from "../../../../components/Loader/Loader"
import { updateAbout } from "../../../../features/auth/authSlice"

const UpdateAbout = ({ content }) => {
    const dispatch = useDispatch()

    const validate = (values) => {
        let errors = {}

        if (!values.data) {
            errors.data = "Please enter about"
        }

        return errors
    }

    const { isLoading } = useSelector((state) => state.auth)

    return (
        <div className="w-full">
            <Formik
                initialValues={{ data: content }}
                onSubmit={(values, actions) => {
                    dispatch(updateAbout(values))
                }}
                validate={validate}
            >
                {(props) => (
                    <Form>
                        <Textarea
                            name="data"
                            placeholder="About"
                            color="dark"
                            value={content ? content : ""}
                        />
                        <div className="flex justify-end">
                            <button
                                className="bg-blue-900 px-6 py-3 text-white text-sm"
                                type="submit"
                                disabled={isLoading ? true : false}
                            >
                                {isLoading ? (
                                    <div className="inline-flex">
                                        <Loader
                                            size="6"
                                            classes="mr-2 w-6 h-6"
                                        />
                                        <div className="self-center">
                                            Please wait...
                                        </div>
                                    </div>
                                ) : (
                                    `Save`
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default UpdateAbout
