import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux"

import { scrollTop } from "../../config"
import { setCurrentPage } from "../../features/office-bearers/officeBearerSlice"

const Pagination = () => {
    const dispatch = useDispatch()

    const { currentPage, lastPage } = useSelector(
        (state) => state.officeBearersPage
    )

    const paginationBtnHandle = (page) => {
        const elem = document
            .querySelector("#winners-list")
            .getBoundingClientRect()

        scrollTop(elem.x, Math.abs(elem.y))
        dispatch(setCurrentPage(page))
    }

    const paginationLinks = []
    for (let i = 1; i <= lastPage; i++) {
        const isActive = i === currentPage ? "active" : ""

        paginationLinks.push(
            <button
                key={i}
                type="button"
                onClick={() => paginationBtnHandle(i)}
                className={isActive}
            >
                {i}
            </button>
        )
    }

    return (
        <section className="bg-blue-dark px-4 py-8 -mt-4 lg:mt-0">
            <div className="container mx-auto">
                <div className="flex justify-end">
                    <div className="relative w-full md:w-3/4 ">
                        <div className="flex justify-between mr-0 md:mr-12 mt-0 md:mt-10">
                            <button
                                className="flex text-white text-2xl"
                                onClick={() =>
                                    paginationBtnHandle(currentPage - 1)
                                }
                                disabled={currentPage > 1 ? false : true}
                            >
                                <span className="mr-1 mt-[0.4rem]">
                                    <FiChevronsLeft />
                                </span>
                                Previous
                            </button>
                            <button
                                className="flex text-white text-2xl"
                                onClick={() =>
                                    paginationBtnHandle(currentPage + 1)
                                }
                                disabled={currentPage < lastPage ? false : true}
                            >
                                Next
                                <span className="ml-1 mt-[0.4rem]">
                                    <FiChevronsRight />
                                </span>
                            </button>
                        </div>
                        <div className="flex justify-end mr-0 md:mr-12 pagination-buttons">
                            {paginationLinks}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pagination
