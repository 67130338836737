import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"

import CheckBox from "../../../components/Inputs/CheckBox"
import TextInput from "../../../components/Inputs/TextInput"
import { signUp, sendOTP } from "../../../features/auth/authSlice"
import Toastify from "../../../components/Notification/Toastify"
import Loader from "../../../components/Loader/Loader"

const RegisterForm = () => {
    const dispatch = useDispatch()

    const { otpSent, otpLoading } = useSelector((state) => state.auth)

    function handleSubmit(values, actions) {
        if (otpSent && values.otp) {
            dispatch(signUp({ values, actions }))
        } else if (!otpSent) {
            actions.setSubmitting(false)
            actions.setFieldTouched("mobile", true, false)
            actions.setFieldError("mobile", "Please click on Send OTP button")
        } else if (otpSent && !values.otp) {
            actions.setFieldError("otp", "Please enter OTP")
            actions.setFieldTouched("otp", true, false)
            actions.setSubmitting(false)
        }
    }

    function handleSendOTP({
        values,
        setFieldError,
        setFieldTouched,
        setFieldValue,
    }) {
        if (!values.mobile) {
            setFieldError("mobile", "Please enter mobile number")
            setFieldTouched("mobile", true, true)
        } else if (values.mobile && values.mobile.toString().length === 10) {
            dispatch(sendOTP(values.mobile))
            setFieldValue("otp", "")
        }
    }

    const RegisterSchema = Yup.object({
        full_name: Yup.string().required("Please enter your name"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Please enter your email"),
        mobile: Yup.string()
            .min(10, "Invalid mobile number")
            .max(10, "Invalid mobile number")
            .required("Please enter mobile number"),
        password: Yup.string().required("Please enter password"),
        otp: otpSent
            ? Yup.string().required("Please enter OTP")
            : Yup.string().nullable(),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords don't match!")
            .required("Please confirm your password"),
        acceptedTerms: Yup.boolean()
            .required("Required")
            .oneOf([true], "You must accept the terms and conditions."),
    })

    return (
        <>
            <div>
                <Formik
                    initialValues={{
                        full_name: "",
                        mobile: "",
                        email: "",
                        acceptedTerms: false,
                        password: "",
                        password_confirmation: "",
                        otp: "",
                    }}
                    validationSchema={RegisterSchema}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        handleSubmit(values, actions)
                    }}
                >
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <TextInput
                                name="full_name"
                                type="text"
                                placeholder="NAME"
                            />
                            <TextInput
                                name="email"
                                type="email"
                                placeholder="EMAIL"
                            />
                            <div className="flex w-full">
                                <div className="flex-1">
                                    <TextInput
                                        name="mobile"
                                        type="number"
                                        placeholder="MOBILE"
                                    />
                                </div>
                                <div className="ml-2">
                                    <button
                                        type="button"
                                        className="bg-white px-4 md:px-2 lg:px-4 py-1 text-md appearance-none mt-3 font-semibold"
                                        onClick={() =>
                                            handleSendOTP(formikProps)
                                        }
                                        disabled={otpLoading ? true : false}
                                    >
                                        {otpLoading ? (
                                            <div className="inline-flex">
                                                <Loader
                                                    size="4"
                                                    classes="mr-1 -mt-1"
                                                />
                                                Sending...
                                            </div>
                                        ) : (
                                            "Send OTP"
                                        )}
                                    </button>
                                </div>
                            </div>
                            {otpSent && (
                                <TextInput
                                    name="otp"
                                    type="number"
                                    placeholder="OTP"
                                />
                            )}
                            <TextInput
                                name="password"
                                type="password"
                                placeholder="PASSWORD"
                            />
                            <TextInput
                                name="password_confirmation"
                                type="password"
                                placeholder="CONFIRM PASSWORD"
                            />
                            <CheckBox name="acceptedTerms">
                                By singing up, you agree to our terms and
                                conditions and privacy policy
                            </CheckBox>
                            <button
                                className="bg-white px-8 py-2 text-xl text-center uppercase font-bold mt-6"
                                type="submit"
                                disabled={
                                    formikProps.isSubmitting ? true : false
                                }
                            >
                                {formikProps.isSubmitting ? (
                                    <div className="inline-flex">
                                        <Loader size="6" classes="mr-1 -mt-1" />
                                        Loading...
                                    </div>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
            <Toastify />
        </>
    )
}

export default RegisterForm
