import "./login.css"
import LoginFormWrapper from "./LoginFormWrapper"
import SocialLinks from "../Register/SocialLinks"

const Login = () => {
    return (
        <>
            {/* <div className="bg-dark-blue-newsletter py-6"></div> */}
            <section className="bg-black px-4 py-16">
                <div className="container mx-auto px-6">
                    <h1 className="register-gradient-text font-bold text-3xl md:text-5xl lg:text-6xl">
                        Re-Connect
                    </h1>
                    <h3 className="text-white mt-2 text-lg md:text-xl">
                        The Private Alumni Network for Alumni, Students &amp;
                        Faculty of RGU
                    </h3>
                </div>
            </section>
            <LoginFormWrapper />
            <SocialLinks />
        </>
    )
}

export default Login
