import React, { Component } from "react"
import { FiArrowRight } from "react-icons/fi"

class KnowMore extends Component {
    render() {
        return (
            <section className="bg-blue-dark pt-12 relative">
                <div className="container mx-auto px-10">
                    <div className="wd-know-more-head flex text-white font-semidbold md:text-3xl lg:text-4xl">
                        <div>Know more where does the donation go to</div>
                        <span className="mt-2 ml-[0.7rem]">
                            <FiArrowRight />
                        </span>
                    </div>
                    <div className="flex justify-center mt-12">
                        <div className="relative">
                            <img
                                src="./dists/images/video-img.jpg"
                                alt="video"
                                className="rounded-3xl"
                            />
                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                <img
                                    src="./dists/images/video-play.png"
                                    alt="video play"
                                    className="w-16 md:w-32"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="gradient-bg-contact h-12 -mt-10"></div>
            </section>
        )
    }
}

export default KnowMore
