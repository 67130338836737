import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as DOMPurify from "dompurify"
import { Helmet } from "react-helmet-async"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import {
    fetchDonors,
    fetchPageContent,
} from "../../features/endowment-fund/endowmentFundSlice"
import "./give.css"
import HowToMakeDonation from "./HowToMakeDonation"
import TransferMethods from "../HowToDonate/TransferMethods"
import WallOfDonors from "./WallOfDonors"
import WhyGiveRgu from "./WhyGiveRgu"
import DonateForm from "../HowToDonate/DonateForm"

const Give = () => {
    const dispatch = useDispatch()

    const { pageContent, donors } = useSelector(
        (state) => state.endowmentFundPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("give"))
        dispatch(fetchDonors(16))
    }, [dispatch])

    let cleanContent = DOMPurify.sanitize(pageContent.content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <>
            <Helmet>
                <title>Give</title>
                <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
            </Helmet>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <section className="px-4">
                <div className="container mx-auto px-6 relative -mt-24 md:-mt-32">
                    <h1 className="font-bold text-4xl md:text-6xl htd-page-heading">
                        Donate!
                    </h1>
                </div>
            </section>
            <section className="px-4 pb-16 pt-10 htd-white-blue-gradient">
                <div className="px-6">
                    <DonateForm form_type="give" />
                </div>
                <div className="container mx-auto px-6">
                    <div className="htd-online-giving mt-10">
                        <div className="mb-4">
                            <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl uppercase">
                                Online Giving
                            </h1>
                            <div className="htd-gradient-underline h-2 w-[50%] md:w-[30%] mt-2"></div>
                        </div>
                        <div
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
                <HowToMakeDonation />
            </section>
            <WhyGiveRgu content={pageContent.content_2} />
            <WallOfDonors donors={donors} />
            <TransferMethods />
        </>
    )
}

export default Give
