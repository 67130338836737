import { FaEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { useDispatch } from "react-redux"
import { deleteWorkRow } from "../../../../features/auth/authSlice"

const WorkItem = ({
    company,
    job_title,
    from,
    to,
    id,
    isEdit,
    editRow,
    isPresent,
}) => {
    const handleEditBtn = (id) => {
        isEdit(true)
        editRow(id)
    }

    const dispatch = useDispatch()

    const handleDeleteBtn = (id) => {
        if (window.confirm("Are you sure you want to delete this details?")) {
            dispatch(deleteWorkRow(id))
        }
    }

    return (
        <div className="border-2 border-gray-300 p-4 rounded-lg mb-2 flex justify-between">
            <div className="flex-1">
                <h1 className="font-bold text-2xl">{job_title}</h1>
                <h3 className="font-semibold text-lg">{company}</h3>
                <h5 className="text-base">{`${from} - ${
                    isPresent ? "Present" : to
                }`}</h5>
            </div>
            <div className="shrink-0 self-center ml-4 text-blue-600">
                <button className="text-xl" onClick={() => handleEditBtn(id)}>
                    <FaEdit />
                </button>
                <button
                    className="text-2xl ml-2 text-red-500"
                    onClick={() => handleDeleteBtn(id)}
                >
                    <MdDeleteForever />
                </button>
            </div>
        </div>
    )
}

export default WorkItem
