import { apiBaseURI, staticBaseURI } from "../../config"

const OngoingItem = ({ image, title, slug }) => {
    return (
        <div className="ongoing-talk-item mx-4">
            <a href={`${staticBaseURI}/tech-talk-details/${slug}`}>
                <img src={`${apiBaseURI}${image}`} alt={title} />
            </a>
        </div>
    )
}

export default OngoingItem
