import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import endowmentTeamService from "./endowmentTeamService"

const initialState = {
    studentTeam: [],
    mainTeam: [],
    accountsTeam: [],
    isLoading: false,
    isError: false,
    pageContent: {},
}

export const fetchPageContent = createAsyncThunk(
    "endowmentTeam/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await endowmentTeamService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchTeamMembers = createAsyncThunk(
    "endowmentTeam/fetchTeamMembers",
    async (limit, thunkAPI) => {
        try {
            return await endowmentTeamService.fetchTeamMembers(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const endowmentTeamSlice = createSlice({
    name: "endowmentTeam",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamMembers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTeamMembers.fulfilled, (state, action) => {
                state.isLoading = false
                state.mainTeam = action.payload.main_team
                state.accountsTeam = action.payload.accounts_team
                state.studentTeam = action.payload.student_team
            })
            .addCase(fetchTeamMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.mainTeam = []
                state.accountsTeam = []
                state.studentTeam = []
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
    },
})

export default endowmentTeamSlice.reducer
