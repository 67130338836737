import { FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import { openModal } from "../../../../features/modal/modalSlice"
import ProjectForm from "./ProjectForm"

const ProjectItem = ({ project }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="mb-4 flex justify-between">
                <div className="flex-1">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <h2 className="font-semibold text-base md:text-xl">
                            {project.title}
                        </h2>
                    </div>
                    <p className="text-sm md:text-base text-gray-600">
                        {project.description}
                    </p>
                </div>
                <div className="flex-0 self-center ml-3">
                    <button className="text-2xl text-blue-dark">
                        <FaRegEdit
                            className="text-2xl"
                            onClick={() =>
                                dispatch(openModal(`editProject${project.id}`))
                            }
                        />
                    </button>
                </div>
            </div>

            {isOpen && modalName === `editProject${project.id}` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Edit Project</h3>
                        <hr className="my-4" />
                        <ProjectForm project={project} />
                    </>
                </Modal>
            )}
        </>
    )
}

export default ProjectItem
