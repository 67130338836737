import { api } from "../../api"

//fetch
const fetchBearers = async (limit, page) => {
    const url = `/office-bearers/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

const officeBearerService = {
    fetchBearers,
    fetchPageContent,
}

export default officeBearerService
