import * as DOMPurify from "dompurify"

const About = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="bg-black px-4 py-16 relative overflow-hidden">
            <div className="container mx-auto px-6">
                <div className="flex justify-end ">
                    <div className="bg-text scholarships-bg-text left-0 md:left-8 lg:top-[20%] top-[40%]">
                        Chapters
                    </div>
                    <div className="text-white w-full md:w-2/3 text-xl">
                        <div className="flex justify-end mb-8">
                            <a
                                href="#0"
                                className="read-more-btn py-2 flex self-center w-fit"
                            >
                                <span className="light-blue-dot rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
                                Read more
                            </a>
                        </div>
                        <div
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
