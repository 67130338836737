import Slider from "react-slick"
import { NextArrow, PrevArrow } from "../../components/CustomArrows"
import { staticBaseURI } from "../../config"
import TalkItem from "./TalkItem"

const UpcomingTalks = ({ talks }) => {
    const settings = {
        dots: false,
        infinite: talks.lenght >= 3 ? true : false,
        arrows: true,
        className: "center",
        centerMode: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    className: "center",
                    centerMode: true,
                    infinite: true,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div className="mt-10 md:px-10 border-b-4 pb-16 tt-blue-border-bottom">
            <div className="flex justify-end px-4 md:px-0">
                <div className="flex self-center text-blue-light">
                    <span className="light-blue-dot bg-blue-light rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
                    <a
                        href={`${staticBaseURI}/all-tech-talks`}
                        className="font-bold text-base md:text-xl"
                    >
                        View all
                    </a>
                </div>
            </div>
            <div className="flex justify-end px-4 md:px-0">
                <h3 className="uppercase font-bold text-3xl text-white">
                    Upcoming talks
                </h3>
            </div>
            <div className="mt-10">
                <Slider {...settings}>
                    {talks.map((talk) => (
                        <TalkItem
                            key={talk.id}
                            title={talk.title}
                            image={talk.image_url}
                            date={talk.formatted_event_date}
                            time={talk.event_time}
                            slug={talk.slug}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default UpcomingTalks
