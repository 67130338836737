import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import SpecialPurposeDetailsBody from "./SpecialPurposeDetailsBody"
import { fetchPurposeDetails } from "../../features/special-purposes/specialpurposesSlice"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"

const SpecialPurposeDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { purposeDetails } = useSelector((state) => state.specialPurposesPage)

    useEffect(() => {
        dispatch(fetchPurposeDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{purposeDetails.meta_title}</title>
                <meta
                    name="description"
                    content={purposeDetails.meta_description}
                />
                <meta name="keywords" content={purposeDetails.meta_keywords} />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${purposeDetails.image_url}`}
            />
            <SpecialPurposeDetailsBody
                content={purposeDetails.description}
                title={purposeDetails.title}
            />
        </>
    )
}

export default SpecialPurposeDetails
