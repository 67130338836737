import * as DOMPurify from "dompurify"

const About = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="relative bg-dark-blue-newsletter px-4 py-8">
            <div className="container mx-auto p-6 px-4">
                <div className="flex justify-end mt-10">
                    <div className="bg-text top-2/4 left-8 -translate-y-1/2 newsletter-bg-text">
                        newsletter
                    </div>
                    <div className="text-white w-full md:w-2/3 text-xl">
                        <div
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
