import { apiBaseURI, staticBaseURI } from "../../config"

const NewsLetterItem = ({ image, title, slug }) => {
    return (
        <div className="newsletter-item">
            <a href={`${staticBaseURI}/newsletter-details/${slug}`}>
                <img src={`${apiBaseURI}${image}`} alt={title} />
                <h2 className="text-white text-center text-xl mt-4">{title}</h2>
            </a>
        </div>
    )
}

export default NewsLetterItem
