import * as DOMPurify from "dompurify"

const MessageFromPresident = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }
    return (
        <section className="px-4 pb-16 pt-10">
            <div className="container mx-auto px-6">
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-4 md:col-span-1 mb-4 md:mb-0">
                        <div className="aa-img-wrapper md:-mt-28 lg:-mt-32">
                            <img
                                src="./dists/images/president.jpg"
                                className="rounded-full ring-offset-2"
                                alt="president"
                            />
                        </div>
                        <div className="text-center">
                            <h3 className="text-black text-2xl md:text-3xl font-semibold mt-4">
                                Mr. Kalpen Shukla
                            </h3>
                        </div>
                    </div>
                    <div className="col-span-4 md:col-span-3">
                        <h1 className="font-bold lg:text-4xl md:text-3xl text-2xl uppercase">
                            Message from President
                        </h1>
                        <div className="aa-message-underline"></div>
                        <div
                            className="mt-8 text-xl"
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MessageFromPresident
