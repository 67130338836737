import Slider from "react-slick"
import VideoItem from "./VideoItem"

const Videos = () => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark px-4 py-20">
            <div className="container mx-auto px-6">
                <h1 className="text-white font-semibold text-2xl md:text-3xl lg:text-4xl">
                    What Recognition Board has to say?
                </h1>
                <div className="mt-12 -mx-10 md:mx-0">
                    <Slider {...settings}>
                        <VideoItem
                            image="/dists/images/recog-v-1.jpg"
                            title="video 1"
                        />
                        <VideoItem
                            image="/dists/images/recog-v-2.jpg"
                            title="video 2"
                        />
                        <VideoItem
                            image="/dists/images/recog-v-1.jpg"
                            title="video 3"
                        />
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Videos
