import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"

import Textarea from "../../../../components/Inputs/Textarea"
import Loader from "../../../../components/Loader/Loader"
import { createPostComment } from "../../../../features/forum/forumSlice"

const NewCommentForm = () => {
    const dispatch = useDispatch()

    const { isLoading, postDetails } = useSelector((state) => state.forum)

    const validate = (values) => {
        let errors = {}

        if (!values.comment) {
            errors.comment = "Please enter your comment"
        }

        return errors
    }

    return (
        <div className="w-full">
            <Formik
                initialValues={{ comment: "", post_id: postDetails.id }}
                onSubmit={(values, actions) => {
                    dispatch(createPostComment({ values, actions }))
                }}
                validate={validate}
            >
                {(props) => (
                    <Form>
                        <Textarea
                            name="comment"
                            placeholder="Enter your comment here..."
                            color="dark"
                            rows="3"
                        />
                        <div className="flex justify-end">
                            <button
                                className="bg-blue-900 px-6 py-3 text-white text-sm"
                                type="submit"
                                disabled={isLoading ? true : false}
                            >
                                {isLoading ? (
                                    <div className="inline-flex">
                                        <Loader
                                            size="6"
                                            classes="mr-2 w-6 h-6"
                                        />
                                        <div className="self-center">
                                            Please wait...
                                        </div>
                                    </div>
                                ) : (
                                    `Post Comment`
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default NewCommentForm
