import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { HelmetProvider } from "react-helmet-async"
import loadable from "@loadable/component"

import "./styles/custom.css"

import NotFound from "./pages/NotFound"
import Header from "./components/Header/Header"
//import Header from './components/HeaderNew/Header'
import Footer from "./components/Footer/Footer"
import Awards from "./pages/Awards/Awards"
//import YoungAlumniAwards from "./pages/YoungAlumniAwards/YoungAlumniAwards"
import YoungAlumniPage from "./pages/YoungAlumniPage/YoungAlumniPage"
import AlumniNews from "./pages/AlumniNews/AlumniNews"
import EndowmentFund from "./pages/EndowmentFund/EndowmentFund"
import AlumniAssociation from "./pages/AlumniAssociation/AlumniAssociation"
import TechTalks from "./pages/TechTalks/TechTalks"
import WhyDonate from "./pages/WhyDonate/WhyDonate"
import ServiceAlumniAward from "./pages/ServiceAlumniAward/ServiceAlumniAward"
import TechTalkDetails from "./pages/TechTalkDetails/TechTalkDetails"
import Team from "./pages/EFTeam/Team"
import NoticeBoard from "./pages/NoticeBoard/NoticeBoard"
import NewsDetails from "./pages/AlumniNews/NewsDetails"
import DistinguishedAlumniPage from "./pages/DistinguishedAlumniPage/DistinguishedAlumniPage"
import ByLaws from "./pages/ByLaws/ByLaws"
import OfficeBearers from "./pages/OfficeBearers/OfficeBearers"
import AlumniAffairs from "./pages/AlumniAffairs/AlumniAffairs"
import HowToDonate from "./pages/HowToDonate/HowToDonate"
import Give from "./pages/Give/Give"
import Facilities from "./pages/Facilities/Facilities"
import NewsLetterArchives from "./pages/NewsLetters/NewsLetterArchives"
import Newsletters from "./pages/NewsLetters/Newsletters"
import Recognition from "./pages/Recognition/Recognition"
import AlumniDatabase from "./pages/AlumniDatabase/AlumniDatabase"
import ClassContribution from "./pages/ClassContribution/ClassContribution"
import Register from "./pages/Auth/Register/Register"
import NoticeDetails from "./pages/NoticeBoard/NoticeDetails"
import ChapterPolicy from "./pages/ChapterPolicy/ChapterPolicy"
import ScholarshipsAwards from "./pages/ScholarshipsAwards/ScholarshipsAwards"
import Chairs from "./pages/Chairs/Chairs"
import SpecialPurposes from "./pages/SpecialPurposes/SpecialPurposes"
import ScholarshipDetails from "./pages/ScholarshipsAwards/ScholarshipDetails"
import ChairDetails from "./pages/Chairs/ChairDetails"
import SpecialPurposeDetails from "./pages/SpecialPurposes/SpecialPurposeDetails"
import Faq from "./pages/Faq/Faq"
import AlumniOfTheMonth from "./pages/AlumniOfTheMonth/AlumniOfTheMonth"
import DegreeCertificates from "./pages/DegreeCertificates/DegreeCertificates"
import Memories from "./pages/Memories/Memories"
import AlbumDetails from "./pages/Memories/AlbumDetails"
import Events from "./pages/Events/Events"
import EventDetails from "./pages/EventDetails/EventDetails"
import Chapters from "./pages/Chapters/Chapters"
import ChapterDetails from "./pages/Chapters/ChapterDetails"
import ViewAllTalks from "./pages/TechTalks/ViewAllTalks"
import AlumniDay from "./pages/AlumniDay/AlumniDay"
import AlumniDayDetails from "./pages/AlumniDay/AlumniDayDetails"
import AlumniSinglePage from "./pages/AlumniSinglePage/AlumniSinglePage"
import Login from "./pages/Auth/Login/Login"
import Success from "./pages/payment/Success"
import BasicDetails from "./pages/Auth/Profile/Forms/BasicDetails"
import Dashboard from "./pages/Auth/Dashboard/Dashboard"
import EducationDetails from "./pages/Auth/Profile/Forms/EducationDetails"
import WorkDetails from "./pages/Auth/Profile/Forms/WorkDetails"
import AlumniOfTheMonthSingle from "./pages/AlumniOfTheMonthSingle/AlumniOfTheMonthSingle"
import NewsLetterDetails from "./pages/NewsLetters/NewsletterDetails"
import ServiceAlumniAwardSingle from "./pages/ServiceAlumniAwardSingle/ServiceAlumniAwardSingle"
import Reunions from "./pages/Reunions/Reunions"
import ReunionDetails from "./pages/Reunions/ReunionDetails"
import PleaseWait from "./pages/Redirect/PleaseWait"
import Resume from "./pages/Auth/Resume/Resume"
import PrivacyPolicy from "./pages/PolicyPages/PrivacyPolicy"
import TermsConditions from "./pages/PolicyPages/TermsConditions"
import PaymentReceipt from "./pages/Auth/Payments/PaymentReceipt"
import Payments from "./pages/Auth/Payments/Payments"

import { LinkedInCallback } from "react-linkedin-login-oauth2"
import LinkedinLogin from "./pages/Auth/Login/LinkedinLogin"
import UpdateMobile from "./pages/Auth/Profile/UpdateMobile"
import Forum from "./pages/Auth/Forum/Forum"
import PostDetails from "./pages/Auth/Forum/PostDetails"
import QuickLinks from "./pages/QuickLinks/QuickLinks"

const Homepage = loadable(() => import("./pages/Homepage"))
const YoungAlumniAwards = loadable(() =>
    import("./pages/YoungAlumniAwards/YoungAlumniAwards")
)

function App() {
    return (
        <HelmetProvider>
            <Router basename="/">
                <Header />
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route
                        path="/distinguished-alumni-award"
                        element={<Awards />}
                    />
                    <Route
                        path="/young-alumni-achievements"
                        element={<YoungAlumniAwards />}
                    />
                    <Route
                        path="/young-alumni/:slug"
                        element={<YoungAlumniPage />}
                    />
                    <Route path="/news" element={<AlumniNews />} />
                    <Route path="/endowment-fund" element={<EndowmentFund />} />
                    <Route path="/tech-talks" element={<TechTalks />} />
                    <Route
                        path="/alumni-association"
                        element={<AlumniAssociation />}
                    />
                    <Route path="/why-donate-us" element={<WhyDonate />} />
                    <Route
                        path="/distinguished-alumni-service-award"
                        element={<ServiceAlumniAward />}
                    />
                    <Route
                        path="/distinguished-alumni-service-details/:slug"
                        element={<ServiceAlumniAwardSingle />}
                    />
                    <Route
                        path="/tech-talk-details/:slug"
                        element={<TechTalkDetails />}
                    />
                    <Route path="/all-tech-talks" element={<ViewAllTalks />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/notice-board" element={<NoticeBoard />} />
                    <Route
                        path="/notice-board-details/:slug"
                        element={<NoticeDetails />}
                    />
                    <Route
                        path="/news-details/:slug"
                        element={<NewsDetails />}
                    />
                    <Route
                        path="/distinguished-alumni/:slug"
                        element={<DistinguishedAlumniPage />}
                    />
                    <Route path="/by-laws" element={<ByLaws />} />
                    <Route path="/chapter-policy" element={<ChapterPolicy />} />
                    <Route path="/office-bearers" element={<OfficeBearers />} />
                    <Route path="/alumni-affairs" element={<AlumniAffairs />} />
                    <Route path="/how-to-donate" element={<HowToDonate />} />
                    <Route path="/give" element={<Give />} />
                    <Route path="/facilities" element={<Facilities />} />
                    <Route
                        path="/newsletter-archive"
                        element={<NewsLetterArchives />}
                    />
                    <Route path="/newsletter" element={<Newsletters />} />
                    <Route
                        path="/newsletter-details/:slug"
                        element={<NewsLetterDetails />}
                    />
                    <Route path="/recognition" element={<Recognition />} />
                    <Route
                        path="/alumni-database"
                        element={<AlumniDatabase />}
                    />
                    <Route
                        path="/alumni-single/:slug"
                        element={<AlumniSinglePage />}
                    />
                    <Route
                        path="/class-contribution"
                        element={<ClassContribution />}
                    />
                    <Route
                        path="/scholarships-awards"
                        element={<ScholarshipsAwards />}
                    />
                    <Route
                        path="/special-purposes"
                        element={<SpecialPurposes />}
                    />
                    <Route path="/chairs" element={<Chairs />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                        path="/scholarship-details/:slug"
                        element={<ScholarshipDetails />}
                    />
                    <Route
                        path="/chair-details/:slug"
                        element={<ChairDetails />}
                    />
                    <Route
                        path="/special-purpose-details/:slug"
                        element={<SpecialPurposeDetails />}
                    />
                    <Route
                        path="/alumni-of-the-month"
                        element={<AlumniOfTheMonth />}
                    />
                    <Route
                        path="/alumni-of-the-month-details/:slug"
                        element={<AlumniOfTheMonthSingle />}
                    />
                    <Route
                        path="/degree-certificates"
                        element={<DegreeCertificates />}
                    />
                    <Route path="/memories" element={<Memories />} />
                    <Route
                        path="/memory-details/:slug"
                        element={<AlbumDetails />}
                    />
                    <Route path="/events" element={<Events />} />
                    {/* <Route
                        path="/event-details/:slug"
                        element={<EventDetails />}
                     /> */}
                    <Route
                        path="/event-details/:slug"
                        element={<EventDetails />}
                    />
                    <Route path="/chapters" element={<Chapters />} />
                    <Route
                        path="/chapter-details/:slug"
                        element={<ChapterDetails />}
                    />
                    <Route path="/alumni-day" element={<AlumniDay />} />
                    <Route
                        path="/alumni-day-details/:slug"
                        element={<AlumniDayDetails />}
                    />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/donation/payment-successfull"
                        element={<Success />}
                    />
                    <Route
                        path="profile/basic-details"
                        element={<BasicDetails />}
                    />
                    <Route
                        path="profile/education-details"
                        element={<EducationDetails />}
                    />
                    <Route
                        path="profile/work-details"
                        element={<WorkDetails />}
                    />
                    <Route path="/reunions" element={<Reunions />} />
                    <Route
                        path="/reunion-details/:slug"
                        element={<ReunionDetails />}
                    />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="resume" element={<Resume />} />
                    <Route path="/redirect" element={<PleaseWait />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsConditions />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route
                        path="/payment-receipt/:txnid"
                        element={<PaymentReceipt />}
                    />
                    <Route path="/my-donations" element={<Payments />} />
                    <Route
                        path="/linkedin-callback"
                        element={<LinkedInCallback />}
                    />
                    <Route
                        path="/linkedin-login/:code"
                        element={<LinkedinLogin />}
                    />
                    <Route
                        path="/profile/update-mobile"
                        element={<UpdateMobile />}
                    />
                    <Route path="/forum" element={<Forum />} />
                    <Route path="/forum/post/:slug" element={<PostDetails />} />
                    <Route path="/quick-link/:slug" element={<QuickLinks />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </Router>
        </HelmetProvider>
    )
}

export default App
