import { apiBaseURI, staticBaseURI } from "../../config"

export const AlumniItem = ({ name, image, batch, slug }) => {
    return (
        <div className="relative bg-dark-blue-newsletter award-winner-item py-4 px-8 rounded-lg m-4">
            <a href={`${staticBaseURI}/alumni-single/${slug}`}>
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={name}
                    className=" -mt-8"
                />
                <h4 className="text-white text-lg md:text-xl lg:text-2xl font-bold mt-2">
                    {name}
                </h4>
                <h6 className="text-white md:text-lg lg:text-xl">
                    Degree Year: {batch}
                </h6>
            </a>
        </div>
    )
}
