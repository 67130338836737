import { useDispatch, useSelector } from "react-redux"

import { apiBaseURI, staticBaseURI } from "../../../config"
import { fetchReceiptDetails } from "../../../features/auth/authSlice"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

const PaymentReceipt = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const { receiptDetails } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(fetchReceiptDetails(params.txnid))
    }, [dispatch, params.txnid])

    const printDiv = () => {
        let divContents = document.getElementById("printdivcontent").innerHTML

        var originalContents = document.body.innerHTML
        document.body.innerHTML = divContents
        window.print()
        document.body.innerHTML = originalContents
    }

    return (
        <section className="py-20">
            <div className="container mx-auto px-4 md:px-8">
                <div
                    className="form-wrap py-8 bg-white px-0 md:px-8 mt-8 border-0 md:border border-gray-300"
                    id="printdivcontent"
                >
                    <div className="bg-blue-900 p-1 w-full"></div>
                    <div className="flex justify-between items-center my-10">
                        <div className="flex justify-start items-center">
                            <div className="mr-4">
                                <img
                                    src={`${staticBaseURI}/dists/images/logo.png`}
                                    className="w-24"
                                    alt="rgu-logo"
                                />
                            </div>
                            <div>
                                <h2 className="font-bold text-sm md:text-xl uppercase">
                                    Rajiv Gandhi University
                                </h2>
                                <h3 className=" text-xs md:text-base">
                                    Rono Hills, Doimukh, Arunachal Pradesh,
                                    791112
                                </h3>
                            </div>
                        </div>
                        <div>
                            <h1 className="font-bold text-sm md:text-xl">
                                INVOICE #
                                {receiptDetails.transaction?.transaction_id}
                            </h1>
                        </div>
                    </div>
                    <h1 className="font-bold text-xl md:text-2xl uppercase text-blue-dark mb-8">
                        {receiptDetails.transaction?.form_type ===
                        "endowment_fund"
                            ? "Endowment Fund Donation Receipt"
                            : "General Donation Receipt"}
                    </h1>
                    <div>
                        <div className="flex mb-3 space-x-4">
                            <div className="font-bold">Name of Donor:</div>
                            <div>
                                {receiptDetails.transaction?.member?.name}
                            </div>
                        </div>
                        <div className="flex mb-3 space-x-4">
                            <div className="font-bold">Mobile:</div>
                            <div>
                                {receiptDetails.transaction?.member?.mobile}
                            </div>
                        </div>
                        <div className="flex mb-3 space-x-4">
                            <div className="font-bold">Email ID:</div>
                            <div>
                                {receiptDetails.transaction?.member?.email}
                            </div>
                        </div>
                        <div className="flex mb-3 space-x-4">
                            <div className="font-bold">Transaction Date:</div>
                            <div>{receiptDetails.transaction?.paid_at}</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 mt-10">
                        <div>
                            <h1 className="bg-blue-900 text-center text-white text-sm md:text-base p-2 md:p-4">
                                Donation Amount
                            </h1>
                            <h2 className="text-center bg-blue-50 text-blue-900 font-semibold text-sm md:text-base p-2 md:p-4">
                                {`${receiptDetails.transaction?.amount} ${receiptDetails.currency}`}
                            </h2>
                        </div>
                        <div>
                            <h1 className="bg-blue-900 text-center text-white text-sm md:text-base p-2 md:p-4">
                                Transaction ID
                            </h1>
                            <h2 className="text-center bg-blue-50 text-blue-900 font-semibold text-sm md:text-base p-2 md:p-4">
                                {receiptDetails.transaction?.transaction_id}
                            </h2>
                        </div>
                        <div>
                            <h1 className="bg-blue-900 text-center text-white text-sm md:text-base p-2 md:p-4">
                                Mode Of Payment
                            </h1>
                            <h2 className="text-center bg-blue-50 text-blue-900 font-semibold text-sm md:text-base p-2 md:p-4">
                                {receiptDetails.payment}
                            </h2>
                        </div>
                    </div>
                    <div className="flex justify-center my-10">
                        <img
                            src={`${apiBaseURI}/payments/qrcodes/${receiptDetails.transaction?.qr_code}`}
                            alt="qr-code"
                        />
                    </div>
                    <div className="flex justify-center flex-wrap mt-6">
                        <img
                            src={`${staticBaseURI}/govt-logos/10.jpg`}
                            alt="Fit India"
                            style={{ width: "100px", marginRight: "1rem" }}
                        />
                        <img
                            src={`${staticBaseURI}/govt-logos/11.jpg`}
                            alt="Digital India"
                            style={{ width: "100px", marginRight: "1rem" }}
                        />
                        <img
                            src={`${staticBaseURI}/govt-logos/12.jpg`}
                            alt="Jal Shakti Abhiyan"
                            style={{ width: "100px", marginRight: "1rem" }}
                        />
                        <img
                            src={`${staticBaseURI}/govt-logos/13.jpg`}
                            alt="Swacch Bharat"
                            style={{ width: "100px", marginRight: "1rem" }}
                        />
                        <img
                            src={`${staticBaseURI}/govt-logos/14.jpg`}
                            alt="Swayam"
                            style={{ width: "100px", marginRight: "1rem" }}
                        />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button
                        type="button"
                        className="bg-blue-700 text-white px-6 py-2 rounded-md"
                        onClick={() => printDiv()}
                    >
                        Print
                    </button>
                </div>
            </div>
        </section>
    )
}

export default PaymentReceipt
