import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import facilitiesService from "./facilitiesService"

const initialState = {
    facilities: [],
    isLoading: false,
    isError: false,
}

export const fetchFacilities = createAsyncThunk(
    "facilities/fetchFacilites",
    async (_, thunkAPI) => {
        try {
            return await facilitiesService.fetchFacilities()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const facilitiesSlice = createSlice({
    name: "facilities",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFacilities.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchFacilities.fulfilled, (state, action) => {
                state.facilities = action.payload
                state.isLoading = false
            })
            .addCase(fetchFacilities.rejected, (state, action) => {
                state.facilities = []
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export default facilitiesSlice.reducer
