import { staticBaseURI } from "../../../config"

import Facebook from "../../../components/Header/RguNetwork/Facebook"
import Google from "../../../components/Header/RguNetwork/Google"
import Linkedin from "../../../components/Header/RguNetwork/Linkedin"

const SocialLinks = () => {
    return (
        <section className="bg-dark-blue-newsletter px-4 pb-20">
            <div className="container mx-auto px-6">
                <div className="register-social-links p-4 md:p-8 lg:p-12 relative -mt-8 rounded-2xl">
                    <div className="flex flex-col md:flex-row justify-between space-x-2">
                        <div className="self-center flex space-x-2 mb-4 md:mb-0">
                            <div className="self-center flex-initial">
                                <img
                                    src={`${staticBaseURI}/dists/images/logo.png`}
                                    className="w-24 md:w-28 grayscale"
                                    alt="RGU logo"
                                />
                            </div>
                            <div className="self-center flex-1">
                                <h2 className="text-white uppercase text-xl lg:text-3xl">
                                    Rajiv Gandhi University
                                </h2>
                                <h3 className="uppercase text-base md:text-lg lg:text-xl text-white">
                                    Arunachal Pradesh
                                </h3>
                            </div>
                        </div>
                        <div className="self-center">
                            <div className="register-social-buttons flex flex-col self-center">
                                <Facebook hide={false} />
                                <Google hide={false} />
                                <Linkedin hide={false} />
                                {/* <a
                                    href="#0"
                                    className="linkedin-connect-btn flex justify-center"
                                >
                                    <div className="">Connect With</div>
                                    <div className="mt-1 ml-2">
                                        <FaLinkedin />
                                    </div>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-12">
                    <p className="font-bold text-xl text-white">
                        By singing up, you agree to our terms and conditions and
                        privacy policy
                    </p>
                    <div className="flex space-x-2 md:space-x-4 text-white mt-6 text-xl font-bold register-extra-links flex-wrap">
                        {/* <a href="#0">About</a>
                        <a href="#0">Jobs</a>
                        <a href="#0">FAQ</a> */}
                        <a href={`${staticBaseURI}/privacy-policy`}>
                            Privacy Policy
                        </a>
                        <a href={`${staticBaseURI}/terms-and-conditions`}>
                            Terms & Conditions
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SocialLinks
