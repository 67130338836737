import { staticBaseURI } from "../../config"

const url = window.location.href

export const Facebook = () => {
    return (
        <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                url
            )}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src={`${staticBaseURI}/dists/images/s-fb.png`}
                alt="facebook"
            />
        </a>
    )
}

export const Twitter = () => {
    return (
        <a
            href={`https://twitter.com/intent/tweet?text=${encodeURI(url)}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src={`${staticBaseURI}/dists/images/s-tw.png`} alt="twiiter" />
        </a>
    )
}

export const Whatsapp = () => {
    return (
        <a
            href={`whatsapp://send?text=${encodeURI(url)}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                src={`${staticBaseURI}/dists/images/s-wa.png`}
                alt="whatsapp"
            />
        </a>
    )
}
