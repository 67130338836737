import Slider from "react-slick"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

import { apiBaseURI } from "../../config"
import { fetchDonors } from "../../features/recognitions/recognitionSlice"
import { DownArrow, UpArrow } from "../../components/CustomArrows"
import { openModal } from "../../features/modal/modalSlice"
import BoardMemberModal from "./BoardMemberModal"
import Modal from "../../components/Modal/Modal"

const Board = () => {
    const dispatch = useDispatch()

    const { donors } = useSelector((state) => state.recognitionsPage)
    const { isOpen, modalName } = useSelector((state) => state.modal)

    const [selectedMember, setSelectedMember] = useState("")
    // const [modal_name, setModalName] = useState("")

    let modal_name = "board-member-modal"

    useEffect(() => {
        dispatch(fetchDonors())
    }, [dispatch])

    const boardMemberModal = (ev, id) => {
        ev.preventDefault()

        setSelectedMember(donors.find((member) => member.id === id))
        // setModalName(`board-member-${id}`)

        dispatch(openModal(modal_name))
    }

    const settings_one = {
        dots: false,
        className: "center",
        centerMode: true,
        infinite: donors.length >= 4 ? true : false,
        slidesToShow: donors.length >= 4 ? 4 : donors.length,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <UpArrow />,
        prevArrow: <DownArrow />,
    }

    return (
        <>
            <section className="recognition-board-members px-4 py-20">
                <div className="container mx-auto px-6">
                    <div>
                        <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl uppercase">
                            Recognition
                        </h1>
                        <div className="flex">
                            <h1 className="text-2xl md:text-4xl lg:text-5xl uppercase">
                                Board
                            </h1>
                            <h3 className="flex items-end ml-4 font-bold text-base md:text-lg uppercase">
                                Major Alumni Dononrs
                            </h3>
                        </div>
                    </div>
                    <div className="members-wrapper mt-12 w-full">
                        <div className="grid grid-cols-10 md:grid-cols-5 gap-4">
                            <div className="col-span-3 md:col-span-1"></div>
                            <div className="col-span-7 md:col-span-4 hidden md:block">
                                <div className="recognition-members-table-bg">
                                    {/* <div className="flex justify-around h-full">
                                    <div className="self-center text-center">
                                        Batch
                                    </div>
                                    <div className="self-center">
                                        Department
                                    </div>
                                    <div className="self-center">
                                        Designation
                                    </div>
                                    <div className="self-center">Funded</div>
                                </div> */}
                                    <table className="recognition-members-table">
                                        <thead>
                                            <tr>
                                                <th>Batch</th>
                                                <th width="25%">Department</th>
                                                <th width="25%">Designation</th>
                                                <th>Funded</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Slider
                            {...settings_one}
                            className="recognition-carousel"
                        >
                            {donors.map((member) => (
                                <div className="m-0 p-0" key={member.id}>
                                    <div className="grid grid-cols-10 md:grid-cols-5 gap-4 m-0 p-0">
                                        <div
                                            className=" bg-white col-span-10 md:col-span-1 member-img-wrapper m-0 py-4"
                                            onClick={(ev) =>
                                                boardMemberModal(ev, member.id)
                                            }
                                        >
                                            <div className="flex  justify-center">
                                                <img
                                                    className="rounded-full w-[70%] member-img"
                                                    src={`${apiBaseURI}${member.image_url}`}
                                                    alt={member.name}
                                                />
                                            </div>
                                            <h1 className="mt-4 text-center member-name">
                                                {member.name}
                                            </h1>
                                        </div>
                                        <div className="col-span-7 md:col-span-4 recognition-members-table-bg hidden md:block">
                                            {/* <div className="flex justify-around h-full">
                                            <div className="self-center">
                                                {member.batch}
                                            </div>
                                            <div className="self-center">
                                                {member.department}
                                            </div>
                                            <div className="self-center">
                                                {member.designation}
                                            </div>
                                            <div className="self-center">
                                                {member.funded}
                                            </div>
                                        </div> */}
                                            <table className="recognition-members-table member-info w-[200vw] overflow-x-auto">
                                                <tbody>
                                                    <tr>
                                                        <td className="h-full self-center">
                                                            {member.year}
                                                        </td>
                                                        <td width="25%">
                                                            {
                                                                member
                                                                    .department
                                                                    .name
                                                            }
                                                        </td>
                                                        <td width="25%">
                                                            {member.designation}
                                                        </td>
                                                        <td>
                                                            {
                                                                member.formatted_amount
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                        {/* <div className="col-span-4 recognition-members-table-bg">
                        <table className="recognition-members-table">
                            <thead>
                                <tr>
                                    <th>Batch</th>
                                    <th>Department</th>
                                    <th>Designation</th>
                                    <th>Funded</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div> */}
                    </div>
                </div>
            </section>

            {modalName === modal_name && isOpen && (
                <Modal>
                    <BoardMemberModal info={selectedMember} />
                </Modal>
            )}
        </>
    )
}

export default Board
