import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import newsService from "./newsService"

const initialState = {
    news: [],
    moreNews: [],
    perPage: 16,
    currentPage: 1,
    lastPage: 1,
    newsDetails: {},
    nextNews: null,
    nextPageURI: null,
    prevPageURI: null,
    isLoading: false,
    isError: false,
}

export const fetchAllNews = createAsyncThunk(
    "news/fetchNews",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().newspage
            return await newsService.fetchNews(state.perPage, currentPage)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchNewsDetails = createAsyncThunk(
    "news/fetchNewsDetails",
    async (slug, thunkAPI) => {
        try {
            return await newsService.fetchNewsDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNews.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchAllNews.fulfilled, (state, action) => {
                state.isLoading = false
                state.news = [...state.news, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchAllNews.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.news = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchNewsDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNewsDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.newsDetails = action.payload.news
                state.moreNews = action.payload.more_news
                state.nextNews = action.payload.next_news
            })
            .addCase(fetchNewsDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
                state.moreNews = []
                state.nextNews = null
                state.newsDetails = {}
            })
    },
})

export default newsSlice.reducer
