import { IoMdCloseCircleOutline } from "react-icons/io"
import { useDispatch } from "react-redux"
import { closeModal } from "../../features/modal/modalSlice"

import "./modal.css"

const Modal = (props) => {
    const dispatch = useDispatch()

    return (
        <div className="modal-overlay">
            <div className="modal-wrapper">
                <div className="modal-body">
                    <button
                        type="button"
                        onClick={() => dispatch(closeModal())}
                        className="modal-close-btn"
                        title="Close"
                    >
                        <IoMdCloseCircleOutline />
                    </button>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal
