import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import endowmentFundService from "./endowmentFundService"

const initialState = {
    founders: [],
    boardMembers: [],
    isLoading: false,
    isError: false,
    pageContent: {},
    donors: [],
}

export const fetchPageContent = createAsyncThunk(
    "endowmentTeam/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await endowmentFundService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchDonors = createAsyncThunk(
    "endowmentTeam/fetchDonors",
    async (slug, thunkAPI) => {
        try {
            return await endowmentFundService.fetchDonors(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchTeamMembers = createAsyncThunk(
    "endowmentTeam/fetchTeamMembers",
    async (_, thunkAPI) => {
        try {
            return await endowmentFundService.fetchTeamMembers()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const endowmentFundSlice = createSlice({
    name: "endowmentTeam",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamMembers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTeamMembers.fulfilled, (state, action) => {
                state.isLoading = false
                state.founders = action.payload.founders
                state.boardMembers = action.payload.board_members
            })
            .addCase(fetchTeamMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.founders = []
                state.boardMembers = []
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchDonors.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDonors.fulfilled, (state, action) => {
                state.isLoading = false
                state.donors = action.payload
            })
            .addCase(fetchDonors.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.donors = []
                state.errorMessage = action.payload
            })
    },
})

export default endowmentFundSlice.reducer
