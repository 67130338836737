import React from "react"

const PhotoCardRound = () => {
    return (
        <div
            role="status"
            className="p-4 max-w-sm rounded animate-pulse md:p-6 "
        >
            <div className="items-center mt-4 flex justify-center">
                <svg
                    className="w-36 h-36 text-gray-200"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </div>
            <div className="flex justify-center">
                <div className="h-2.5 bg-gray-200 rounded-full  w-32 mb-2"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default PhotoCardRound
