import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import About from "./About"
import Bio from "./Bio"
import NextAlumni from "./NextAlumni"
import "./distinguished-alumni-page.css"
import { apiBaseURI } from "../../config"
import { fetchAlumniDetails } from "../../features/distinguished-alumns/distinguishedAlumnsSlice"

const DistinguishedAlumniPage = () => {
    let params = useParams()

    let dispatch = useDispatch()

    const { alumniDetails } = useSelector(
        (state) => state.distinguishedAlumnsPage
    )

    useEffect(() => {
        dispatch(fetchAlumniDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${alumniDetails.cover_image_url}`}
                text={false}
            />
            <About
                name={alumniDetails.name}
                image={`${apiBaseURI}${alumniDetails.image_url}`}
                awardYear={alumniDetails.award_year}
                degreeYear={alumniDetails.degree_year}
            />
            <Bio about={alumniDetails.description} />
            <NextAlumni
                next={alumniDetails.next}
                prev={alumniDetails.previous}
            />
        </>
    )
}

export default DistinguishedAlumniPage
