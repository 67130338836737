import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import alumniAssociationService from "./alumniAssociationService"

const initialState = {
    pageContent: {},
    vcMembers: [],
    errorVcMembers: false,
    loadingVcMembers: false,
}

export const fetchPageContent = createAsyncThunk(
    "alumniAssociation/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await alumniAssociationService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchVcMembers = createAsyncThunk(
    "alumniAssociation/fetchVcMembers",
    async (limit, thunkAPI) => {
        try {
            return await alumniAssociationService.fetchVcMembers(limit)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const alumniAssociationSlice = createSlice({
    name: "alumniAssociation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchVcMembers.pending, (state) => {
                state.loadingVcMembers = true
            })
            .addCase(fetchVcMembers.fulfilled, (state, action) => {
                state.loadingVcMembers = false
                state.vcMembers = action.payload
            })
            .addCase(fetchVcMembers.rejected, (state, action) => {
                state.loadingVcMembers = false
                state.errorVcMembers = true
                state.messageVcMembers = action.payload
                state.vcMembers = []
            })
    },
})

export default alumniAssociationSlice.reducer
