import { useDispatch } from "react-redux"

import { staticBaseURI } from "../../../../config"
import { closeModal } from "../../../../features/modal/modalSlice"

const PostSuccess = () => {
    const dispatch = useDispatch()

    return (
        <div className="">
            <div className="flex justify-center">
                <img
                    alt="success"
                    src={`${staticBaseURI}/dists/images/success.gif`}
                    className="w-24"
                />
            </div>
            <div className="text-center mt-4">
                <h1 className="text-2xl font-semibold mb-4">
                    Post successfully created
                </h1>
                <button
                    onClick={() => dispatch(closeModal())}
                    className="bg-blue-900 text-white px-4 py-1"
                >
                    Go Back
                </button>
            </div>
        </div>
    )
}

export default PostSuccess
