import * as DOMPurify from "dompurify"
import { apiBaseURI, staticBaseURI } from "../../config"

const Details = ({
    content,
    videoType,
    youtubeId,
    video,
    youtubeLink,
    title,
}) => {
    let cleanContent = DOMPurify.sanitize(content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    function playVideo() {
        const video = document.querySelector("#video")
        video.play()
        const playBtnImage = document.querySelector("#play-btn-img")
        playBtnImage.style.display = "none"
    }

    return (
        <section className="">
            <div className="bg-white px-10 relative pb-28 md:pb-36">
                <div className="flex justify-end my-10">
                    <h1 className="ttd-about-blue-text text-4xl md:text-6xl lg:text-8xl font-bold underline decoration-cyan-300 underline-offset-4">
                        Abstract
                    </h1>
                </div>
                <div
                    className="ttd-abstract-body text-base md:text-lg"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </div>
            <div className="mt-20 bg-blue-dark px-4 md:px-10 pb-12">
                <div className="container mx-auto">
                    <div className="relative -mt-40">
                        {/* <img
                            src="./dists/images/ttd-details.jpg"
                            alt="details"
                            className="w-full"
                        /> */}

                        {videoType === "yt" ? (
                            <div>
                                <iframe
                                    width="100%"
                                    height="500"
                                    src={`https://www.youtube.com/embed/${youtubeId}`}
                                    title={title}
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        ) : (
                            <div className="relative">
                                <video
                                    width="100%"
                                    height="300"
                                    controls
                                    name="media"
                                    id="video"
                                    src={`${apiBaseURI}${video}`}
                                />
                                <div
                                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                    id="play-btn-img"
                                >
                                    <img
                                        src={`${staticBaseURI}/dists/images/video-play.png`}
                                        className="w-16 md:w-32 cursor-pointer"
                                        alt="play button"
                                        onClick={() => playVideo()}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {videoType === "yt" && (
                        <div className="flex justify-center mt-10">
                            <div className="self-center mr-4 text-white text-3xl">
                                Watch On
                            </div>
                            <a
                                href={youtubeLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    alt="youtube"
                                    src={`${staticBaseURI}/dists/images/yt.png`}
                                    className="self-center w-20"
                                />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default Details
