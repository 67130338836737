import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import ChairDetailsBody from "./ChairDetailsBody"
import { fetchChairDetails } from "../../features/chairs/chairsSlice"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"

const ChairDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { chairDetails } = useSelector((state) => state.chairsPage)

    useEffect(() => {
        dispatch(fetchChairDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{chairDetails.meta_title}</title>
                <meta
                    name="description"
                    content={chairDetails.meta_description}
                />
                <meta name="keywords" content={chairDetails.meta_keywords} />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${chairDetails.image_url}`}
            />
            <ChairDetailsBody
                content={chairDetails.description}
                title={chairDetails.title}
            />
        </>
    )
}

export default ChairDetails
