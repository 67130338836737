// import { useSelector } from "react-redux"
// import { useNavigate } from "react-router-dom"
// import { staticBaseURI } from "../../../../config"

import "./steps.css"

const Steps = ({ step }) => {
    // let navigate = useNavigate()

    // const { user } = useSelector((state) => state.auth)

    // if (user && user.profile_completed) {
    //     navigate(`${staticBaseURI}/dashboard`)
    // }

    return (
        <div className="w-full bg-blue-dark flex justify-center flex-row md:flex-col h-full text-white py-16 md:py-auto space-x-4 md:space-x-0">
            <div
                className={`flex flex-col md:flex-row profile-steps  ${
                    step === 1 ? "active" : step > 1 ? "complete" : "inactive"
                }`}
            >
                <div className="step-no">
                    1<div className="step-one"></div>
                </div>
                <div className="step-name">Basic Details</div>
            </div>
            <div
                className={`flex flex-col md:flex-row profile-steps ${
                    step === 2 ? "active" : step > 2 ? "complete" : "inactive"
                }`}
            >
                <div className="step-no">
                    2<div className="step-two"></div>
                </div>
                <div className="step-name">Education Details</div>
            </div>
            <div
                className={`flex flex-col md:flex-row profile-steps ${
                    step === 3 ? "active" : step > 3 ? "complete" : "inactive"
                }`}
            >
                <div className="step-no">3</div>
                <div className="step-name">Work Details</div>
            </div>
        </div>
    )
}

export default Steps
