import { BoardMemberItem } from "./BoardMemberItem"

const Board = ({ members }) => {
    return (
        <section className="bg-blue-dark px-4 py-16 -mt-4">
            <div className="container mx-auto px-6">
                <h3 className="ef-board-header font-semibold text-4xl uppercase mt-0 md:mt-10">
                    Composition of the board
                    <div className="h-1 mt-3 w-1/2 md:w-1/3"></div>
                </h3>
                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-8">
                    {members.map((item) => (
                        <BoardMemberItem
                            key={`board-member-${item.id}`}
                            name={item.name}
                            image={item.image_url}
                            designation={item.designation}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Board
