import { apiBaseURI, formatDate } from "../../../../config"

const PostBy = ({ member }) => {
    const memberSinceDate = formatDate(new Date(member?.created_at))

    return (
        <div className="bg-white shadow-md rounded-lg mb-4">
            <h1 className="font-medium bg-blue-semi-dark text-white text-center text-lg lg:text-xl p-2 rounded-t-lg">
                Post By
            </h1>
            <div className="p-3 lg:p-4">
                <div className="flex justify-center">
                    <img
                        alt={member?.name}
                        src={`${apiBaseURI}${member?.basic_details?.photo_url}`}
                        className="w-24 md:w-20 lg:w-28 rounded-full"
                    />
                </div>
                <div className="text-center mt-2">
                    <h3 className="font-bold text-base md:text-lg lg:text-xl">
                        {member?.name}
                    </h3>
                </div>
                <div className="grid grid-cols-2 text-center mt-3">
                    <div className="border-r border-r-gray-400 p-1">
                        <h6 className="text-sm lg:text-base">Total Posts</h6>
                        <h5 className=" text-sm lg:text-lg font-semibold">
                            {member?.posts_count}
                        </h5>
                    </div>
                    <div className="p-1">
                        <h6 className="text-sm lg:text-base">Member Since</h6>
                        <h5 className="text-sm lg:text-lg font-semibold">{`${memberSinceDate.monthName}, ${memberSinceDate.year}`}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostBy
