import React from "react"

const HowToMakeDonation = () => {
    return (
        <div className="container mx-auto relative px-2 md:px-6 mt-10 ">
            <div className="bg-blue-dark px-4 md:px-8 py-8 rounded-2xl text-white">
                <h3 className="font-bold text-2xl md:text-4xl">
                    How to make the donation?
                </h3>
                <h5 className="text-lg md:text-2xl">Steps towards good</h5>
                <div className="htd-gradient-underline h-1 mt-4"></div>
                <div className="grid grid-cols-5 gap-4 mt-8">
                    <div className="flex justify-center text-sm md:text-2xl">
                        <div className="text-center">
                            <div className="flex justify-center mb-4">
                                <img
                                    src="./dists/images/give.png"
                                    className="w-10 md:w-[50%]"
                                    alt="gift"
                                />
                            </div>
                            Choose Gift Amount
                        </div>
                    </div>
                    <div className="htd-gradient-underline h-1 self-center"></div>
                    <div className="flex justify-center text-sm md:text-2xl">
                        <div className="text-center">
                            <div className="flex justify-center mb-4">
                                <img
                                    src="./dists/images/user-info-icon.png"
                                    alt="gift"
                                    className="w-10 md:w-[50%]"
                                />
                            </div>
                            Add your Information
                        </div>
                    </div>
                    <div className="htd-gradient-underline h-1 self-center"></div>
                    <div className="flex justify-center text-sm md:text-2xl">
                        <div className="text-center">
                            <div className="flex justify-center mb-4">
                                <img
                                    src="./dists/images/card.png"
                                    className="w-10 md:w-[50%]"
                                    alt="gift"
                                />
                            </div>
                            Pay
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToMakeDonation
