import { api } from "../../api"

//fetch alumns
const fetchAlumns = async (limit, page) => {
    const url = `/distinguished-alumns/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni details
const fetchAlumniDetails = async (slug) => {
    const url = `/distinguished-alumn/details/${slug}`
    const response = await api.get(url)
    return response.data
}

const distinguishedAlumnsService = {
    fetchAlumns,
    fetchPageContent,
    fetchAlumniDetails,
}

export default distinguishedAlumnsService
