import React from "react"

const About = () => {
    const todayDate = new Date()

    return (
        <section className="bg-black px-4 py-20 relative">
            <div className="container mx-auto px-4">
                <div className="bg-text top-0 left-0">notice</div>
                <div className="flex justify-between">
                    <div className="text-white uppercase border-r-4 pr-4 md:pr-8 self-center">
                        <h2 className="font-semibold text-4xl md:text-7xl">
                            Notice
                        </h2>
                        <h1 className="font-bold text-4xl md:text-7xl">
                            Board
                        </h1>
                    </div>
                    <div className="flex justify-center">
                        <div>
                            <div className="flex justify-center">
                                <img
                                    alt="logo"
                                    src="./dists/images/logo.png"
                                    className=" grayscale md:w-auto w-[50%]"
                                />
                            </div>
                            <div className="text-white text-center font-semibold text-2xl md:text-4xl">
                                <h3>Today is</h3>
                                <h3>{`${todayDate.getDate()} - ${
                                    todayDate.getMonth() + 1
                                } - ${todayDate.getFullYear()}`}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
