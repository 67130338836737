import { staticBaseURI } from "../../config"

const VideoItem = ({ image, url, title }) => {
    return (
        <div className="recognition-video-item relative mx-4">
            <img
                className="rounded-xl"
                src={`${staticBaseURI}${image}`}
                alt={title}
            />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <img
                    src="./dists/images/video-play.png"
                    className="w-16 md:w-32"
                    alt="play button"
                />
            </div>
        </div>
    )
}

export default VideoItem
