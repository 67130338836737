import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import eventsService from "./eventsService"

const initialState = {
    events: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 24,
    type: "all",
    eventDetails: {},
    ongoingEvents: [],
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchEvents = createAsyncThunk(
    "events/fetchEvents",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().eventsPage
            return await eventsService.fetchEvents(
                state.perPage,
                currentPage,
                state.type
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "events/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await eventsService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchEventDetails = createAsyncThunk(
    "events/fetchEventDetails",
    async (slug, thunkAPI) => {
        try {
            return await eventsService.fetchEventDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const eventsSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        setType: (state, action) => {
            state.type = action.payload
        },
        resetEvents: (state) => {
            state.events = []
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.isLoading = false
                state.events = action.payload.data
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.events = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchEventDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchEventDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.eventDetails = action.payload.event
                state.ongoingEvents = action.payload.ongoing_events
            })
            .addCase(fetchEventDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export const { setType, resetEvents, setCurrentPage } = eventsSlice.actions
export default eventsSlice.reducer
