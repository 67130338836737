import React from "react"

const PointItem = ({ title }) => {
    return (
        <div className="flex text-lg mb-8">
            <div className="wd-endowment-point h-2 w-[20%] md:w-[10%] flex-shrink-0 self-center mr-3"></div>
            {title}
        </div>
    )
}

export default PointItem
