import { api } from "../../api"

//fetch tech-talks
const fetchTalks = async (limit) => {
    const url = `/tech-talks/${limit}`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch tech talk details
const fetchTalkDetails = async (slug) => {
    const url = `/tech-talk-details/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch all
const fetchAllTechTalks = async (limit, page, type) => {
    const url = `/tech-talk-list/${limit}/${type}?page=${page}`
    const response = await api.get(url)
    return response.data
}

const techTalkService = {
    fetchTalks,
    fetchPageContent,
    fetchTalkDetails,
    fetchAllTechTalks,
}

export default techTalkService
