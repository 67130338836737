import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import ReunionDetailsBody from "./ReunionDetailsBody"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"
import { fetchReunionDetails } from "../../features/reunions/reunionSlice"

const ReunionDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { reunionDetails } = useSelector((state) => state.reunionsPage)

    useEffect(() => {
        dispatch(fetchReunionDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{reunionDetails.meta_title}</title>
                <meta
                    name="description"
                    content={reunionDetails.meta_description}
                />
                <meta name="keywords" content={reunionDetails.meta_keywords} />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${reunionDetails.image_url}`}
            />
            <ReunionDetailsBody
                content={reunionDetails.description}
                title={reunionDetails.title}
            />
        </>
    )
}

export default ReunionDetails
