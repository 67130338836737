import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import noticeboardService from "./noticeboardService"

const initialState = {
    notices: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 24,
    noticeDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchNotices = createAsyncThunk(
    "noticeboard/fetchNotices",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().noticeboardPage
            return await noticeboardService.fetchNotices(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "noticeboard/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await noticeboardService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchNoticeDetails = createAsyncThunk(
    "noticeboard/fetchNoticeDetails",
    async (slug, thunkAPI) => {
        try {
            return await noticeboardService.fetchNoticeDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const noticeboardSlice = createSlice({
    name: "noticeboard",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotices.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNotices.fulfilled, (state, action) => {
                state.isLoading = false
                state.notices = action.payload.data
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchNotices.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.notices = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchNoticeDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNoticeDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.noticeDetails = action.payload
            })
            .addCase(fetchNoticeDetails.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.noticeDetails = {}
                state.errorMessage = action.payload
            })
    },
})

export const { setCurrentPage } = noticeboardSlice.actions
export default noticeboardSlice.reducer
