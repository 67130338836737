import { useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"

import { redirectUser, staticBaseURI } from "../../../config"
import { linkedinLogin } from "../../../features/auth/authSlice"

const LinkedinLogin = () => {
    const { code } = useParams()
    const dispatch = useDispatch()

    const { login, user, isLoading } = useSelector((state) => state.auth)

    useEffect(() => {
        dispatch(linkedinLogin(code))
    }, [dispatch, code])

    if (!isLoading && login) {
        redirectUser(user)
    } else if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    return (
        <section>
            <div className="my-20">
                <div className="flex justify-center">
                    <div>
                        <div className="flex justify-center">
                            <img
                                src={`${staticBaseURI}/dists/images/loading.gif`}
                                width="150"
                                alt="loading"
                            />
                        </div>
                        <h1 className="font-bold text-lg md:text-2xl text-center">
                            Please wait while we redirect you. Do not close this
                            window or refresh the page.
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LinkedinLogin
