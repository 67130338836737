import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import {
    fetchPageContent,
    fetchTeamMembers,
} from "../../features/endowment-fund/endowmentFundSlice"
import About from "./About"
import Board from "./Board"
import "./endowment-fund.css"
import Founders from "./Founders"
import WaysFaqDonate from "./WaysFaqDonate"

const EndowmentFund = () => {
    const dispatch = useDispatch()

    const { boardMembers, founders, pageContent } = useSelector(
        (state) => state.endowmentFundPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("endowment-fund"))
        dispatch(fetchTeamMembers())
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <section className="ef-blue-bg-gradient px-4 py-10">
                <About about={pageContent.content} />
                <Founders founders={founders} />
            </section>
            <WaysFaqDonate />
            <Board members={boardMembers} />
        </>
    )
}

export default EndowmentFund
