import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import byelawsService from "./byelawsService"

const initialState = {
    byeLaw: {},
    isLoading: false,
    isError: false,
}

export const fetchByeLaw = createAsyncThunk(
    "byelaws/fetchByeLaw",
    async (_, thunkAPI) => {
        try {
            return await byelawsService.fetchByeLaw()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const bylawsSlice = createSlice({
    name: "byelaws",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchByeLaw.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchByeLaw.fulfilled, (state, action) => {
                state.byeLaw = action.payload
                state.isLoading = false
            })
            .addCase(fetchByeLaw.rejected, (state, action) => {
                state.byeLaw = {}
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export default bylawsSlice.reducer
