import { FaPlus, FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import SectionTitle from "../../../../components/Resume/SectionTitle"
import { openModal } from "../../../../features/modal/modalSlice"
import ProjectForm from "./ProjectForm"
import ProjectItem from "./ProjectItem"

const Projects = ({ projects }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="flex justify-between">
                <SectionTitle title="Projects" />

                <button
                    className="text-2xl text-blue-dark self-center  ml-3"
                    onClick={() => dispatch(openModal("newProject"))}
                >
                    <FaPlus />
                </button>
            </div>
            <div className="mt-4 mb-6">
                {projects && projects.length > 0 ? (
                    projects.map((project) => (
                        <ProjectItem key={project.id} project={project} />
                    ))
                ) : (
                    <div className="flex justify-left mt-4">
                        <button
                            className="text-sm bg-blue-dark px-6 py-3 rounded text-white flex"
                            onClick={() => dispatch(openModal("newProject"))}
                        >
                            <FaRegEdit className="self-center mr-2" /> Add New
                            Project
                        </button>
                    </div>
                )}
            </div>

            {isOpen && modalName === `newProject` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Add New Project</h3>
                        <hr className="my-4" />
                        <ProjectForm />
                    </>
                </Modal>
            )}
        </>
    )
}

export default Projects
