import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumnis
const fetchAlumnis = async (limit) => {
    const url = `/alumni-database/${limit}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni details
const fetchAlumniDetails = async (slug) => {
    const url = `/alumni-single/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni records
const fetchAlumniRecords = async (year, limit, page) => {
    const url = `/alumni-records/${year}/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

const alumniDatabaseService = {
    fetchAlumnis,
    fetchPageContent,
    fetchAlumniRecords,
    fetchAlumniDetails,
}

export default alumniDatabaseService
