import { Form, Formik } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import { createForumPost } from "../../../../features/forum/forumSlice"

const NewPostForm = () => {
    const dispatch = useDispatch()

    const handleSubmit = (values, actions) => {
        dispatch(createForumPost({ values, actions }))
    }

    const { categories } = useSelector((state) => state.forum)

    const initialValues = {
        title: "",
        category: "",
        content: "",
    }

    const postSchema = Yup.object({
        title: Yup.string().required("Please enter post title"),
        category: Yup.string().required("Please select post category"),
        content: Yup.string().required("Please enter post body"),
    })

    function handleSelectInput(ev, props, field) {
        props.setFieldValue(field, ev.value)
    }

    const categoryList = categories.map((category) => {
        return { value: category.id, label: category.name }
    })

    return (
        <div className="w-[88vw]">
            <Formik
                initialValues={initialValues}
                validationSchema={postSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions)
                }}
            >
                {(formikProps) => (
                    <Form className="">
                        <TextInput
                            name="title"
                            type="text"
                            placeholder="POST TITLE"
                            color="dark"
                        />
                        <Select
                            value={categoryList.find(
                                ({ value }) =>
                                    value === formikProps.values.categories
                            )}
                            options={categoryList}
                            className="w-full mt-2 border-none"
                            name="category"
                            placeholder="Select Category"
                            onChange={(ev) =>
                                handleSelectInput(ev, formikProps, "category")
                            }
                        />
                        {formikProps.touched.category &&
                        formikProps.errors.category ? (
                            <div className="input-error ">
                                {formikProps.errors.category}
                            </div>
                        ) : null}

                        <div className="mt-4 w-full h-[250px] overflow-y-auto">
                            <label className="text-gray-600 mb-2 text-sm">
                                Post Body:
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data=""
                                config={{
                                    toolbar: [
                                        "undo",
                                        "redo",
                                        "|",
                                        "heading",
                                        "|",
                                        "fontfamily",
                                        "fontsize",
                                        "fontColor",
                                        "fontBackgroundColor",
                                        "|",
                                        "bold",
                                        "italic",
                                        "strikethrough",
                                        "subscript",
                                        "superscript",
                                        "code",
                                        "|",
                                        "link",
                                        "blockQuote",
                                        "codeBlock",
                                        "|",
                                        "bulletedList",
                                        "numberedList",
                                        "outdent",
                                        "indent",
                                    ],
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    formikProps.setFieldValue("content", data)
                                }}
                            />
                            {formikProps.touched.content &&
                            formikProps.errors.content ? (
                                <div className="input-error ">
                                    {formikProps.errors.content}
                                </div>
                            ) : null}
                        </div>
                        <button
                            className="bg-blue-900 text-white md:px-8 px-6 py-1 md:py-2 text-base md:text-xl text-center uppercase font-bold mt-4"
                            type="submit"
                            disabled={formikProps.isSubmitting ? true : false}
                        >
                            {formikProps.isSubmitting ? (
                                <div className="inline-flex">
                                    <Loader size="6" classes="mr-1 -mt-1" />
                                    Loading...
                                </div>
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default NewPostForm
