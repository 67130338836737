import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import "./office-bearers.css"
import About from "./About"
import BearersList from "./BearersList"
import Pagination from "./Pagination"
import { fetchPageContent } from "../../features/office-bearers/officeBearerSlice"
import { apiBaseURI } from "../../config"

const OfficeBearers = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.officeBearersPage)

    useEffect(() => {
        dispatch(fetchPageContent("office-bearers"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <About about={pageContent.content} />
            <BearersList />
            <Pagination />
        </>
    )
}

export default OfficeBearers
