import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import classContributionService from "./classContributionService"

const initialState = {
    years: [],
    isLoading: false,
    isError: false,
    classDetails: {},
    pageContent: {},
    selectedYear: "",
}

export const fetchPageContent = createAsyncThunk(
    "classContribution/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await classContributionService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchDefaultContribution = createAsyncThunk(
    "classContribution/fetchDefaultContribution",
    async (_, thunkAPI) => {
        try {
            return await classContributionService.fetchDefaultContribution()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchClassDetails = createAsyncThunk(
    "classContribution/fetchClassDetails",
    async (slug, thunkAPI) => {
        try {
            return await classContributionService.fetchClassDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const classContributionSlice = createSlice({
    name: "classCOntribution",
    initialState,
    reducers: {
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
        resetclassDetails: (state) => {
            state.classDetails = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchDefaultContribution.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchDefaultContribution.fulfilled, (state, action) => {
                state.isLoading = false
                state.years = action.payload.years
                state.classDetails = action.payload.default
                state.selectedYear = action.payload.default.year
            })
            .addCase(fetchDefaultContribution.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.years = []
                state.classDetails = {}
                state.selectedYear = ""
                state.errorMessage = action.payload
            })
            .addCase(fetchClassDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchClassDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.classDetails = action.payload
            })
            .addCase(fetchClassDetails.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.classDetails = {}
                state.errorMessage = action.payload
            })
    },
})

export const { resetclassDetails, setSelectedYear } =
    classContributionSlice.actions
export default classContributionSlice.reducer
