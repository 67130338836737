import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as DOMPurify from "dompurify"
import { Helmet } from "react-helmet-async"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import PageHeader from "../../components/PageHeader"
import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
} from "../../config"
import { fetchPageContent } from "../../features/endowment-fund/endowmentFundSlice"
import "./how-to-donate.css"
import HowToMakeDonation from "./HowToMakeDonation"
import TransferMethods from "./TransferMethods"
import WireTransfer from "./WireTransfer"
import DonateForm from "./DonateForm"

const HowToDonate = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.endowmentFundPage)

    useEffect(() => {
        dispatch(fetchPageContent("how-to-donate"))
    }, [dispatch])

    let cleanContent = DOMPurify.sanitize(pageContent.content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <>
            <Helmet>
                <title>Give</title>
                <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
            </Helmet>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <section className="px-4">
                <div
                    className="container mx-auto px-6 relative -mt-24 md:-mt-32"
                    ref={ref}
                >
                    <motion.h1
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                        }}
                        className="font-bold text-4xl md:text-6xl htd-page-heading"
                    >
                        How To Donate?
                    </motion.h1>
                </div>
            </section>
            <section className="px-4 pb-0 pt-10 htd-white-blue-gradient">
                <div className="px-6">
                    <DonateForm />
                </div>
                <div className="container mx-auto px-6">
                    <div className="htd-online-giving mt-10">
                        <div className="mb-4">
                            <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl uppercase">
                                Online Giving
                            </h1>
                            <div className="htd-gradient-underline h-2 w-[50%] md:w-[30%] mt-2"></div>
                        </div>
                        <div
                            className="text-lg"
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></div>
                    </div>
                </div>
                <HowToMakeDonation />
            </section>
            <div className="bg-white py-5 -mt-10"></div>
            <WireTransfer content={pageContent.content_2} />
            <TransferMethods />
        </>
    )
}

export default HowToDonate
