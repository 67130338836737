import * as DOMPurify from "dompurify"

const About = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <div className="container mx-auto px-6 relative">
            <div className="ef-about-head flex justify-between -mx-6 md:mx-0">
                <div className="font-bold md:text-5xl lg:text-6xl">
                    About Us
                    <div className="gradient-underline h-1 mt-4">&nbsp;</div>
                </div>
                <div className="ef-stats bg-black p-4 md:p-6 -mt-32 md:-mt-48 lg:-mt-56 rounded-2xl">
                    <div className="text-gradient text-center">
                        <h1 className="md:text-5xl lg:text-6xl font-bold">
                            INR 371 Cr
                        </h1>
                        <h1 className="md:text-5xl lg:text-6xl font-bold">
                            (US$ 50M)
                        </h1>
                        <h3 className="text-base md:text-2xl uppercase mt-4">
                            <span>Pledged By</span> | <span>21 Alumni</span>
                        </h3>
                    </div>
                    <div className="flex justify-center mt-6">
                        <div className="md:flex-1 self-center mr-2">
                            <img
                                src="./dists/images/give-money.png"
                                className="md:w-10 lg:w-16 w-8"
                                alt="give money"
                            />
                        </div>
                        <div className="uppercase text-white self-center text-xs md:text-xl lg:text-2xl">
                            Every Contribution Counts
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="ef-about mt-8 text-lg"
                dangerouslySetInnerHTML={createMarkup(cleanContent)}
            ></div>
        </div>
    )
}

export default About
