import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import configService from "./configService"

const initialState = {
    menu: {},
    isLoading: false,
    isError: false,
    courses: [],
    departments: [],
    countries: [],
    states: [],
    cities: [],
    bankTransferDetails: {},
    quickLinks: [],
    quickLinkDetails: {},
}

export const fetchMenuSettings = createAsyncThunk(
    "config/fetchMenuSettings",
    async (_, thunkAPI) => {
        try {
            return await configService.fetchMenuSettings()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchCourses = createAsyncThunk(
    "config/fetchCourses",
    async (dept_id, thunkAPI) => {
        try {
            return await configService.fetchCourses(dept_id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchDepartments = createAsyncThunk(
    "config/fetchDepartments",
    async (_, thunkAPI) => {
        try {
            return await configService.fetchDepartments()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchStates = createAsyncThunk(
    "config/fetchStates",
    async (country_id, thunkAPI) => {
        try {
            return await configService.fetchStates(country_id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchCities = createAsyncThunk(
    "config/fetchCities",
    async (state_id, thunkAPI) => {
        try {
            return await configService.fetchCities(state_id)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchCountries = createAsyncThunk(
    "config/fetchCountries",
    async (_, thunkAPI) => {
        try {
            return await configService.fetchCountries()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchBankTransferDetails = createAsyncThunk(
    "config/fetchBankTransferDetails",
    async (_, thunkAPI) => {
        try {
            return await configService.fetchBankTransferDetails()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchQuickLinks = createAsyncThunk(
    "config/fetchQuickLinks",
    async (_, thunkAPI) => {
        try {
            return await configService.fetchQuickLinks()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchQuickLinkDetails = createAsyncThunk(
    "config/fetchQuickLinkDetails",
    async (slug, thunkAPI) => {
        try {
            return await configService.fetchQuickLinkDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenuSettings.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchMenuSettings.fulfilled, (state, action) => {
                state.isLoading = false
                state.menu = action.payload
            })
            .addCase(fetchMenuSettings.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.courses = action.payload
            })
            .addCase(fetchCourses.rejected, (state) => {
                state.courses = []
            })
            .addCase(fetchDepartments.fulfilled, (state, action) => {
                state.departments = action.payload
            })
            .addCase(fetchDepartments.rejected, (state) => {
                state.departments = []
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.countries = action.payload
            })
            .addCase(fetchCountries.rejected, (state) => {
                state.countries = []
            })
            .addCase(fetchStates.fulfilled, (state, action) => {
                state.states = action.payload
            })
            .addCase(fetchStates.rejected, (state) => {
                state.states = []
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                state.cities = action.payload
            })
            .addCase(fetchCities.rejected, (state) => {
                state.cities = []
            })
            .addCase(fetchBankTransferDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchBankTransferDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.bankTransferDetails = action.payload
            })
            .addCase(fetchBankTransferDetails.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
            })
            .addCase(fetchQuickLinks.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchQuickLinks.fulfilled, (state, action) => {
                state.isLoading = false
                state.quickLinks = action.payload
            })
            .addCase(fetchQuickLinks.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
                state.quickLinks = []
            })
            .addCase(fetchQuickLinkDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchQuickLinkDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.quickLinkDetails = action.payload
            })
            .addCase(fetchQuickLinkDetails.rejected, (state, action) => {
                state.isError = true
                state.isLoading = false
                state.errorMessage = action.payload
                state.quickLinkDetails = {}
            })
    },
})

export default configSlice.reducer
