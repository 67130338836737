import React from "react"

const GuestOfHonourItem = ({ name, image, designation }) => {
    return (
        <div className="evd-guest-of-honour-item flex">
            <img
                alt={name}
                src={image}
                className="rounded-full w-[40%] md:w-[25%] lg:w-[17%]"
            />
            <div className="self-center text-white ml-4">
                <h3 className="font-semibold text-2xl md:text-3xl lg:text-4xl uppercase">
                    {name}
                </h3>
                <h4 className="text-lg md:text-xl lg:text-2xl mt-4">
                    {designation}
                </h4>
            </div>
        </div>
    )
}

export default GuestOfHonourItem
