import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import ScholarshipDetailsBody from "./ScholarshipDetailsBody"
import { fetchScholarshipDetails } from "../../features/scholarships-awards/scholarshipsSlice"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"

const ScholarshipDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { scholarshipDetails } = useSelector(
        (state) => state.scholarshipsPage
    )

    useEffect(() => {
        dispatch(fetchScholarshipDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{scholarshipDetails.meta_title}</title>
                <meta
                    name="description"
                    content={scholarshipDetails.meta_description}
                />
                <meta
                    name="keywords"
                    content={scholarshipDetails.meta_keywords}
                />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${scholarshipDetails.image_url}`}
            />
            <ScholarshipDetailsBody
                content={scholarshipDetails.description}
                title={scholarshipDetails.title}
            />
        </>
    )
}

export default ScholarshipDetails
