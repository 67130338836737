import { BiChevronRight } from "react-icons/bi"
import { staticBaseURI } from "../../config"

const MenuLink = ({ url = "#0", title }) => {
    return (
        <a href={`${staticBaseURI}/${url}`} className="flex">
            <span>
                <BiChevronRight />
            </span>
            {title}
        </a>
    )
}

export default MenuLink
