import React from "react"
import Slider from "react-slick"
import DonorItem from "./DonorItem"

const WallOfDonors = ({ donors }) => {
    const settings = {
        className: "center",
        centerMode: donors.length >= 6 ? true : false,
        infinite: donors.length >= 6 ? true : false,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        arrows: false,
        dots: true,
        slidesPerRow: 2,
        responsive: [
            {
                breakpoint: 821,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    infinite: true,
                    centerMode: true,
                },
            },
        ],
    }

    return (
        <section className="px-4 py-16 bg-blue-dark">
            <div className="container mx-auto px-6">
                <div className="flex justify-start">
                    <div className="border-r-2 border-r-blue-400 mt-10">
                        <h1 className="htd-page-heading uppercase text-3xl md:text-6xl pr-4">
                            Wall of donors
                        </h1>
                    </div>
                </div>
                <div className="mt-12 mb-12 -mx-10 give-donors-list">
                    <Slider {...settings}>
                        {donors.map((donor) => (
                            <DonorItem
                                key={donor.id}
                                name={donor.name}
                                date={donor.donation_date}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default WallOfDonors
