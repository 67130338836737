const About = () => {
    return (
        <section className="bg-black px-4 pt-10 pb-16 relative">
            <div className="container mx-auto px-6">
                <div className="bg-text recognition-bg-text left-4 top-[30%] md:top-[30%] lg:top-0">
                    Recognition
                </div>
                <h1 className="text-white font-bold text-2xl md:text-3xl lg:text-5xl">
                    How your contribution is recognised?
                </h1>
                <div className="grid grid-cols-3 gap-4 text-center mt-12 recognition-about-data">
                    <div>
                        <div>
                            <h3>Founder</h3>
                            <div className="gradient-text">Rs. 10Cr</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h3>Co-Founder</h3>
                            <div className="gradient-text">Rs. 5-10Cr</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h3>Donor</h3>
                            <div className="gradient-text">Rs. 5Cr</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
