import { staticBaseURI } from "../../../config"

const RegistrationComplete = () => {
    return (
        <div className="py-20">
            <div className="flex justify-center">
                <img
                    src={`${staticBaseURI}/dists/images/success.gif`}
                    alt="complete"
                    className="w-28"
                />
            </div>
            <h1 className="text-white font-bold text-xl text-center mt-4 uppercase">
                Registration Complete
            </h1>
            <h3 className="text-center text-lg text-white font-bold">
                Login to your account
            </h3>
        </div>
    )
}

export default RegistrationComplete
