import GoogleLogin from "react-google-login"
import { useDispatch } from "react-redux"
import { FaGoogle } from "react-icons/fa"

import { socialLogin } from "../../../features/auth/authSlice"

const Google = ({ hide = false }) => {
    const dispatch = useDispatch()

    const responseGoogle = (response) => {
        dispatch(socialLogin({ data: response.profileObj, type: "google" }))
    }

    return (
        <>
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={(renderProps) => (
                    <a
                        href="#0"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="google-connect-btn flex"
                    >
                        <div className={hide ? `hidden md:block` : `block`}>
                            Connect With
                        </div>
                        <div
                            className={`mt-0 ${
                                hide ? `ml-0` : `ml-2`
                            } lg:ml-2 self-center`}
                        >
                            <FaGoogle />
                        </div>
                    </a>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
            />
        </>
    )
}

export default Google
