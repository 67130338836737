import { FaPlus, FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import SectionTitle from "../../../../components/Resume/SectionTitle"
import { openModal } from "../../../../features/modal/modalSlice"
import ExperienceForm from "./ExperienceForm"
import ExperienceItem from "./ExperienceItem"

const Experiences = ({ works }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="flex justify-between">
                <SectionTitle title="Experience" />

                <button
                    className="text-2xl text-blue-dark self-center  ml-3"
                    onClick={() => dispatch(openModal("newExperience"))}
                >
                    <FaPlus />
                </button>
            </div>
            <div className="mt-4">
                {works && works.length > 0 ? (
                    works.map((work) => (
                        <ExperienceItem key={work.id} work={work} />
                    ))
                ) : (
                    <div className="flex justify-left mt-4">
                        <button
                            className="text-sm bg-blue-dark px-6 py-3 rounded text-white flex"
                            onClick={() => dispatch(openModal("newExperience"))}
                        >
                            <FaRegEdit className="self-center mr-2" /> Add New
                            Experience
                        </button>
                    </div>
                )}
            </div>

            {isOpen && modalName === `newExperience` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">
                            Add New Experience
                        </h3>
                        <hr className="my-4" />
                        <ExperienceForm />
                    </>
                </Modal>
            )}
        </>
    )
}

export default Experiences
