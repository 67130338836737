import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"

import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import { addSkill } from "../../../../features/auth/authSlice"
import { closeModal } from "../../../../features/modal/modalSlice"

const SkillsForm = () => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state.auth)

    const handleSubmit = (values, actions) => {
        dispatch(addSkill({ values, actions })).then(() =>
            dispatch(closeModal())
        )
    }

    const skillSchema = Yup.object({
        name: Yup.string().required("Please enter a skill name"),
    })

    return (
        <div>
            <Formik
                initialValues={{
                    name: "",
                }}
                validationSchema={skillSchema}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions)
                }}
            >
                {(formikProps) => (
                    <Form>
                        <TextInput
                            name="name"
                            type="text"
                            placeholder="SKILL NAME"
                            color="dark"
                        />

                        <div className="flex justify-end mt-8">
                            <div className="flex space-x-4">
                                <button
                                    type="submit"
                                    className="text-white px-6 py-2 submit-btn"
                                    disabled={isLoading ? true : false}
                                >
                                    {isLoading ? (
                                        <div className="inline-flex">
                                            <Loader
                                                size="6"
                                                classes="mr-2 w-6 h-6 fill-blue-200"
                                            />
                                            <div className="self-center">
                                                Please wait...
                                            </div>
                                        </div>
                                    ) : (
                                        `Add Skill`
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default SkillsForm
