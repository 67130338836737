import React, { Component } from "react"
import { staticBaseURI } from "../../config"
import { WFDItem } from "./WFDItem"

class WaysFaqDonate extends Component {
    render() {
        const waysFaqDonate = [
            {
                id: 1,
                title: "Ways to donate",
                image: "./dists/images/wfd-1.jpg",
                url: `${staticBaseURI}/how-to-donate`,
            },
            {
                id: 2,
                title: "FAQ's",
                image: "./dists/images/wfd-2.jpg",
                url: `${staticBaseURI}/faq`,
            },
            {
                id: 3,
                title: "Donate Now",
                image: "./dists/images/wfd-3.jpg",
                url: `${staticBaseURI}/give`,
            },
        ]

        return (
            <section className="relative">
                <div className="ef-bg-green-gradient px-4 pt-8 ">
                    <div className="container mx-auto px-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-16">
                            {waysFaqDonate.map((item) => (
                                <WFDItem
                                    key={item.id}
                                    title={item.title}
                                    image={item.image}
                                    url={item.url}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className=" bg-blue-dark -mt-36 h-40"></div>
            </section>
        )
    }
}

export default WaysFaqDonate
