import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import {
    fetchPageContent,
    fetchTalks,
} from "../../features/tech-talks/techTalkSlice"
import About from "./About"
import OngoingTalks from "./OngoingTalks"
import PastTalks from "./PastTalks"
import "./tech-talks.css"
import UpcomingTalks from "./UpcomingTalks"

function TechTalks() {
    const dispatch = useDispatch()

    const { pageContent, ongoingTalks, upcomingTalks, pastTalks } = useSelector(
        (state) => state.techTalksPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("tech-talks"))
        dispatch(fetchTalks(8))
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <About about={pageContent.content} />
            <section className="bg-blue-semi-dark py-10">
                <div className="container mx-auto">
                    <div className="px-10 flex justify-start">
                        <div className="tt-header-title border-r-4 pr-4 text-white">
                            <h1 className="text-4xl font-bold">Tech Talks</h1>
                            <h3 className="text-2xl">Alumni Lecture Series</h3>
                        </div>
                    </div>
                </div>
                <UpcomingTalks talks={upcomingTalks} />
                <PastTalks talks={pastTalks} />
            </section>
            <OngoingTalks talks={ongoingTalks} />
        </>
    )
}

export default TechTalks
