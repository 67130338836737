import { apiBaseURI, staticBaseURI } from "../../config"

const AwardWinnerItem = ({ image, name, month, slug }) => {
    return (
        <div className="relative award-winner-item bg-dark py-4 px-8 rounded-xl">
            <a href={`${staticBaseURI}/alumni-of-the-month-details/${slug}`}>
                <img
                    src={`${apiBaseURI}${image}`}
                    alt={name}
                    className="rounded-full -mt-12"
                />
                <h4 className="text-white text-xl md:text-2xl lg:text-3xl font-bold mt-2">
                    {name}
                </h4>
                <h6 className="text-white md:text-lg lg:text-xl">
                    Award Month: {month}
                </h6>
                <div className="flex justify-center mt-6">
                    <button className="text-white uppercase absolute -bottom-4 font-semibold rounded-lg py-1 px-3 young-read-more-btn">
                        Read More
                    </button>
                </div>
            </a>
        </div>
    )
}

export default AwardWinnerItem
