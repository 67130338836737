import { BiPlus } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"

import Categories from "./Partials/Categories"
import Modal from "../../../components/Modal/Modal"
import { openModal } from "../../../features/modal/modalSlice"
import NewPostForm from "./Partials/NewPostForm"
import { redirectUser, staticBaseURI } from "../../../config"
import PostSuccess from "./Partials/PostSuccess"
import { resetFormSubmitted } from "../../../features/forum/forumSlice"
import PostsList from "./Partials/PostsList"

const Forum = () => {
    const dispatch = useDispatch()

    const { modalName, isOpen } = useSelector((state) => state.modal)
    const { login, isLoading, user } = useSelector((state) => state.auth)
    const { isLoading: forumFormLoading, formSubmitted } = useSelector(
        (state) => state.forum
    )

    const modalOpen = () => {
        dispatch(openModal(`newForumPost`))
        dispatch(resetFormSubmitted())
    }

    if (!isLoading && login && !user.profile_completed) {
        redirectUser(user)
    } else if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    return (
        <>
            <section className="bg-gray-200 py-20">
                <div className="container mx-auto px-4 lg:px-6">
                    <div className="grid grid-cols-4 gap-4">
                        <div className="grid-cols-1 col-span-4 md:col-span-1">
                            <button
                                onClick={() => modalOpen()}
                                className="flex justify-center text-md md:text-sm lg:text-base bg-blue-dark text-white px-4 py-2 w-full hover:bg-blue-800 mb-4 rounded-md"
                            >
                                <BiPlus className="text-xl mr-2 mt-[2px]" />
                                <span className="">Create New Post</span>
                            </button>
                            <Categories />
                        </div>
                        <div className="grid-cols-3 col-span-4 md:col-span-3">
                            <PostsList />
                        </div>
                    </div>
                </div>
            </section>

            {isOpen && modalName === `newForumPost` && (
                <Modal>
                    {!forumFormLoading && formSubmitted ? (
                        <PostSuccess />
                    ) : (
                        <>
                            <h3 className="font-bold text-2xl">New Post</h3>
                            <hr className="my-4" />
                            <NewPostForm />
                        </>
                    )}
                </Modal>
            )}
        </>
    )
}

export default Forum
