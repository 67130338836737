import "photoswipe/dist/photoswipe.css"
import { Gallery, Item } from "react-photoswipe-gallery"

import { apiBaseURI } from "../../config"

const AlbumImages = ({ images = [] }) => {
    const CSSProperties = {
        width: "640px",
        height: "300px",
    }

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <Gallery>
                {images.map((image) => (
                    <Item
                        key={image.id}
                        original={`${apiBaseURI}${image.image_url}`}
                        thumbnail={`${apiBaseURI}${image.image_url}`}
                        cropped={true}
                    >
                        {({ ref, open }) => (
                            <img
                                alt={`album-img-${image.id}`}
                                {...CSSProperties}
                                ref={ref}
                                onClick={open}
                                src={`${apiBaseURI}${image.image_url}`}
                            />
                        )}
                    </Item>
                ))}
            </Gallery>
        </div>
    )
}

export default AlbumImages
