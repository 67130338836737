import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
    staticBaseURI,
} from "../../config"

const AwardWinnerItem = ({ image, name, year, slug }) => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div ref={ref}>
            <a
                href={`${staticBaseURI}/distinguished-alumni-service-details/${slug}`}
            >
                <motion.div
                    initial={framerAnimationInitial}
                    animate={controls}
                    transition={{
                        duration: 0.3,
                        delay: 0.3,
                    }}
                    className="relative award-winner-item bg-blue-dark py-4 px-8 rounded-xl"
                >
                    <img
                        src={`${apiBaseURI}${image}`}
                        alt={name}
                        className="-mt-12"
                    />
                    <h4 className="text-white text-xl md:text-2xl lg:text-3xl font-bold mt-2 h-20">
                        {name}
                    </h4>
                    <h6 className="text-white md:text-lg lg:text-xl">
                        Award Year: {year}
                    </h6>
                    <div className="flex justify-center mt-6">
                        <a
                            href={`${staticBaseURI}/distinguished-alumni-service-details/${slug}`}
                            className="text-white uppercase absolute -bottom-4 font-semibold rounded-lg py-1 px-3 young-read-more-btn"
                        >
                            Read More
                        </a>
                    </div>
                </motion.div>
            </a>
        </div>
    )
}

export default AwardWinnerItem
