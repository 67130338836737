import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DOMPurify from "dompurify"
import { Helmet } from "react-helmet-async"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { useParams } from "react-router-dom"
import { fetchQuickLinkDetails } from "../../features/config/configSlice"

const QuickLinks = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const { quickLinkDetails } = useSelector((state) => state.config)

    useEffect(() => {
        dispatch(fetchQuickLinkDetails(params.slug))
    }, [dispatch, params.slug])

    let cleanContent = DOMPurify.sanitize(quickLinkDetails?.content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <>
            <Helmet>
                <title>{quickLinkDetails?.title}</title>
            </Helmet>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${quickLinkDetails?.image_url}`}
            />
            <section className="bg-black px-4 py-20 relative">
                <div className="container mx-auto px-6 flex justify-start">
                    <div className=" text-white">
                        <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl">
                            {quickLinkDetails?.title}
                        </h1>
                    </div>
                </div>
            </section>
            <section className="px-4 pt-0 pb-16 bg-blue-dark">
                <div
                    className="container mx-auto relative bg-white -mt-8 p-8 rounded-2xl text-base"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </section>
        </>
    )
}

export default QuickLinks
