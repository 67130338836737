import { api } from "../../api"

//fetch
const fetchByeLaw = async () => {
    const url = `/bye-laws`
    const response = await api.get(url)
    return response.data
}

const byelawsService = {
    fetchByeLaw,
}

export default byelawsService
