import { api } from "../../api"

//fetch alumni news
const fetchNews = async (limit) => {
    const url = `/news/${limit}`
    const response = await api.get(url)
    return response.data.data
}

//fetch distinguished alumns
const fetchDistinguishedAlumns = async (limit) => {
    const url = `/distinguished-alumns/${limit}`
    const response = await api.get(url)
    return response.data.data
}

//fetch events
const fetchEvents = async (limit) => {
    const url = `/events/${limit}`
    const response = await api.get(url)
    return response.data
}

//fetch slides
const fetchSlides = async () => {
    const url = `/slides`
    const response = await api.get(url)
    return response.data
}

//fetch memories
const fetchMemories = async () => {
    const url = `/memories-images`
    const response = await api.get(url)
    return response.data
}

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

const homepageService = {
    fetchNews,
    fetchDistinguishedAlumns,
    fetchEvents,
    fetchSlides,
    fetchMemories,
    fetchPageContent,
}

export default homepageService
