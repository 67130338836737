import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import AlumniDayDetailsBody from "./AlumniDayDetailsBody"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"
import { fetchAlumniDayDetails } from "../../features/alumni-day/alumniDaySlice"

const AlumniDayDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { alumniDayDetails } = useSelector((state) => state.alumniDayPage)

    useEffect(() => {
        dispatch(fetchAlumniDayDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{alumniDayDetails.meta_title}</title>
                <meta
                    name="description"
                    content={alumniDayDetails.meta_description}
                />
                <meta
                    name="keywords"
                    content={alumniDayDetails.meta_keywords}
                />
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${alumniDayDetails.image_url}`}
            />
            <AlumniDayDetailsBody
                content={alumniDayDetails.description}
                title={alumniDayDetails.title}
                year={alumniDayDetails.year}
            />
        </>
    )
}

export default AlumniDayDetails
