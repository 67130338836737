import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchNewsletters } from "../../features/newsletters/newsletterSlice"
import NewsLetterItem from "./NewsLetterItem"

const ArchivesList = () => {
    const dispatch = useDispatch()

    const { newsletters, currentPage } = useSelector(
        (state) => state.newsletterPage
    )

    useEffect(() => {
        dispatch(fetchNewsletters({ status: 2, currentPage }))
    }, [dispatch, currentPage])

    return (
        <section className="bg-blue-dark px-4 py-16">
            <div className="container mx-auto px-6">
                <h1 className="text-white text-2xl md:text-3xl lg:text-5xl uppercase underline underline-offset-8 decoration-cyan-300">
                    Newsletter Archive
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-16 gap-8">
                    {newsletters.map((newsletter) => (
                        <NewsLetterItem
                            key={`newsletter-${newsletter.id}`}
                            title={newsletter.title}
                            image={newsletter.image_url}
                            slug={newsletter.slug}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ArchivesList
