import * as DOMPurify from "dompurify"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import {
    apiBaseURI,
    framerAnimationFinal,
    framerAnimationInitial,
} from "../../config"

const ClassDetails = ({ details }) => {
    let cleanContent = DOMPurify.sanitize(details.description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <div
            className="bg-white p-4 md:p-8 mt-32 md:mt-48 rounded-xl md:rounded-2xl"
            ref={ref}
        >
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.3,
                }}
                className="flex justify-center"
            >
                <img
                    alt={details.title}
                    src={`${apiBaseURI}${details.image_url}`}
                    className="relative -mt-24 md:-mt-44 rounded-xl md:rounded-2xl"
                />
            </motion.div>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.4,
                }}
                className="flex justify-start mt-12"
            >
                <div className="border-r-4 border-r-black pr-4">
                    <h3 className="text-2xl md:text-5xl font-semibold">
                        Class Of
                    </h3>
                    <h2 className="text-4xl md:text-7xl font-bold">
                        {details.year}
                    </h2>
                </div>
                <div className="pl-4">
                    <h3 className="font-semibold text-2xl">
                        {details.created_month}
                    </h3>
                    <h3 className="font-bold text-4xl">
                        {details.created_date}, {details.created_year}
                    </h3>
                </div>
            </motion.div>
            <motion.div
                initial={framerAnimationInitial}
                animate={controls}
                transition={{
                    duration: 0.3,
                    delay: 0.5,
                }}
                className="mt-8 text-lg"
                dangerouslySetInnerHTML={createMarkup(cleanContent)}
            ></motion.div>
        </div>
    )
}

export default ClassDetails
