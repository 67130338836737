import * as DOMPurify from "dompurify"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const About = ({ about }) => {
    let cleanContent = DOMPurify.sanitize(about)

    const createMarkup = (body) => {
        return { __html: body }
    }

    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <section className="relative bg-blue-dark px-4 py-8">
            <div className="container mx-auto p-6 px-4">
                <div className="flex justify-end mt-10">
                    <div className="bg-text top-2/4 left-8 -translate-y-1/2 about-bg-text">
                        alumni
                    </div>
                    <div
                        className="text-white w-full md:w-2/3 text-xl"
                        ref={ref}
                    >
                        <motion.div
                            initial={framerAnimationInitial}
                            animate={controls}
                            transition={{
                                duration: 0.3,
                                delay: 0.2,
                            }}
                            dangerouslySetInnerHTML={createMarkup(cleanContent)}
                        ></motion.div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
