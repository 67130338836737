import React from "react"
import { apiBaseURI } from "../../config"

export const FounderItem = ({ image, designation, batch, name }) => {
    return (
        <div className="founder-item mx-2">
            <div className="flex justify-center">
                <img
                    src={`${apiBaseURI}${image}`}
                    className="rounded-full w-[70%]"
                    alt={name}
                />
            </div>
            <div className="text-center mt-8 ">
                <h1 className="font-bold text-3xl">{name}</h1>
                <h3 className="text-2xl">{designation}</h3>
                <h3 className="font-bold text-2xl">(Batch of {batch})</h3>
            </div>
        </div>
    )
}
