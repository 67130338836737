import React from "react"
import { apiBaseURI, staticBaseURI } from "../../config"

const TalkItem = ({ image, title, date, time, slug }) => {
    return (
        <div className="talk-item mx-4">
            <a href={`${staticBaseURI}/tech-talk-details/${slug}`}>
                <img src={`${apiBaseURI}${image}`} alt={title} />
                <div className="text-white mt-4">
                    <h2 className="text-2xl font-bold">{title}</h2>
                    <h3 className="text-lg">
                        <span className="font-bold">{date}</span> |{" "}
                        <span>{time}</span>
                    </h3>
                </div>
            </a>
        </div>
    )
}

export default TalkItem
