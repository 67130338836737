import { FaPlus } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import SectionTitle from "../../../../components/Resume/SectionTitle"
import { openModal } from "../../../../features/modal/modalSlice"
import EducationForm from "./EducationForm"
import EducationItem from "./EducationItem"

const Education = ({ education }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="flex justify-between">
                <SectionTitle title="Education" />
                <button
                    className="text-2xl text-blue-dark self-center  ml-3"
                    onClick={() => dispatch(openModal("newEducation"))}
                >
                    <FaPlus />
                </button>
            </div>
            <div className="mt-4">
                {education.map((edu) => (
                    <EducationItem key={edu.id} edu={edu} />
                ))}
            </div>
            {isOpen && modalName === `newEducation` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">
                            Add New Education
                        </h3>
                        <hr className="my-4" />
                        <EducationForm />
                    </>
                </Modal>
            )}
        </>
    )
}

export default Education
