import React from "react"

const PostSkeleton = () => {
    return (
        <div
            role="status"
            className="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-lg shadow animate-pulse dark:divide-gray-400 md:p-6 dark:border-gray-300 mb-4 bg-white"
        >
            <div className="flex items-center justify-between">
                <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
                <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12"></div>
            </div>
            <hr className="my-4" />
            <div className="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
            <div className="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
            <div className="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-400"></div>
            <div className="flex justify-between items-center  border-none">
                <div className="flex items-center">
                    <svg
                        className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-400"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <div className="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 mr-3"></div>
                </div>
                <div className="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-400"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default PostSkeleton
