import { api } from "../../api"

const newsletterSignup = async (email) => {
    const url = `/newsletter-signup`

    const formdata = new FormData()
    formdata.append("email", email)

    const response = await api.post(url, formdata)

    return response
}

const newsletterSignupService = {
    newsletterSignup,
}

export default newsletterSignupService
