import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch Chapters
const fetchChapters = async (limit, page) => {
    const url = `/chapters/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch chapter details
const fetchChapterDetails = async (slug) => {
    const url = `/chapter-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const chapterService = {
    fetchChapters,
    fetchPageContent,
    fetchChapterDetails,
}

export default chapterService
