import React from "react"
import { apiBaseURI, staticBaseURI } from "../../config"

const NewsletterVideo = ({ videoType, youtubeId, video, title }) => {
    function playVideo() {
        const video = document.querySelector("#video")
        video.play()
        const playBtnImage = document.querySelector("#play-btn-img")
        playBtnImage.style.display = "none"
    }

    return (
        <div className="relative htd-transfer-info-bg p-4 md:p-6 lg:p-8 rounded-2xl -mx-12 mt-16 mb-12">
            <div className="flex justify-center">
                <div className="max-w-full md:max-w-[50%]">
                    {videoType === "yt" ? (
                        <div>
                            <iframe
                                width="100%"
                                height="500"
                                src={`https://www.youtube.com/embed/${youtubeId}`}
                                title={title}
                                frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ) : (
                        <div className="relative">
                            <video
                                width="100%"
                                height="300"
                                controls
                                name="media"
                                id="video"
                                src={`${apiBaseURI}${video}`}
                            />
                            <div
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                id="play-btn-img"
                            >
                                <img
                                    src={`${staticBaseURI}/dists/images/video-play.png`}
                                    className="w-16 md:w-32 cursor-pointer"
                                    alt="play button"
                                    onClick={() => playVideo()}
                                />
                            </div>
                        </div>
                    )}
                    <div className="font-bold text-xl md:text-2xl text-center mt-3">
                        {title}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsletterVideo
