import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { gapi } from "gapi-script"

import { staticBaseURI } from "../../config"

import { useCallback } from "react"
import Facebook from "./RguNetwork/Facebook"
import Google from "./RguNetwork/Google"
import Linkedin from "./RguNetwork/Linkedin"

const RGUNetwork = () => {
    const { login, isLoading, user } = useSelector((state) => state.auth)

    const loginSuccess = useCallback(() => {
        if (login && !isLoading && user.profile_completed === 1) {
            window.location.href = `${staticBaseURI}/dashboard`
        } else if (login && !isLoading && user.profile_completed === 0) {
            if (user.profile_step === 0)
                window.location.href = `${staticBaseURI}/profile/basic-details`
            else if (user.profile_step === 1)
                window.location.href = `${staticBaseURI}/profile/education-details`
            else if (user.profile_step === 2)
                window.location.href = `${staticBaseURI}/profile/work-details`
        }
    }, [login, user, isLoading])

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            })
        }
        gapi.load("client:auth2", initClient)

        if (login && user) {
            //loginSuccess()
        }
    }, [login, user, loginSuccess])

    const toggle = () => {
        const elem = document.querySelector(".rgu-network-contents")

        if (elem.classList.contains("hidden")) {
            elem.classList.remove("hidden")
            elem.classList.add("block")
        } else {
            elem.classList.remove("block")
            elem.classList.add("hidden")
        }
    }

    return (
        <>
            <div className="relative">
                <button
                    className="rgu-network-btn bg-blue-dark text-white px-6 py-3 rounded-md font-bold text-sm"
                    onClick={() => toggle()}
                >
                    <span className="text-blue-light">RGU</span> Network
                </button>
                <div className="p-4 bg-white hidden rgu-network-contents">
                    <div className="flex justify-between md:justify-start mt-4 md:mt-0">
                        <div className=" text-sm md:text-lg self-center">
                            Join{" "}
                            <span className="underline text-blue-semi-dark font-bold">
                                RGU Alumni Network
                            </span>
                        </div>
                        <div className="ml-2 md:ml-8 rgu-network-buttons">
                            {/* <a
                            href={`http://127.0.0.1:8000/api/auth/facebook/login`}
                            className="facebook-connect-btn flex"
                        >
                            <div className="hidden lg:block">Connect With</div>
                            <div className="mt-0 ml-0 lg:ml-2 self-center">
                                <FaFacebook />
                            </div>
                        </a> */}

                            <Facebook hide={true} />

                            <Google hide={true} />

                            <Linkedin hide={true} />
                        </div>
                        <a
                            href={`${staticBaseURI}/login`}
                            className="uppercase text-xs font-semibold md:text-base login-btn px-4 py-2"
                        >
                            Login with OTP
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RGUNetwork
