import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/alumni-of-the-month/alumniOfTheMonthSlice"
import About from "./About"
import AwardWinners from "./AwardWinners"
import Pagination from "./Pagination"
import "./young-alumni-awards.css"

const AlumniOfTheMonth = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.alumniOfTheMonthpage)

    useEffect(() => {
        dispatch(fetchPageContent("alumni-of-the-month"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                title="Alumni Of The Month"
                image={`${apiBaseURI}${pageContent.image_url}`}
                position="center"
            />
            <About about={pageContent.content} />
            <AwardWinners />
            <Pagination />
        </>
    )
}

export default AlumniOfTheMonth
