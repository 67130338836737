import DOMPurify from "dompurify"
import GuestOfHonour from "./GuestOfHonour"

const Details = ({ description, guest_name, guest_image, guest_info }) => {
    let cleanContent = DOMPurify.sanitize(description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <section className="evd-bg-blue px-4 py-16">
            <div className="container mx-auto px-6">
                <div className="flex justify-start">
                    <div className="text-white border-r-4 border-r-white pr-8">
                        <h2 className="text-3xl md:text-4xl lg:text-6xl">
                            Event
                        </h2>
                        <h2 className="text-3xl md:text-4xl lg:text-6xl">
                            Details
                        </h2>
                    </div>
                </div>
                <div
                    className="text-white text-base md:text-lg mt-8"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
                <GuestOfHonour
                    guest_name={guest_name}
                    guest_image={guest_image}
                    guest_info={guest_info}
                />
            </div>
        </section>
    )
}

export default Details
