import { FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import UpdateAbout from "./UpdateAbout"
import Modal from "../../../../components/Modal/Modal"
import { openModal } from "../../../../features/modal/modalSlice"
import SectionTitle from "../../../../components/Resume/SectionTitle"

const About = ({ about }) => {
    const dispatch = useDispatch()

    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="flex justify-between">
                <SectionTitle title="About Me" />
                {about && (
                    <button
                        className="text-2xl text-blue-dark self-center  ml-3"
                        onClick={() => dispatch(openModal("editAbout"))}
                    >
                        <FaRegEdit />
                    </button>
                )}
            </div>
            <div>
                {about ? (
                    <div className="mt-4">{about}</div>
                ) : (
                    <div className="flex justify-center align-middle py-16">
                        <button
                            className="text-sm bg-blue-dark px-6 py-3 rounded text-white flex"
                            onClick={() => dispatch(openModal("editAbout"))}
                        >
                            <FaRegEdit className="self-center mr-2" /> Add About
                        </button>
                    </div>
                )}
            </div>

            {isOpen && modalName === "editAbout" && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Update About</h3>
                        <hr className="my-4" />
                        <UpdateAbout content={about} />
                    </>
                </Modal>
            )}
        </>
    )
}

export default About
