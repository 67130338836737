import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/alumni-affairs/alumniAffairsSlice"
import About from "./About"
import "./alumni-affairs.css"
import Introduction from "./Introduction"
import MeetTheTeam from "./MeetTheTeam"
import TopStories from "./TopStories"

const AlumniAffairs = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.alumniAffairsPage)

    useEffect(() => {
        dispatch(fetchPageContent("alumni-affairs"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About />
            <section className="aaf-split-bg px-4 py-16">
                <Introduction about={pageContent.content} />
                <TopStories />
                <MeetTheTeam />
            </section>
        </>
    )
}

export default AlumniAffairs
