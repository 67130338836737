import { staticBaseURI } from "../../config"
import "./custom-arrows.css"

export const PrevArrow = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow prev-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/left.png`}
                    className=" w-12"
                    alt="left-arrow"
                />
            </div>
        </div>
    )
}

export const NextArrow = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow next-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/right.png`}
                    className="w-12"
                    alt="right-arrow"
                />
            </div>
        </div>
    )
}

export const UpArrow = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow prev-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/top.png`}
                    className=" w-12"
                    alt="up-arrow"
                />
            </div>
        </div>
    )
}

export const DownArrow = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow next-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/bottom.png`}
                    className="w-12"
                    alt="down-arrow"
                />
            </div>
        </div>
    )
}

export const PrevArrowBlack = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow prev-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/left-black.png`}
                    className=" w-12"
                    alt="left-arrow"
                />
            </div>
        </div>
    )
}

export const NextArrowBlack = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow next-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/right-black.png`}
                    className="w-12"
                    alt="right-arrow"
                />
            </div>
        </div>
    )
}

export const PrevArrowGradient = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow prev-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/left-gradient.png`}
                    className=" w-12"
                    alt="left-arrow"
                />
            </div>
        </div>
    )
}

export const NextArrowGradient = (props) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow next-arrow"
            style={{ ...style }}
            onClick={onClick}
        >
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/right-gradient.png`}
                    className="w-12"
                    alt="right-arrow"
                />
            </div>
        </div>
    )
}

export const HeroPrevArrow = (props) => {
    const { onClick } = props
    return (
        <div className="slick-arrow prev-arrow" onClick={onClick}>
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/left.png`}
                    className=" w-12"
                    alt="left-arrow"
                />
            </div>
        </div>
    )
}

export const HeroNextArrow = (props) => {
    const { onClick } = props
    return (
        <div className="slick-arrow next-arrow" onClick={onClick}>
            <div className="carousel-nav-btn">
                <img
                    src={`${staticBaseURI}/dists/images/right.png`}
                    className="w-12"
                    alt="right-arrow"
                />
            </div>
        </div>
    )
}
