import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "../../components/PageHeader"
import FaqItem from "./FaqItem"
import { fetchFaq, fetchPageContent } from "../../features/faq/faqSlice"
import { apiBaseURI } from "../../config"
import { Helmet } from "react-helmet-async"

const Faq = () => {
    const dispatch = useDispatch()

    const { faqs, pageContent } = useSelector((state) => state.faqPage)

    useEffect(() => {
        dispatch(fetchPageContent("faq"))
        dispatch(fetchFaq())
    }, [dispatch])

    return (
        <>
            <Helmet>
                <title>FAQ's</title>
            </Helmet>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={true}
                position="center"
                title="FAQ's"
            />
            <section className="px-4 light-blue-bg py-20">
                <div className="container mx-auto px-6">
                    <div className="rounded overflow-hidden mt-4 md:mt-8">
                        {faqs.map((faq, index) => (
                            <FaqItem
                                index={index + 1}
                                question={faq.question}
                                answer={faq.answer}
                                key={faq.id}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq
