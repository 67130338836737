import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { apiBaseURI } from "../../config"
import {
    fetchHowDonationHelps,
    fetchPageContent,
    fetchWhatEndowmentAllows,
} from "../../features/why-donate-us/whyDonateUsSlice"
import About from "./About"
import EndowmentPoints from "./EndowmentPoints"
import KnowMore from "./KnowMore"
import PageHeader from "./PageHeader"
import "./why-donate.css"
import YourDonation from "./YourDonation"

const WhyDonate = () => {
    const dispatch = useDispatch()

    const { pageContent, howDonationHelps, whatEndowmentAllows } = useSelector(
        (state) => state.whyDonateUsPage
    )

    useEffect(() => {
        dispatch(fetchPageContent("why-donate"))
        dispatch(fetchHowDonationHelps())
        dispatch(fetchWhatEndowmentAllows())
    }, [dispatch])

    return (
        <>
            <PageHeader
                image={`${apiBaseURI}${pageContent.image_url}`}
                text={false}
            />
            <section className="ef-blue-bg-gradient px-4 py-10">
                <About />
                <YourDonation points={howDonationHelps} />
            </section>
            <div className="bg-white py-8 -mt-20"></div>
            <EndowmentPoints points={whatEndowmentAllows} />
            <KnowMore />
        </>
    )
}

export default WhyDonate
