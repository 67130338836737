const DropdownContentHead = ({ link = "#0", text }) => {
    return (
        <div className="flex self-center">
            <span className="blue-dot rounded-full h-3 w-3 mt-1 md:mt-2 mr-2 shrink-0"></span>
            <a
                href={link}
                className="font-bold text-base leading-2 md:text-lg h-10"
            >
                {text}
            </a>
        </div>
    )
}

export default DropdownContentHead
