import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchNotices } from "../../features/noticeboard/noticeboardSlice"

import NoticeItem from "./NoticeItem"
import Pagination from "./Pagination"

function NoticeWrapper() {
    const dispatch = useDispatch()

    const { notices, currentPage } = useSelector(
        (state) => state.noticeboardPage
    )

    useEffect(() => {
        dispatch(fetchNotices(currentPage))
    }, [dispatch, currentPage])

    return (
        <section
            className="nb-slipt-bg-colors px-3 md:px-4 pt-0 pb-20"
            id="winners-list"
        >
            <div className="container mx-auto px-3 md:px-6 relative -mt-8">
                {notices.map((notice) => (
                    <NoticeItem
                        key={notice.id}
                        title={notice.title}
                        date={notice.notice_date}
                        month={notice.notice_month}
                        year={notice.notice_year}
                        slug={notice.slug}
                    />
                ))}
            </div>
            <Pagination />
        </section>
    )
}

export default NoticeWrapper
