import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import memoriesService from "./memoriesService"

const initialState = {
    memories: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    memoryDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchMemories = createAsyncThunk(
    "memories/fetchMemories",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().memoriesPage
            return await memoriesService.fetchMemories(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "memories/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await memoriesService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchMemoryDetails = createAsyncThunk(
    "memories/fetchMemoryDetails",
    async (slug, thunkAPI) => {
        try {
            return await memoriesService.fetchMemoryDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const memoriesSlice = createSlice({
    name: "memories",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMemories.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchMemories.fulfilled, (state, action) => {
                state.isLoading = false
                state.memories = [...state.memories, ...action.payload.data]
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchMemories.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.memories = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchMemoryDetails.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchMemoryDetails.fulfilled, (state, action) => {
                state.isLoading = false
                state.memoryDetails = action.payload
            })
            .addCase(fetchMemoryDetails.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.errorMessage = action.payload
            })
    },
})

export const { setCurrentPage } = memoriesSlice.actions
export default memoriesSlice.reducer
