import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import whyDonateUsService from "./whyDonateUsService"

const initialState = {
    pageContent: {},
    howDonationHelps: [],
    isLoading: false,
    isError: false,
    whatEndowmentAllows: [],
}

export const fetchHowDonationHelps = createAsyncThunk(
    "whyDonateUs/fetchHowDonationHelps",
    async (_, thunkAPI) => {
        try {
            return await whyDonateUsService.fetchHowDonationHelps()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchWhatEndowmentAllows = createAsyncThunk(
    "whyDonateUs/fetchWhatEndowmentAllows",
    async (_, thunkAPI) => {
        try {
            return await whyDonateUsService.fetchWhatEndowmentAllows()
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "whyDonateUs/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await whyDonateUsService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const whyDonateUsSlice = createSlice({
    name: "whyDonateUs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWhatEndowmentAllows.fulfilled, (state, action) => {
                state.whatEndowmentAllows = action.payload
            })
            .addCase(fetchWhatEndowmentAllows.rejected, (state) => {
                state.whatEndowmentAllows = []
            })
            .addCase(fetchHowDonationHelps.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchHowDonationHelps.fulfilled, (state, action) => {
                state.isLoading = false
                state.howDonationHelps = action.payload
            })
            .addCase(fetchHowDonationHelps.rejected, (state, action) => {
                state.isError = true
                state.errorMessage = action.payload
                state.isLoading = false
                state.howDonationHelps = []
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
    },
})

export default whyDonateUsSlice.reducer
