import React from "react"
import AddCommentBtn from "./AddCommentBtn"

const NoComments = () => {
    return (
        <div className="text-center my-16">
            <h3 className="font-semibold text-base md:text-lg mb-4">
                No comments yet. Be the first one to comment.
            </h3>
            <AddCommentBtn />
        </div>
    )
}

export default NoComments
