import { Formik, Form } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"

import Steps from "../Steps/Steps"
import TextInput from "../../../../components/Inputs/TextInput"
import Loader from "../../../../components/Loader/Loader"
import "./profile-forms.css"
import { scrollTop, staticBaseURI } from "../../../../config"
import validateEducationDetails from "./validation/validateEducationDetails"
import { useEffect } from "react"
import {
    changeEditRow,
    changeIsEdit,
    editEducationDetails,
    fetchEducationDetails,
    submitEducationDetails,
} from "../../../../features/auth/authSlice"
import EducationItem from "./EducationItem"

const EducationDetails = () => {
    const dispatch = useDispatch()

    const { login, isLoading, educationDetails, isEdit, editRow } = useSelector(
        (state) => state.auth
    )

    useEffect(() => {
        scrollTop()
        dispatch(fetchEducationDetails())
    }, [dispatch])

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    const setIsEdit = () => {
        dispatch(changeIsEdit())
    }

    const setEditRow = (id) => {
        dispatch(changeEditRow(id))
    }

    const handleSubmit = (values, actions) => {
        if (isEdit) {
            dispatch(editEducationDetails({ values, actions }))
        } else {
            dispatch(submitEducationDetails({ values, actions }))
        }
    }

    const initialValues = {
        college: "",
        course: "",
        from: "",
        to: "",
    }

    const selectedRow = educationDetails.find((row) => row.id === editRow)

    let editRowValues = {
        college: "",
        course: "",
        from: "",
        to: "",
        id: "",
    }

    if (isEdit && selectedRow) {
        editRowValues = {
            college: selectedRow.college_name,
            course: selectedRow.course_name,
            from: new Date(`${selectedRow.start}-01-01`),
            to: new Date(`${selectedRow.end}-01-01`),
            id: selectedRow.id,
        }
    }

    return (
        <>
            <div className="grid grid-cols-5">
                <div className="col-span-5 md:col-span-2">
                    <Steps step={2} />
                </div>
                <motion.div
                    initial={{ opacity: 0, x: 300 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                    className="col-span-5 md:col-span-3 py-16 px-8"
                >
                    <h1 className="font-bold text-4xl">Education Details</h1>
                    <h3 className="text-lg text-gray-700">
                        Please enter your education details
                    </h3>
                    <div>
                        <Formik
                            initialValues={
                                isEdit ? editRowValues : initialValues
                            }
                            validationSchema={validateEducationDetails}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                handleSubmit(values, actions)
                            }}
                        >
                            {(formikProps: FormikProps<any>) => (
                                <Form className="pt-8">
                                    <TextInput
                                        name="college"
                                        type="text"
                                        placeholder="COLLEGE NAME"
                                        color="dark"
                                    />
                                    <TextInput
                                        name="course"
                                        type="text"
                                        placeholder="COURSE NAME"
                                        color="dark"
                                    />
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <div className="w-full">
                                            <label>From: </label>
                                            <DatePicker
                                                selected={
                                                    formikProps.values.from
                                                }
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "from",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="text-input"
                                                placeholderText="Click to select year"
                                            />
                                            {formikProps.touched.from &&
                                            formikProps.errors.from ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.from}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="w-full">
                                            <label>To: </label>
                                            <DatePicker
                                                selected={formikProps.values.to}
                                                onChange={(date) =>
                                                    formikProps.setFieldValue(
                                                        "to",
                                                        date
                                                    )
                                                }
                                                maxDate={new Date()}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="text-input"
                                                placeholderText="Click to select year"
                                            />
                                            {formikProps.touched.to &&
                                            formikProps.errors.to ? (
                                                <div className="input-error ">
                                                    {formikProps.errors.to}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex justify-end mt-8">
                                        <button
                                            type="submit"
                                            className="text-white px-6 py-2 submit-btn"
                                            disabled={isLoading ? true : false}
                                        >
                                            {isLoading ? (
                                                <div className="inline-flex">
                                                    <Loader
                                                        size="6"
                                                        classes="mr-2 w-6 h-6 fill-blue-200"
                                                    />
                                                    <div className="self-center">
                                                        Please wait...
                                                    </div>
                                                </div>
                                            ) : (
                                                `Add Details`
                                            )}
                                        </button>
                                        {/* <a
                                            href={`${staticBaseURI}/profile/work-details`}
                                            className="text-white px-6 py-2 proceed-btn ml-2"
                                        >
                                            Save & Continue
                                        </a> */}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    {educationDetails && educationDetails.length > 0 && (
                        <div className="mt-10">
                            {educationDetails.map((edu) => (
                                <EducationItem
                                    key={edu.id}
                                    college={edu.college_name}
                                    course={edu.course_name}
                                    from={edu.start}
                                    to={edu.end}
                                    id={edu.id}
                                    isEdit={setIsEdit}
                                    editRow={setEditRow}
                                />
                            ))}
                            <div className="flex justify-between mt-8">
                                <Link
                                    to={`/profile/basic-details`}
                                    className="text-white px-6 py-2 proceed-btn ml-2"
                                >
                                    Go Back
                                </Link>
                                <Link
                                    to={`/profile/work-details`}
                                    className="text-white px-6 py-2 proceed-btn ml-2"
                                >
                                    Save & Continue
                                </Link>
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>
        </>
    )
}

export default EducationDetails
