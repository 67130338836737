import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import distinguishedAlumniServiceAwardService from "./distinguishedAlumniServiceAwardService"

const initialState = {
    alumns: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 24,
    alumniDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchAlumns = createAsyncThunk(
    "distinguishedAlumniServiceAward/fetchAlumns",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().distinguishedAlumniServiceAwardPage
            return await distinguishedAlumniServiceAwardService.fetchAlumns(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "distinguishedAlumniServiceAward/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await distinguishedAlumniServiceAwardService.fetchPageContent(
                slug
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAlumniDetails = createAsyncThunk(
    "distinguishedAlumniServiceAward/fetchAlumniDetails",
    async (slug, thunkAPI) => {
        try {
            return await distinguishedAlumniServiceAwardService.fetchAlumniDetails(
                slug
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const distinguishedAlumniServiceAwardSlice = createSlice({
    name: "distinguishedAlumniServiceAward",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAlumns.pending, (state) => {
                state.isLoading = true
                state.alumns = []
            })
            .addCase(fetchAlumns.fulfilled, (state, action) => {
                state.isLoading = false
                state.alumns = action.payload.data
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchAlumns.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.alumns = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchAlumniDetails.fulfilled, (state, action) => {
                state.alumniDetails = action.payload
            })
            .addCase(fetchAlumniDetails.rejected, (state) => {
                state.alumniDetails = {}
            })
    },
})

export const { setCurrentPage } = distinguishedAlumniServiceAwardSlice.actions
export default distinguishedAlumniServiceAwardSlice.reducer
