import { api } from "../../api"

//fetch menu-settings
const fetchMenuSettings = async () => {
    const url = `/menu-settings`
    const response = await api.get(url)
    return response.data
}

//fetch Courses
const fetchCourses = async (dept_id) => {
    const url = `/courses/${dept_id}`
    const response = await api.get(url)
    return response.data
}

//fetch Departments
const fetchDepartments = async () => {
    const url = `/departments`
    const response = await api.get(url)
    return response.data
}

//fetch Countries
const fetchCountries = async () => {
    const url = `/countries`
    const response = await api.get(url)
    return response.data
}

//fetch states
const fetchStates = async (country_id) => {
    const url = `/states/${country_id}`
    const response = await api.get(url)
    return response.data
}

//fetch Cities
const fetchCities = async (state_id) => {
    const url = `/cities/${state_id}`
    const response = await api.get(url)
    return response.data
}

//fetch Bank Transfer Details
const fetchBankTransferDetails = async () => {
    const url = `/bank-transfer-details`
    const response = await api.get(url)
    return response.data
}

//fetch quick links
const fetchQuickLinks = async () => {
    const url = `/quick-links`
    const response = await api.get(url)
    return response.data
}

//fetch quick link details
const fetchQuickLinkDetails = async (slug) => {
    const url = `/quick-link/${slug}`
    const response = await api.get(url)
    return response.data
}

const configService = {
    fetchMenuSettings,
    fetchCourses,
    fetchDepartments,
    fetchCountries,
    fetchStates,
    fetchCities,
    fetchBankTransferDetails,
    fetchQuickLinks,
    fetchQuickLinkDetails,
}

export default configService
