import { apiBaseURI } from "../../config"

const BearerItem = ({ image, name, designation, position, course, batch }) => {
    return (
        <div className="relative award-winner-item bg-black py-4 px-8 rounded-xl">
            <img src={`${apiBaseURI}${image}`} alt={name} className="-mt-8" />
            <h4 className="text-white text-xl md:text-2xl lg:text-3xl font-bold mt-2">
                {name}
            </h4>
            <h6 className="text-white md:text-lg lg:text-xl">
                {course} - {batch}
            </h6>
            <h6 className="text-white md:text-lg lg:text-xl">{designation}</h6>
            <h6 className="text-white md:text-lg lg:text-xl">
                Position: {position}
            </h6>
        </div>
    )
}

export default BearerItem
