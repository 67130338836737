import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DOMPurify from "dompurify"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"

import { Helmet } from "react-helmet-async"
import { fetchPageContent } from "../../features/policy-pages/policySlice"

const PrivacyPolicy = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.policyPages)

    useEffect(() => {
        dispatch(fetchPageContent("privacy-policy"))
    }, [dispatch])

    let cleanContent = DOMPurify.sanitize(pageContent.content)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <>
            <Helmet>
                <title>{pageContent.title}</title>
            </Helmet>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <section className="bg-black px-4 py-20 relative">
                <div className="container mx-auto px-6 flex justify-start">
                    <div className=" text-white">
                        <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl">
                            {pageContent.title}
                        </h1>
                    </div>
                </div>
            </section>
            <section className="px-4 pt-0 pb-16 bg-blue-dark">
                <div
                    className="container mx-auto relative bg-white -mt-8 p-8 rounded-2xl text-base"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </section>
        </>
    )
}

export default PrivacyPolicy
