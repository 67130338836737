import { useSelector } from "react-redux"
import { staticBaseURI } from "../../config"

const Success = () => {
    const { user } = useSelector((state) => state.auth)

    return (
        <section className="bg-yellow-100 h-[90vh] w-[100vw] flex justify-center items-center">
            <div className="bg-white px-4 py-8 shadow-lg rounded-2xl mx-2">
                <div className="flex justify-center">
                    <img
                        src={`${staticBaseURI}/dists/images/confetti.png`}
                        alt="success"
                        className="w-28"
                    />
                </div>
                <div className="text-center mt-4">
                    <h3 className="font-semibold text-lg md:text-xl lg:text-2xl">
                        Hi, {user.name}
                    </h3>
                    <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl mt-2">
                        Your donation is successfull.
                    </h2>
                    <p className="text-lg text-gray-700 mt-3 mb-4">
                        Thank you for your generous donation to RGU. We are
                        thrilled to have your support.
                    </p>
                    <a
                        href={`${staticBaseURI}/`}
                        className="bg-blue-dark text-white px-4 py-2 text-lg uppercase"
                    >
                        Go to homepage
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Success
