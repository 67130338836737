import { Formik, Form } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"

import TextInput from "../../../components/Inputs/TextInput"
import { sendOTP, updateMobile } from "../../../features/auth/authSlice"
import Toastify from "../../../components/Notification/Toastify"
import Loader from "../../../components/Loader/Loader"
import { redirectUser, staticBaseURI } from "../../../config"

const UpdateMobile = () => {
    const { otpSent, otpLoading, formSubmitted, user, login, isLoading } =
        useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const otpSchema = Yup.object({
        mobile: Yup.string()
            .min(10, "Invalid mobile number")
            .max(10, "Invalid mobile number")
            .required("Please enter mobile number"),
        otp: otpSent
            ? Yup.string().required("Please enter OTP")
            : Yup.string().nullable(),
    })

    function handleSubmit(values, actions) {
        if (otpSent && values.otp) {
            dispatch(updateMobile({ values, actions }))
        } else if (!otpSent) {
            actions.setSubmitting(false)
            actions.setFieldTouched("mobile", true, false)
            actions.setFieldError("mobile", "Please click on Send OTP button")
        } else if (otpSent && !values.otp) {
            actions.setFieldError("otp", "Please enter OTP")
            actions.setFieldTouched("otp", true, false)
            actions.setSubmitting(false)
        }
    }

    function handleSendOTP({
        values,
        setFieldError,
        setFieldTouched,
        setFieldValue,
    }) {
        if (!values.mobile) {
            setFieldError("mobile", "Please enter mobile number")
            setFieldTouched("mobile", true, true)
        } else if (values.mobile && values.mobile.toString().length === 10) {
            dispatch(sendOTP(values.mobile))
            setFieldValue("otp", "")
        }
    }

    if (formSubmitted) {
        redirectUser(user)
    }

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login`
    }

    return (
        <section className="py-20 bg-gray-200">
            <div className="container mx-auto px-4 flex justify-center">
                <div className="border border-gray-200 shadow-md rounded-lg p-4 bg-white w-full md:w-[60%] lg:w-[40%]">
                    <h1 className="uppercase text-center text-lg md:text-2xl font-bold">
                        Update mobile number
                    </h1>
                    <div>
                        <Formik
                            initialValues={{
                                mobile: "",
                                otp: "",
                            }}
                            validationSchema={otpSchema}
                            enableReinitialize={true}
                            onSubmit={(values, actions) => {
                                handleSubmit(values, actions)
                            }}
                        >
                            {(formikProps: FormikProps<any>) => (
                                <Form>
                                    <div className="flex w-full">
                                        <div className="flex-1 mt-4">
                                            <TextInput
                                                name="mobile"
                                                type="number"
                                                placeholder="MOBILE"
                                                color="dark"
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <button
                                                type="button"
                                                className="bg-blue-900 text-white px-4 md:px-2 lg:px-4 py-1 text-md appearance-none mt-5 font-semibold"
                                                onClick={() =>
                                                    handleSendOTP(formikProps)
                                                }
                                                disabled={
                                                    otpLoading ? true : false
                                                }
                                            >
                                                {otpLoading ? (
                                                    <div className="inline-flex">
                                                        <Loader
                                                            size="4"
                                                            classes="mr-1 -mt-1"
                                                        />
                                                        Sending...
                                                    </div>
                                                ) : (
                                                    "Send OTP"
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    {otpSent && (
                                        <TextInput
                                            name="otp"
                                            type="number"
                                            placeholder="OTP"
                                            color="dark"
                                        />
                                    )}

                                    <div className="flex justify-center">
                                        <button
                                            className="bg-blue-900 text-white px-8 py-2 text-xl text-center uppercase font-bold mt-6"
                                            type="submit"
                                            disabled={
                                                formikProps.isSubmitting
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {formikProps.isSubmitting ? (
                                                <div className="inline-flex">
                                                    <Loader
                                                        size="6"
                                                        classes="mr-1 -mt-1"
                                                    />
                                                    Loading...
                                                </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            <Toastify />
        </section>
    )
}

export default UpdateMobile
