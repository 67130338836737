import { FaRegEdit } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"

import Modal from "../../../../components/Modal/Modal"
import { openModal } from "../../../../features/modal/modalSlice"
import EducationForm from "./EducationForm"

const EducationItem = ({ edu }) => {
    const dispatch = useDispatch()
    const { modalName, isOpen } = useSelector((state) => state.modal)

    return (
        <>
            <div className="mb-4 flex justify-between">
                <div className="flex-1">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                        <h2 className="font-semibold text-base md:text-xl">
                            {edu.course_name}
                        </h2>
                        <h3 className="text-base md:text-xl text-gray-700">{` ${edu.start} - ${edu.end}`}</h3>
                    </div>
                    <h4 className="text-sm md:text-lg text-gray-600">
                        {edu.college_name}
                    </h4>
                </div>
                <div className="flex-0 self-center ml-3">
                    <button className="text-2xl text-blue-dark">
                        <FaRegEdit
                            className="text-2xl"
                            onClick={() =>
                                dispatch(openModal(`editEducation${edu.id}`))
                            }
                        />
                    </button>
                </div>
            </div>
            {isOpen && modalName === `editEducation${edu.id}` && (
                <Modal>
                    <>
                        <h3 className="font-bold text-2xl">Edit Education</h3>
                        <hr className="my-4" />
                        <EducationForm edu={edu} />
                    </>
                </Modal>
            )}
        </>
    )
}

export default EducationItem
