const CarouselItem = ({ name, image, slug }) => {
    return (
        <>
            <div
                className="facilitites-carousel-item mx-4"
                data-facility={`#${slug}`}
            >
                <div className="flex justify-center">
                    <div
                        className="circle-img rounded-full"
                        style={{ backgroundImage: `url(${image})` }}
                    ></div>
                </div>
                <h2 className="text-center text-white font-bold text-xl lg:text-2xl mt-5">
                    {name}
                </h2>
            </div>
        </>
    )
}

export default CarouselItem
