import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ImageCard from "../../components/Skeletons/ImageCard"
import { fetchBearers } from "../../features/office-bearers/officeBearerSlice"

import BearerItem from "./BearerItem"

const BearersList = () => {
    const dispatch = useDispatch()

    const { bearers, currentPage } = useSelector(
        (state) => state.officeBearersPage
    )

    useEffect(() => {
        dispatch(fetchBearers(currentPage))
    }, [dispatch, currentPage])

    return (
        <section className="bg-blue-dark pt-12 px-4" id="winners-list">
            <div className="container mx-auto px-4 pt-4">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-12">
                    {bearers.length < 1
                        ? [...Array(4)].map((i) => <ImageCard key={i} />)
                        : bearers.map((member) => (
                              <BearerItem
                                  key={member.id}
                                  image={member.image_url}
                                  name={member.name}
                                  designation={member.designation}
                                  position={member.position}
                                  course={member.course}
                                  batch={member.batch}
                              />
                          ))}
                </div>
            </div>
        </section>
    )
}

export default BearersList
