import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

//fetch alumnis
const fetchAlumnis = async (limit, page) => {
    const url = `/alumni-of-the-month/${limit}?page=${page}`
    const response = await api.get(url)
    return response.data
}

//fetch alumni details
const fetchAlumniOfTheMonthDetails = async (slug) => {
    const url = `/alumni-of-the-month-details/${slug}`
    const response = await api.get(url)
    return response.data
}

const alumniOfTheMonthService = {
    fetchPageContent,
    fetchAlumnis,
    fetchAlumniOfTheMonthDetails,
}

export default alumniOfTheMonthService
