import './section-title.css'

function index({title, button=false, link="#0", buttonText="View all"}) {
  return (
    <div className="flex justify-between">
      <h2 className="xs:text-base sm:text-lg md:text-2xl font-bold underline underline-offset-8 uppercase">{title}</h2>
      {
        button && link && buttonText && (
          <div className="flex self-center">
            <span className="blue-dot rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
            <a href={link} className="font-bold text-base md:text-xl">{buttonText}</a>
          </div>
        )
      }
    </div>
  )
}

export default index