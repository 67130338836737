import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchPageContent } from "../../features/recognitions/recognitionSlice"
import About from "./About"
import Board from "./Board"
import "./recognition.css"
import Videos from "./Videos"

const Recognition = () => {
    const dispatch = useDispatch()

    const { pageContent } = useSelector((state) => state.recognitionsPage)

    useEffect(() => {
        dispatch(fetchPageContent("recognitions"))
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${pageContent.image_url}`}
            />
            <About />
            <Board />
            <Videos />
        </>
    )
}

export default Recognition
