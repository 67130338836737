import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import AlbumImages from "./AlbumImages"
import { apiBaseURI } from "../../config"
import PageHeader from "../../components/PageHeader"
import { fetchMemoryDetails } from "../../features/memories/memoriesSlice"

const AlbumDetails = () => {
    let params = useParams()
    const dispatch = useDispatch()

    const { memoryDetails } = useSelector((state) => state.memoriesPage)

    useEffect(() => {
        dispatch(fetchMemoryDetails(params.slug))
    }, [dispatch, params.slug])

    return (
        <>
            <Helmet>
                <title>{memoryDetails.name}</title>
            </Helmet>

            <PageHeader
                text={false}
                image={`${apiBaseURI}${
                    memoryDetails && memoryDetails.cover
                        ? memoryDetails.cover.image_url
                        : null
                }`}
            />
            <section className="bg-black px-4 py-20 relative">
                <div className="container mx-auto px-6 flex justify-start">
                    <div className=" text-white">
                        <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl">
                            {memoryDetails.name}
                        </h1>
                    </div>
                </div>
            </section>
            <section className="px-4 pt-0 pb-16 bg-blue-dark">
                <div className="container mx-auto relative bg-white -mt-8 p-8 rounded-2xl">
                    <AlbumImages images={memoryDetails.images} />
                </div>
            </section>
        </>
    )
}

export default AlbumDetails
