import { api } from "../../api"

//fetch page content
const fetchPageContent = async (slug) => {
    const url = `/page-data/${slug}`
    const response = await api.get(url)
    return response.data
}

const fetchTeamMembers = async () => {
    const url = `/endowment-fund`
    const response = await api.get(url)
    return response.data
}

const fetchDonors = async (limit) => {
    const url = `/endowment-fund/donors/${limit}`
    const response = await api.get(url)
    return response.data
}

const endowmentFundService = {
    fetchPageContent,
    fetchTeamMembers,
    fetchDonors,
}

export default endowmentFundService
