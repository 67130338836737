import { useState } from "react"
import { ImArrowRight2 } from "react-icons/im"
import { useDispatch, useSelector } from "react-redux"

import { newsletterSignup } from "../../../features/newsletter-signup/newsletterSignupSlice"
import Loader from "../../Loader/Loader"
import "./news-letter.css"

const NewsLetter = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")

    const { isError, isLoading, isSubmitted, errorMessage } = useSelector(
        (state) => state.newsletterSignup
    )

    const handleNewsletterForm = (e) => {
        e.preventDefault()
        if (!email) {
            document.querySelector(
                "#email-error"
            ).innerHTML = `Please enter a valid email`
        } else {
            dispatch(newsletterSignup(email))
        }
    }

    if (email.length > 0 && isSubmitted) {
        setEmail("")
    }

    return (
        <section className="bg-blue-newsletter">
            <div className="container mx-auto pt-6 pb-4 px-10">
                <div className="flex flex-wrap justify-center mt-16 md:mt-24">
                    {isLoading ? (
                        <Loader size="10" />
                    ) : (
                        <>
                            <div className="lg:mr-10 col-span-3 md:col-span-1 lg:text-right text-center mb-8 md:mb-12 lg:mb-0">
                                <h2 className="font-bold text-[21px] md:text-5xl text-white">
                                    Newsletter Signup
                                </h2>
                            </div>
                            <div className="col-span-3 md:col-span-2">
                                <form
                                    className="w-full max-w-lg flex justify-end"
                                    onSubmit={(e) => handleNewsletterForm(e)}
                                >
                                    <div>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            placeholder="Your Email Address"
                                            className="border-b-2 md:border-b-4 py-2 caret-white bg-transparent focus:outline-none text-white text-base md:text-3xl"
                                        />
                                        <div>
                                            <label
                                                id="email-error"
                                                className={`${
                                                    isSubmitted
                                                        ? "text-green-500"
                                                        : "text-red-500"
                                                } pt-2`}
                                            >
                                                {isError
                                                    ? errorMessage
                                                    : isSubmitted
                                                    ? "You have successfully signed up to newsletters"
                                                    : ""}
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="text-white p-3 border-2 md:border-4 text-base md:text-xl border-white ml-5 hover:bg-white hover:text-black duration-100"
                                    >
                                        <ImArrowRight2 />
                                    </button>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                        fill="#002267"
                        fillOpacity="1"
                        d="M0,128L60,112C120,96,240,64,360,80C480,96,600,160,720,202.7C840,245,960,267,1080,261.3C1200,256,1320,224,1380,208L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                    ></path>
                </svg>
            </div>
        </section>
    )
}

export default NewsLetter
