import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { framerAnimationFinal, framerAnimationInitial } from "../../config"

const About = () => {
    const { inView, ref } = useInView()
    const controls = useAnimation()

    if (inView) {
        controls.start(framerAnimationFinal)
    }

    return (
        <section className="bg-black px-4 py-20 aaf-header">
            <div className="container mx-auto px-6">
                <div className="flex justify-end mb-8">
                    <a
                        href="#0"
                        className="read-more-btn py-2 flex self-center w-fit text-white text-xl"
                    >
                        <span className="bg-cyan-300 rounded-full h-4 w-4 mt-1 md:mt-2 mr-2"></span>
                        Know more
                    </a>
                </div>
                <div>
                    <motion.h1
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.2,
                        }}
                        className="text-gradient text-4xl md:text-6xl lg:text-8xl font-bold"
                        ref={ref}
                    >
                        Alumni
                    </motion.h1>
                    <motion.h1
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.3,
                        }}
                        className="text-gradient text-4xl md:text-6xl lg:text-8xl font-bold"
                        ref={ref}
                    >
                        Affairs
                    </motion.h1>
                    <motion.h3
                        initial={framerAnimationInitial}
                        animate={controls}
                        transition={{
                            duration: 0.3,
                            delay: 0.4,
                        }}
                        className="text-white mt-4 text-2xl md:text-3xl lg:text-4xl font-bold"
                        ref={ref}
                    >
                        Stay connected to your Alma Matter
                    </motion.h3>
                </div>
            </div>
        </section>
    )
}

export default About
