import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchLatestNewsletter } from "../../features/newsletters/newsletterSlice"
import ActiveNewsletters from "./ActiveNewsletters"
import "./newsletters.css"

const NewsLetterArchives = () => {
    const dispatch = useDispatch()

    const { newsletterDetails } = useSelector((state) => state.newsletterPage)

    useEffect(() => {
        dispatch(fetchLatestNewsletter())
    }, [dispatch])

    return (
        <>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${newsletterDetails.image_url}`}
            />
            <section className="relative bg-dark-blue-newsletter px-4 pt-8 pb-28">
                <div className="container mx-auto px-6">
                    <div className="flex justify-end mt-10">
                        <div>
                            <h1 className="text-white text-xl md:text-2xl lg:text-3xl uppercase text-right">
                                Newsletter
                            </h1>
                            <h1 className="text-white text-xl md:text-2xl lg:text-3xl uppercase font-bold mt-2">
                                {newsletterDetails.title}
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <ActiveNewsletters details={newsletterDetails} />
        </>
    )
}

export default NewsLetterArchives
