import { useEffect, useRef } from "react"
import {
    FaDownload,
    FaEnvelope,
    FaFacebookSquare,
    FaLinkedin,
    FaPhoneSquare,
    FaTwitterSquare,
} from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import SectionTitle from "../../../components/Resume/SectionTitle"

import { apiBaseURI, scrollTop, staticBaseURI } from "../../../config"
import {
    fetchBasicDetails,
    fetchEducationDetails,
    fetchProjects,
    fetchSkills,
    fetchWorkDetails,
} from "../../../features/auth/authSlice"
import {
    fetchCities,
    //fetchCountries,
    fetchCourses,
    //fetchDepartments,
    fetchStates,
} from "../../../features/config/configSlice"
import About from "./About/About"
import Education from "./Education/Education"
import Experiences from "./Experience/Experiences"
import Projects from "./Projects/Projects"
import Skills from "./Skills/Skills"

const Resume = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isInitialMount = useRef(true)

    const encodedURI = encodeURIComponent(location.pathname)

    const {
        user,
        login,
        isLoading,
        basicDetails,
        workDetails,
        educationDetails,
        skills,
        projectDetails,
    } = useSelector((state) => state.auth)

    // const { courses, departments, countries, states, cities } = useSelector(
    //     (state) => state.config
    // )

    useEffect(() => {
        scrollTop()

        if (isInitialMount.current) {
            // dispatch(fetchDepartments())
            // dispatch(fetchCountries())
            dispatch(fetchBasicDetails())
            dispatch(fetchEducationDetails())
            dispatch(fetchWorkDetails())
            dispatch(fetchSkills())
            dispatch(fetchProjects())
            isInitialMount.current = false
        } else {
            if (basicDetails.country_id) {
                dispatch(fetchStates(basicDetails.country_id))
            }

            if (basicDetails.department_id) {
                dispatch(fetchCourses(basicDetails.department_id))
            }

            if (basicDetails.state_id) {
                dispatch(fetchCities(basicDetails.state_id))
            }
        }
    }, [
        dispatch,
        basicDetails.country_id,
        basicDetails.department_id,
        basicDetails.state_id,
    ])

    if (!isLoading && !login) {
        window.location.href = `${staticBaseURI}/login?redirect=${encodedURI}`
    }

    // const courseName = courses.find(
    //     ({ id }) => id === parseInt(basicDetails.course_id)
    // )

    // const deptName = departments.find(
    //     ({ id }) => id === parseInt(basicDetails.department_id)
    // )

    return (
        <>
            <div className=" bg-slate-300 py-16 px-5 md:px-10 flex justify-center ">
                <div className="border-2 border-slate-400 p-4 md:p-8 rounded-lg bg-white w-full">
                    <div className="flex space-x-6 mb-4">
                        <div className="flex lg:justify-center">
                            <img
                                src={`${apiBaseURI}${basicDetails.photo_url}`}
                                alt={user.name}
                                className="w-32 lg:w-3/5 rounded-full"
                            />
                        </div>
                        <div className="self-center flex-1">
                            <h1 className="font-bold text-xl md:text-3xl mb-4">
                                {user.name}
                            </h1>
                            <div className="flex text-3xl space-x-2">
                                {basicDetails.linkedin_url && (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={basicDetails.linkedin_url}
                                    >
                                        <FaLinkedin />
                                    </a>
                                )}
                                {basicDetails.facebook_url && (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={basicDetails.facebook_url}
                                    >
                                        <FaFacebookSquare />
                                    </a>
                                )}
                                {basicDetails.twitter_url && (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={basicDetails.twitter_url}
                                    >
                                        <FaTwitterSquare />
                                    </a>
                                )}
                            </div>
                            <div className="mt-4">
                                <a
                                    href={`${apiBaseURI}/api/download-resume/${user.id}`}
                                    className="bg-blue-dark text-white px-4 py-2 flex w-fit items-center"
                                >
                                    Download Resume
                                    <FaDownload className="ml-2" />
                                </a>
                            </div>
                            {/* <h3 className=" text-gray-600 text-sm md:text-xl">
                            {courseName && courseName.title} -{" "}
                            {basicDetails.batch}
                        </h3>
                        <h3 className="text-gray-600 text-sm md:text-xl">
                            {deptName.name}
                        </h3> */}
                        </div>
                    </div>
                    <hr />

                    <div className="grid grid-cols-6 mt-4">
                        <div className="col-span-6 md:col-span-4 border-r-0 md:border-r-2 border-r-grey-300 pr-0 md:pr-4">
                            <div className="mb-4">
                                <About about={basicDetails.about} />
                            </div>
                            <div>
                                <Experiences works={workDetails} />
                            </div>
                            <div className="mt-4">
                                <Education education={educationDetails} />
                            </div>
                            <div className="mt-4">
                                <Projects projects={projectDetails} />
                            </div>
                        </div>
                        <div className="col-span-6 md:col-span-2 pl-0 md:pl-4">
                            <div>
                                <SectionTitle title="Contact" />
                                <div className="mt-4">
                                    <ul>
                                        <li className="flex text-xl mb-4">
                                            <FaPhoneSquare className="self-center text-xl md:text-3xl mr-2 md:mr-3" />
                                            {user.mobile}
                                        </li>
                                        <li className="flex text-xl">
                                            <FaEnvelope className="self-center text-xl md:text-3xl mr-2 md:mr-3" />
                                            {user.email}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <Skills skills={skills} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume
