import {
    Facebook,
    Twitter,
    Whatsapp,
} from "../../components/SocialShare/SocialShare"
import { apiBaseURI, staticBaseURI } from "../../config"

const NextAlumni = ({ next, prev }) => {
    return (
        <section className="bg-blue-newsletter px-4 pt-8 pb-16 relative overflow-hidden">
            <div className="bg-text uppercase top-[30%] -left-20">Awards</div>
            <div className="container mx-auto px-6">
                <div className="flex justify-end">
                    <div className="dap-social-btns flex self-center">
                        <Facebook />
                        <Twitter />
                        <Whatsapp />
                    </div>
                    <div className="uppercase dap-share-text font-semibold text-2xl border-l-2 border-white ml-8">
                        Share
                    </div>
                </div>

                {next && prev === null && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${next.image_url}`}
                                    alt={next.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-of-the-month-details/${next.slug}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-2xl md:text-3xl decoration-cyan-300"
                                >
                                    Next
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-lg md:text-3xl font-semibold uppercase">
                                        {next.name}
                                    </h3>
                                    <h5 className="text-base md:text-2xl">
                                        Award Month: {next.award_month}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {next === null && prev && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${prev.image_url}`}
                                    alt={prev.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-of-the-month-details/${prev.slug}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-xl md:text-3xl decoration-cyan-300"
                                >
                                    Previous
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-base md:text-3xl font-semibold uppercase">
                                        {prev.name}
                                    </h3>
                                    <h5 className="text-sm md:text-2xl">
                                        Award Month: {prev.award_month}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {next && prev && (
                    <div className="flex justify-end">
                        <div>
                            <div className="dap-na-img-wrapper">
                                <img
                                    src={`${apiBaseURI}${next.image_url}`}
                                    alt={next.name}
                                />
                            </div>
                            <div className="flex text-white space-x-4 mt-4">
                                <a
                                    href={`${staticBaseURI}/alumni-of-the-month-details/${next.slug}`}
                                    className="font-semibold uppercase self-center underline dap-next-text underline-offset-4 text-xl md:text-3xl decoration-cyan-300"
                                >
                                    Next
                                </a>
                                <div className=" border-l-4 pl-4 dap-next-info border-l-cyan-300">
                                    <h3 className="text-3xl font-semibold uppercase">
                                        {next.name}
                                    </h3>
                                    <h5 className="text-2xl">
                                        Award Month: {prev.award_month}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default NextAlumni
