export const staticBaseURI = ""
//export const staticBaseURI = "/alumni"
//export const apiBaseURI = "http://localhost:8000"
export const apiBaseURI = "https://alumni.rgu.ac.in/admin"

export const scrollTop = (x = 0, y = 0) => {
    window.scrollTo(x, y)
}

export function scrollFunction() {
    const mybutton = document.querySelector("#scroll-to-top-btn")

    if (
        document.body.scrollTop > 150 ||
        document.documentElement.scrollTop > 150
    ) {
        mybutton.style.display = "block"
    } else {
        mybutton.style.display = "none"
    }
}

export const framerAnimationInitial = { opacity: 0 }

export const framerAnimationFinal = { opacity: 1 }

export const formatDate = (date) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    let day = date.getDate()
    let monthName = months[date.getMonth()]

    return { day, month, year, monthName }
}

export const summarizeText = (text, length) => {
    return text.length < length ? text : text.substring(0, length) + "..."
}

export const getQueryParams = (searchParams, term) => {
    const queryParams = new URLSearchParams(searchParams)
    return queryParams.get(term)
}

export function readingTime(text) {
    const wpm = 225
    const words = text.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    return time
}

export function redirectUser(user) {
    if (user.social_login && !user.mobile) {
        window.location.href = `${staticBaseURI}/profile/update-mobile`
    } else if (user.profile_completed === 1) {
        window.location.href = `${staticBaseURI}/dashboard`
    } else if (user.profile_completed === 0) {
        if (user.profile_step === 0)
            window.location.href = `${staticBaseURI}/profile/basic-details`
        else if (user.profile_step === 1)
            window.location.href = `${staticBaseURI}/profile/education-details`
        else if (user.profile_step === 2)
            window.location.href = `${staticBaseURI}/profile/work-details`
    }
}
