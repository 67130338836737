import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import youngAlumniAwardService from "./youngAlumniAwardService"

const initialState = {
    awardees: [],
    isLoading: false,
    isError: false,
    currentPage: 1,
    lastPage: 1,
    perPage: 16,
    alumniDetails: {},
    nextPageURI: null,
    prevPageURI: null,
    pageContent: {},
}

export const fetchAwardees = createAsyncThunk(
    "youngAlumniAward/fetchAwardees",
    async (currentPage, thunkAPI) => {
        try {
            const { getState } = thunkAPI
            const state = getState().youngAlumniAwardPage
            return await youngAlumniAwardService.fetchAwardees(
                state.perPage,
                currentPage
            )
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchPageContent = createAsyncThunk(
    "youngAlumniAward/fetchPageContent",
    async (slug, thunkAPI) => {
        try {
            return await youngAlumniAwardService.fetchPageContent(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const fetchAlumniDetails = createAsyncThunk(
    "youngAlumniAward/fetchAlumniDetails",
    async (slug, thunkAPI) => {
        try {
            return await youngAlumniAwardService.fetchAlumniDetails(slug)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const youngAlumniAwardSlice = createSlice({
    name: "youngAlumniAward",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAwardees.pending, (state) => {
                state.isLoading = true
                state.awardees = []
            })
            .addCase(fetchAwardees.fulfilled, (state, action) => {
                state.isLoading = false
                state.awardees = action.payload.data
                state.currentPage = action.payload.current_page
                state.lastPage = action.payload.last_page
                state.nextPageURI = action.payload.next_page_url
                state.prevPageURI = action.payload.prev_page_url
            })
            .addCase(fetchAwardees.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.awardees = []
                state.currentPage = 1
                state.lastPage = 1
                state.nextPageURI = null
                state.prevPageURI = null
                state.errorMessage = action.payload
            })
            .addCase(fetchPageContent.fulfilled, (state, action) => {
                state.pageContent = action.payload
            })
            .addCase(fetchPageContent.rejected, (state) => {
                state.pageContent = {}
            })
            .addCase(fetchAlumniDetails.fulfilled, (state, action) => {
                state.alumniDetails = action.payload
            })
            .addCase(fetchAlumniDetails.rejected, (state) => {
                state.alumniDetails = {}
            })
    },
})

export const { setCurrentPage } = youngAlumniAwardSlice.actions

export default youngAlumniAwardSlice.reducer
