import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { fetchAlumns } from "../../features/distinguished-alumni-service-award/distinguishedAlumniServiceAwardSlice"
import AwardWinnerItem from "./AwardWinnerItem"
import ImageCard from "../../components/Skeletons/ImageCard"

function AwardWinners() {
    const dispatch = useDispatch()

    const { alumns, currentPage } = useSelector(
        (state) => state.distinguishedAlumniServiceAwardPage
    )

    useEffect(() => {
        dispatch(fetchAlumns(currentPage))
    }, [dispatch, currentPage])

    return (
        <section className="light-blue-bg px-4 py-20" id="winners-list">
            <div className="container mx-auto px-6">
                <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-4 gap-y-20">
                    {alumns.length < 1
                        ? [...Array(4)].map((i) => <ImageCard />)
                        : alumns.map((winner) => (
                              <AwardWinnerItem
                                  key={winner.id}
                                  image={winner.image_url}
                                  name={winner.name}
                                  year={winner.award_year}
                                  slug={winner.slug}
                              />
                          ))}
                </div>
            </div>
        </section>
    )
}

export default AwardWinners
