import Slider from "react-slick"
import OngoingItem from "./OngoingItem"

const OngoingTalks = ({ talks = [] }) => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        className: "center",
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    }

    return (
        <section className="bg-blue-dark py-16">
            <div className="container mx-auto">
                <div className="flex justify-end px-4 md:px-10">
                    <h3 className="uppercase font-bold text-3xl text-white">
                        Ongoing
                    </h3>
                </div>
                <div className="mt-10 tt-ongoing-talks">
                    <Slider {...settings}>
                        {talks.map((talk) => (
                            <OngoingItem
                                key={`ongoing-${talk.id}`}
                                image={talk.image_url}
                                title={talk.title}
                                slug={talk.slug}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default OngoingTalks
