import React from "react"

const DonorItem = ({ name, date }) => {
    return (
        <div className="bg-white p-8 mx-4 mb-8 rounded-2xl">
            <h1 className="text-center font-bold text-xl md:text-3xl">
                {name}
            </h1>
            <div className="flex justify-center md:justify-end">
                <h3 className="font-bold text-base md:text-xl">{date}</h3>
            </div>
        </div>
    )
}

export default DonorItem
