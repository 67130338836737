const About = ({ title, day, date }) => {
    return (
        <section className="bg-black px-4 py-20 relative overflow-hidden">
            <div className="container mx-auto px-6">
                <div className="bg-text top-[10%] left-0 md:left-8">events</div>
                <div className="flex justify-end">
                    <div className="text-white w-full md:w-[70%] lg:w-[55%]">
                        <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold mb-8">
                            {title}
                        </h1>
                        <h3 className="text-2xl md:text-3xl lg:text-4xl uppercase">
                            {day}, <span className="font-bold">{date}</span>
                            {/* &nbsp;|&nbsp;
                            <span className="font-bold">2:30 PM</span>
                            &nbsp;Onwards */}
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
