import React from "react"
import FacilityInfoItem from "./FacilityInfoItem"

const FacilityInfo = ({ facilities }) => {
    return (
        <section className="bg-blue-dark pb-20 px-4 relative -mt-1">
            <div className="container mx-auto px-0 md:px-6">
                <div className="facilties-info-wrapper relative bg-white p-6 rounded-2xl">
                    {facilities.map((facility) => (
                        <FacilityInfoItem
                            key={`facility-info-${facility.id}`}
                            name={facility.name}
                            slug={facility.slug}
                            images={facility.images}
                            description={facility.description}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default FacilityInfo
