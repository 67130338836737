import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as DOMPurify from "dompurify"

import PageHeader from "../../components/PageHeader"
import { apiBaseURI } from "../../config"
import { fetchByeLaw } from "../../features/byelaws/byelawsSlice"
import { Helmet } from "react-helmet-async"

const ByLaws = () => {
    const dispatch = useDispatch()

    const { byeLaw } = useSelector((state) => state.byelawsPage)

    useEffect(() => {
        dispatch(fetchByeLaw())
    }, [dispatch])

    let cleanContent = DOMPurify.sanitize(byeLaw.description)

    const createMarkup = (body) => {
        return { __html: body }
    }

    return (
        <>
            <Helmet>
                <title>{byeLaw.meta_title}</title>
                <meta name="description" content={byeLaw.meta_description} />
                <meta name="keywords" content={byeLaw.meta_keywords} />
            </Helmet>
            <PageHeader
                text={false}
                image={`${apiBaseURI}${byeLaw.image_url}`}
            />
            <section className="bg-black px-4 py-16 relative">
                <div className="container mx-auto px-6 flex justify-start">
                    <div className="border-r-4 text-white border-r-white pr-8">
                        <h1 className="font-bold text-3xl md:text-4xl lg:text-6xl">
                            By
                        </h1>
                        <h1 className="font-bold text-3xl md:text-4xl lg:text-6xl">
                            Laws
                        </h1>
                    </div>
                </div>
            </section>
            <section className="px-4 pt-0 pb-16 bg-blue-dark">
                <div
                    className="container mx-auto relative bg-white -mt-8 p-8 rounded-2xl text-base"
                    dangerouslySetInnerHTML={createMarkup(cleanContent)}
                ></div>
            </section>
        </>
    )
}

export default ByLaws
